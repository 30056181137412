import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useAccount, useNetwork } from 'wagmi';
import { CONVERSATION_SLOT, TEST_TICKS, ZERO_ADDRESS } from '../config';
import InscriptionFactoryV3ABI from '../abi/InscriptionFactory.json';
import InscriptionV3ABI from '../abi/Inscription.json';
import ConversationABI from '../abi/Conversation.json';
import VoteForLaunchABI from '../abi/VoteForLaunch.json';
import { readContract, fetchToken } from '@wagmi/core'
import { BigNumber } from "ethers";
import { FiSearch } from "react-icons/fi";
import toast, { Toaster } from 'react-hot-toast';
import { ConversationData, HomeMainProps, InscriptionData, NetworkData } from '../utils/types';
import TokenList from './TokenList';
import Deploy from './Deploy';
import Mint from './Mint';
import TokenDetails from './TokenDetails';
import { BN, formatAddress, formatEther, getContractAddressByVersion, getNetworkData, toNumber } from '../utils/common';
import Pagination from './Pagination';
import intl from '../utils/intl';
import IFODetails from './IFODetails';
import TokenGrid from './TokenGrid';
import { BsFillGrid3X3GapFill, BsListUl } from "react-icons/bs";
import LinkTo from './LinkTo';
import useDeviceDetect from '../utils/useDeviceDetect';

const HomeMain: FC<HomeMainProps> = ({
	version,
	tokenAddress
}) => {
	// const ORDER_BY = {
	// 	TIMESTAMP: 1, // from oldest to latest
	// 	TIMESTAMP_DESC: 2, // from latest to oldest
	// 	ROLLUPS: 3, // from less to more
	// 	ROLLUPS_DESC: 4, // from more to less
	// }
	const isMobile = useDeviceDetect();
	const pageSize = isMobile ? 12 : 20;

	const [showType, setShowType] = useState(0); // 0- all, 1- in-progress, 2- ended
	const [currentPage, setCurrentPage] = useState(1);
	const [searchKey, setSearchKey] = useState("");
	const [list, setList] = useState(Array<InscriptionData>);
	const [network, setNetwork] = useState({} as NetworkData);
	const [loading, setLoading] = useState(false);
	const [mintItem, setMintItem] = useState({} as InscriptionData);
	// const [totalBallots, setTotalBallots] = useState(BN("0"));

	const [mintRepeat, setMintRepeat] = useState(1);
	const [canMint, setCanMint] = useState(false);
	// const [orderBy, setOrderBy] = useState(ORDER_BY.TIMESTAMP_DESC);
	const { address } = useAccount();

	const [totalInscriptions, setTotalInscriptions] = useState(0);
	const [contractVersion, setContractVersion] = useState("");
	const [showIFODetails, setShowIFODetails] = useState(false);
	const [showMethod, setShowMethod] = useState(
		localStorage.getItem("ferc_show") ? localStorage.getItem("ferc_show") : "grid" // default
	);

	const conversationData = useRef(new Map<string, ConversationData>());
	const rate = useRef(new Map<string, string>());
	const totalBallots = useRef(BN("0"));

	const tokenDetailsRef: any = useRef();

	const { chain } = useNetwork();

	// useEffect(() => {
	// 	setContractVersion(version);
	// }, [version]);

	useEffect(() => {
		if (network !== undefined && version !== "") load(version);
	}, [network]);

	useEffect(() => {
		if (chain) {
			setNetwork(getNetworkData(chain, version));
			setContractVersion(version);
			// if (contractVersion !== "" && network !== undefined && version !== "") load(contractVersion);
		}
	}, [chain, version]);

	const load = async (_version: string) => {
		try {
			const _contractAddress = network.contractAddress;
			if (_contractAddress === undefined) {
				setList([]);
				setTotalInscriptions(0);
				return;
			}
			setLoading(true);

			if (tokenAddress === undefined || tokenAddress === '') {
				const _totalBallots = await readContract({
					address: network.voteForLaunchpad as any,
					abi: VoteForLaunchABI,
					functionName: "totalBallots",
					args: []
				}) as any;
				totalBallots.current = BN(_totalBallots);

				const count = await readContract({
					address: _contractAddress as any,
					abi: InscriptionFactoryV3ABI,
					functionName: 'getInscriptionAmount',
				})
				if (toNumber(count) === 0) {
					setList([]);
					setTotalInscriptions(0);
					setLoading(false);
					return;
				} else {
					setTotalInscriptions(toNumber(count));
					loadInscriptions(currentPage, _version);
				}
			} else {
				// load the only tick
				setCurrentPage(1);
				await search(tokenAddress);
				tokenAddress = undefined;
			}
		} catch (err: any) {
			if (err.details?.indexOf("stack underflow") !== -1) {
				// toast.error("ERROR: Walletconnect is overload now, please try again later!");
				setLoading(false);
			}
		}
	}

	const loadInscriptions = useCallback((_currentPage: number, _version: string) => {
		try {
			readContract({
				address: network.contractAddress as any,
				abi: InscriptionFactoryV3ABI,
				functionName: 'getIncriptions',
				args: [_currentPage, pageSize, showType],
			}).then((inscription) => {
				parseInstructions(inscription).then(() => {
					setSearchKey("");
					setLoading(false);
				})
			})
		} catch (err: any) {
			console.log(err);
			if (err.details?.indexOf("stack underflow") !== -1) {
				toast.error("ERROR#2: Walletconnect is overload now, please try again later!");
				setLoading(false);
			}
		}
	}, [network, pageSize, showType]);

	const parseInstructions = async (inscriptions: any) => {
		const _instriptions: InscriptionData[] = (inscriptions as any)[0];
		const _totalSupplys: BigNumber[] = (inscriptions as any)[1];
		const _totalRollups: BigNumber[] = (inscriptions as any)[2];

		let _list = [];
		for (let i = 0; i < _instriptions.length; i++) {
			let onlyTokenName = '';
			let decimals = BN(0);
			const _inscription = _instriptions[i] as InscriptionData;
			if (_inscription.addr === ZERO_ADDRESS) continue;
			if (TEST_TICKS.includes(_inscription.tick)) continue;

			try {
				if (_inscription.onlyContractAddress !== ZERO_ADDRESS) {
					const tokenInfo: any = await fetchToken({ address: _inscription.onlyContractAddress as any });
					onlyTokenName = tokenInfo.symbol as string;
					decimals = tokenInfo.decimals;
				}
			} catch (err) { }

			await loadSocialData(_inscription.addr);
			if (_inscription.isVoted) await loadRate(_inscription.tick, _inscription.addr);


			_list.push({
				..._inscription,
				onlyTokenName,
				decimals,
				totalSupply: _totalSupplys[i],
				totalRollups: _totalRollups[i],
				totalMintable: BN(_inscription.maxRollups).mul(_inscription.limitPerMint).div(BN(10000).sub(_inscription.liquidityTokenPercent)).mul(BN(10000)),
				wethAddress: network.wethAddress,
				uniswapV3Factory: network.uniswapV3Factory,
				nonfungiblePositionManager: network.nonfungiblePositionManager,
			})
		}

		// traceList(_list);
		setList(_list.sort((a, b) => parseFloat(formatEther(BN(b.timestamp).sub(BN(a.timestamp))))));
	}

	// useEffect(() => {
	// 	// order list by 
	// 	if(list.length === 0) return;
	// 	console.log("====", orderBy, "====");
	// 	const ordered = list.sort((a, b) => {
	// 		let sub = -1;
	// 		if(orderBy === ORDER_BY.TIMESTAMP) sub = parseFloat(formatEther(BN(a.timestamp).sub(BN(b.timestamp))));
	// 		else if(orderBy === ORDER_BY.TIMESTAMP_DESC) sub = parseFloat(formatEther(BN(b.timestamp).sub(BN(a.timestamp))));
	// 		else if(orderBy === ORDER_BY.ROLLUPS) sub = parseFloat(BN(a.totalRollups).mul(10000).div(BN(a.maxRollups)).toString()) - parseFloat(BN(b.totalRollups).mul(10000).div(BN(b.maxRollups)).toString());
	// 		else if(orderBy === ORDER_BY.ROLLUPS_DESC) sub = parseFloat(BN(b.totalRollups).mul(10000).div(BN(b.maxRollups)).toString()) - parseFloat(BN(a.totalRollups).mul(10000).div(BN(a.maxRollups)).toString());
	// 		return sub;
	// 	})
	// 	setList(ordered);
	// 	console.log("==== done ====");
	// }, [orderBy]);

	const loadRate = async (_tick: string, _addr: string) => {
		readContract({
			address: network.voteForLaunchpad as any,
			abi: VoteForLaunchABI,
			functionName: "applications",
			args: [_tick]
		}).then((_application: any) => {
			if (!totalBallots.current.isZero()) {
				const _rate = BN(_application[8]).mul(100).div(totalBallots.current).toString();
				rate.current.set(_addr, _rate);
			} else rate.current.set(_addr, "");
		});
	}

	// Load social data
	const loadSocialData = async (addr: string) => {
		const conversation = await readContract({
			address: network.socialContractAddress as any,
			abi: ConversationABI,
			functionName: 'getLink',
			args: [addr],
		}) as Array<any>;

		let names = new Array<string>();
		let logos = new Array<string>();
		let urls = new Array<string>();
		let rates = new Array<BigNumber>();
		let sellers = new Array<string>();
		let lastUpdates = new Array<number>();
		for (let i = 0; i < CONVERSATION_SLOT; i++) {
			names.push(conversation[i].name as string);
			logos.push(conversation[i].logo as string);
			urls.push(conversation[i].url as string);
			rates.push(conversation[i].rate as BigNumber);
			sellers.push(conversation[i].seller as string);
			lastUpdates.push(conversation[i].lastUpdate as number);
		}

		conversationData.current.set(addr, {
			names,
			logos,
			urls,
			rates,
			sellers,
			lastUpdates,
		})
	}

	const traceList = (list: Array<InscriptionData>) => {
		console.log("=========================  Data  ==============================")
		for (let i = 0; i < list.length; i++) {
			if (list[i].tick !== "") console.log(list[i]);
		}
		console.log("================================================================")
	}

	const checkTokenAddress = async (tokenAddress: string, repeat: number) => {
		// get mint token information
		if (tokenAddress.trim() === "" || repeat <= 0) {
			// toast.error("Please input right tick, make sure the repeat time between 1-10");
			setCanMint(false);
			return;
		};

		try {
			await loadInscriptionData(tokenAddress);
		} catch (err) {
			setCanMint(false);
			toast.error("Oops! there is something wrong. The tick is not deployed. Please check your network")
		}
	}

	const loadInscriptionData = async (tokenAddress: string) => {
		try {
			const data: any = await readContract({
				address: network.contractAddress as any,
				abi: InscriptionFactoryV3ABI,
				functionName: 'getIncriptionByAddress',
				args: [tokenAddress],
			});

			const maxMintSize = toNumber(data[0].maxMintSize);
			if (mintRepeat <= maxMintSize) setCanMint(true);
			else {
				setCanMint(false);
				return;
			}

			let decimals = BN("0");
			try {
				if (data[0].onlyContractAddress !== ZERO_ADDRESS) {
					const tokenInfo: any = await fetchToken({ address: data[0].onlyContractAddress as any });
					decimals = BN(tokenInfo.decimals);
				}
			} catch (err) { }
			// console.log("==> ", data[0].onlyContractAddress as string, decimals.toString(), Date.now());
			const inscriptionData: InscriptionData = {
				...data[0],
				totalSupply: data[1],
				decimals,
				balance: await getBalanceOf(data[0].addr),
				onlyTokenName: await getTokenSymbol((data[0] as InscriptionData).onlyContractAddress),
				onlyBalanceOf: await getBalanceOf((data[0] as InscriptionData).onlyContractAddress),
			}
			setMintItem(inscriptionData);
		} catch (err) {
			console.log(err);
		}
	}

	const getBalanceOf = async (_contractAddress: string) => {
		try {
			if (_contractAddress === ZERO_ADDRESS) return 0;
			else return await readContract({
				address: _contractAddress as any,
				abi: InscriptionV3ABI,
				functionName: 'balanceOf',
				args: [address]
			})
		} catch (err) {
			console.log(err);
		}
	}

	const getTokenSymbol = async (address: string) => {
		if (address === ZERO_ADDRESS) return "";
		try {
			const name = await readContract({
				address: address as any,
				abi: InscriptionV3ABI,
				functionName: 'symbol',
			});
			return name;
		} catch (err) {
			console.log("getTokenSymbol fail", address);
			// console.log(err);
			return "";
		}
	}

	const search = async (_searchKey: string) => {
		setLoading(true);
		try {
			if (_searchKey.trim() === '') {
				// get all inscriptions
				const inscriptions = await readContract({
					address: network.contractAddress as any,
					abi: InscriptionFactoryV3ABI,
					functionName: 'getIncriptions',
					args: [1, pageSize, showType],
				});
				await parseInstructions(inscriptions);
			} else if (!_searchKey.startsWith("0x")) {
				// Search by tick
				const inscriptions = await readContract({
					address: network.contractAddress as any,
					abi: InscriptionFactoryV3ABI,
					functionName: 'searchBy',
					args: [_searchKey.trim().toLowerCase(), 10],
				}) as any;
				await parseInstructions(inscriptions);
			} else {
				// Search by address
				const inscriptions = await readContract({
					address: network.contractAddress as any,
					abi: InscriptionFactoryV3ABI,
					functionName: 'getIncriptionByAddress',
					args: [_searchKey],
				}) as any;
				await parseInstructions([[inscriptions[0]], [inscriptions[1]], [inscriptions[2]]]);
			}
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
		setLoading(false);
	}

	const clickType = async (type: number) => {
		setShowType(type);
		setLoading(true);
		try {
			const count = await readContract({
				address: network.contractAddress as any,
				abi: InscriptionFactoryV3ABI,
				functionName: 'getInscriptionAmountByType',
				args: [type],
			})
			setTotalInscriptions(toNumber(count));

			const inscriptions = await readContract({
				address: network.contractAddress as any,
				abi: InscriptionFactoryV3ABI,
				functionName: 'getIncriptions',
				args: [1, pageSize, type],
			});

			setCurrentPage(1);
			await parseInstructions(inscriptions);
		} catch (err) {
			console.log(err);
		}
		setLoading(false);
	}

	const resetTip = () => {
		tokenDetailsRef.current.resetTip();
	}

	return (
		<div className="bg-base-200">
			{/* Title  */}
			<div className="hero pt-[120px] pb-5">
				<div className="hero-content text-center">
					<div className="max-w-screen-sm">
						<h1 className="text-4xl font-bold flex justify-center"><div className="text-[#F28C18] font-bold text-2xl md:text-3xl mr-1">fair</div>
							<div className="font-extrabold text-2xl md:text-3xl">ERC20</div></h1>
						{/* <h1 className="text-3xl font-bold mt-3">{contractVersion.toUpperCase()}</h1> */}
						<h1 className="text-5xl font-bold mt-3">{intl.get("title")}</h1>
						{/* <h1 className="text-5xl font-bold mt-3"></h1> */}
						<p className="py-6">{intl.get("introduction")}</p>
						<div className="mb-1 text-center">
							<p className="mb-1">🐸 {intl.get("sub-introduction")} 🐸</p>
							<p className="flex justify-center">
								{formatAddress(network.contractAddress)}
								<LinkTo
									copyText={network.contractAddress}
									url={`${network.scanUrl}/address/${network.contractAddress}`}
								/>
							</p>
						</div>
						<div className="input-group flex justify-center">
							<label
								htmlFor="deployModal"
								className="btn btn-primary w-[150px] mt-5"
							// onClick={() => {
							// 	setShowDeploy(true);
							// }}
							>{intl.get("deploy")}</label>
							<a
								className="btn btn-secondary w-[150px] mt-5"
								href="/launchpad/"
								target='_blank'
							>{intl.get("launchpad")}</a>
						</div>
					</div>
				</div>
			</div>

			{/* Table of incriptions */}
			<div className="overflow-x-auto w-full md:w-[90%] m-auto md:m-auto md:mb-3 bg-base-300 md:rounded-lg px-3 py-5 md:p-5 text-center">
				<div className="block md:flex md:justify-between">
					{/* search box */}
					<div className="form-control">
						<div className="input-group">
							<input
								type="text"
								placeholder={intl.get("search-by")}
								value={searchKey}
								className="input w-full max-w-lg"
								onKeyUp={(e) => { if (e.code === "Enter") search(searchKey); }}
								onChange={(e) => setSearchKey(e.target.value)}
							/>
							<button
								className="btn btn-md m-auto"
								onClick={() => search(searchKey)}
							>
								<FiSearch className='text-2xl' />
							</button>
						</div>
					</div>

					{/* order by */}
					{/* <select 
							className="select max-w-xs"
							onChange={(e) => setOrderBy(parseInt(e.currentTarget.value))}
							defaultValue={ORDER_BY.TIMESTAMP_DESC} 
						>
							<option value={ORDER_BY.TIMESTAMP}>{intl.get("order-by-timestamp")}</option>
							<option value={ORDER_BY.TIMESTAMP_DESC}>{intl.get("order-by-timestamp-desc")}</option>
							<option value={ORDER_BY.ROLLUPS}>{intl.get("order-by-rollups")}</option>
							<option value={ORDER_BY.ROLLUPS_DESC}>{intl.get("order-by-rollups-desc")}</option>
						</select> */}


					{/* Show method: list or grid */}
					<label className="swap swap-rotate">
						<input
							type="checkbox"
							onChange={() => {
								setShowMethod(showMethod === "list" ? "grid" : "list");
								localStorage.setItem("ferc_show", showMethod === "list" ? "grid" : "list")
							}}
							checked={showMethod === "list"}
						/>
						<div className="swap-on"><BsListUl className='text-xl mr-5 md:-mt-2' /></div>
						<div className="swap-off"><BsFillGrid3X3GapFill className='text-xl mr-5 md:-mt-2' /></div>
					</label>

					{/* search type */}
					<div className="mt-2 md:mt-0 btn-group mb-3">
						<div className={`btn btn-md ` + (showType === 0 ? "btn-secondary" : "")} onClick={() => clickType(0)}>{intl.get("all")}</div>
						<div className={`btn btn-md ` + (showType === 1 ? "btn-secondary" : "")} onClick={() => clickType(1)}>{intl.get("in-progress")}</div>
						<div className={`btn btn-md ` + (showType === 2 ? "btn-secondary" : "")} onClick={() => clickType(2)}>{intl.get("ended")}</div>
						<div className={`btn btn-md ` + (showType === 3 ? "btn-secondary" : "")} onClick={() => clickType(3)}>{intl.get("voted")}</div>
					</div>
				</div>

				{/* Table of tokens */}
				<div className="">
					{showMethod === "list" ?
						<TokenList
							list={list}
							scanUrl={network.scanUrl}
							symbol={network.symbol}
							showType={showType}
							factory={network.contractAddress}
							checkTokenAddress={checkTokenAddress}
							setMintItem={setMintItem}
							version={contractVersion}
							currentAddress={address as string}
							rate={rate.current}
							socialContractAddress={network.socialContractAddress}
							conversationData={conversationData.current}
							setShowIFODetails={setShowIFODetails} // show ifo details
						/>
						:
						<TokenGrid
							list={list}
							scanUrl={network.scanUrl}
							symbol={network.symbol}
							showType={showType}
							factory={network.contractAddress}
							checkTokenAddress={checkTokenAddress}
							setMintItem={setMintItem}
							version={contractVersion}
							currentAddress={address as string}
							rate={rate.current}
							socialContractAddress={network.socialContractAddress}
							conversationData={conversationData.current}
							setShowIFODetails={setShowIFODetails} // show ifo details
						/>}
				</div>

				{/* pagination */}
				<div className="mt-5 mb-1 md:mb-5">
					<Pagination
						pageSize={pageSize}
						onPageClick={(page: number) => { setLoading(true); loadInscriptions(page, contractVersion); }}
						totalInscriptions={totalInscriptions}
					/>
				</div>
			</div>
			<div className="bg-base-200 md:h-[80px]" />

			{/* Deploy modal */}
			<Deploy
				network={network}
				version={contractVersion}
			/>

			{/* Mint modal: step 1 */}
			<Mint
				canMint={canMint}
				mintRepeat={mintRepeat}
				mintItem={mintItem}
				setMintRepeat={setMintRepeat}
				resetTip={resetTip}
			/>

			{/* Mint modal: step 2 */}
			<TokenDetails
				mintItem={mintItem}
				mintRepeat={mintRepeat}
				currentAddress={address as string}
				symbol={network.symbol}
				scanUrl={network.scanUrl}
				loadInscriptionData={() => loadInscriptionData(mintItem.addr)}
				tdRef={tokenDetailsRef}
			/>

			{/* FTO modal */}
			<IFODetails
				mintItem={mintItem}
				currentAddress={address as string}
				symbol={network.symbol}
				scanUrl={network.scanUrl}
				wethAddress={network.wethAddress}
				ifShow={showIFODetails}
				setShowIFODetails={setShowIFODetails} // hide FTO details
				network={network}
			/>

			{/* Toast */}
			<Toaster toastOptions={{
				duration: 4000,
			}} />

			{/* Loading */}
			{loading &&
				<div className="fixed top-0 left-0 w-full h-full bg-black/60 z-50 text-center py-[25%]">
					<div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
						role="status">
						<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
						</span>
					</div>
				</div>}

		</div>)
}

export default HomeMain;
