import { FC } from "react";
import { ConversationPosData, InscriptionData, TokenItemProps } from "../utils/types";
import { BN, formatAddress, formatDecimal2, formatEther, secondsToHMS, toNumber, toString } from "../utils/common";
import LogoItem from "./LogoItem";
import { FiExternalLink, FiMoreHorizontal } from "react-icons/fi";
import { BASE_URL, CONVERSATION_SLOT, FORMAT_DECIMAL2, ZERO_ADDRESS } from "../config";
import intl from "../utils/intl";
import LinkTo from "./LinkTo";
import toast from "react-hot-toast";
import { formatUnits } from "ethers/lib/utils";

const TokenListItem: FC<TokenItemProps> = ({
	item,
	version,
	scanUrl,
	currentAddress,
	conversationData,
	symbol,
	rate,
	setMintItem,
	setShowIFODetails,
	checkTokenAddress,
	setConversation,
	setDeployer
}) => {
	// console.log("rate ===>", rate.get(item.addr));
	const mintPercent = (item: InscriptionData) => toNumber(item.totalRollups) / toNumber(item.maxRollups) * 100;
	const toLiquidityPerMint = (item: InscriptionData) => formatEther(BN(item.limitPerMint).div(BN(10000).sub(item.liquidityTokenPercent)).mul(item.liquidityTokenPercent));
	const getConversationBgColor = (id: number) => id === 0 ? "primary" : id === 1 ? "secondary" : "accent";
	const getSocialDataByTickPos = (addr: string, tick: string, pos: number) => {
		return {
			addr,
			tick,
			pos,
			name: conversationData.get(addr)?.names[pos],
			logo: conversationData.get(addr)?.logos[pos],
			url: conversationData.get(addr)?.urls[pos],
			rate: conversationData.get(addr)?.rates[pos],
			seller: conversationData.get(addr)?.sellers[pos],
			lastUpdate: conversationData.get(addr)?.lastUpdates[pos],
		} as ConversationPosData;
	}

	const onSlotClick = (e: any, index: number) => {
		const socialData: ConversationPosData = getSocialDataByTickPos(item.addr, item.tick, index);
		if (currentAddress !== item.deployer && socialData.name === "") {
			toast.error("You are not deployer and can not set this slot");
			return;
		} else {
			e.currentTarget.htmlFor = "socialBoxModal";
			setConversation(socialData);
			setDeployer(item.deployer);
		}
	}

	return (
		<>
			<tr>
				<td className="text-center">
					{toString(item.inscriptionId)}
				</td>
				<td className="flex">
					{item.logoUrl !== "" && item.logoUrl !== undefined ?
						<div className="w-10 mt-1 font-bold cursor-pointer">
							{/* <img
							src={IPFS_GATEWAY + item.logoUrl}
							className="rounded-full"
							alt={""}
							onError={(e) => { e.currentTarget.src = "/images/default-logo.jpeg" }}
						></img> */}
							<LogoItem cid={item.logoUrl} isVoted={item.isVoted} isGrid={false} />
						</div> :
						<div className="w-10 h-10 leading-10 mt-1 rounded-full bg-gray-500 font-bold cursor-pointer text-center">
							{item.name.substring(0, 1).toUpperCase()}
						</div>}
					<div className="relative ml-3">
						<div className="text-primary text-lg">{item.tick}<span className="text-gray-500 text-sm"> ({version})</span></div>
						<div className="text-sm text-gray-500">{item.name}</div>
					</div>
				</td>
				<td>
					<div className="flex">
						{item && formatAddress(item.addr)}
						<LinkTo
							copyText={item.addr}
							url={`${scanUrl}/token/${item.addr}#balances`}
						/>
					</div>
				</td>
				<td>
					<div>
						{formatDecimal2(parseFloat(formatEther(item.cap)), FORMAT_DECIMAL2)}
					</div>
					<div className="text-sm text-gray-500">
						{formatDecimal2(parseFloat(formatEther(item.totalMintable)), FORMAT_DECIMAL2)}
					</div>
				</td>
				<td>
					<div>
						{formatDecimal2(parseFloat(formatEther(item.limitPerMint)), FORMAT_DECIMAL2)}
					</div>
					<div className="text-sm text-gray-500">
						{formatDecimal2(parseFloat(toLiquidityPerMint(item)), FORMAT_DECIMAL2)}
					</div>
				</td>
				<td className='text-center'>
					<div>
						{secondsToHMS(toNumber(item.freezeTime))}
					</div>
					<div className="text-sm text-gray-500">
						{toNumber(item.freezeTime) === 0 ? item.maxMintSize.toString() + " mints" : "1 mint"}
					</div>
				</td>
				<td className='text-center'>
					<div className="text-sm">
						{item.isWhitelist ?
							<div className="flex justify-center">
								{intl.get("whitelist")}
								<a href={`/check-whitelist/${item.addr}/${currentAddress}`} target='_blank' rel="noreferrer">
									<FiExternalLink className='ml-2 mt-1 cursor-pointer' />
								</a>
							</div>
							:
							item.onlyContractAddress !== ZERO_ADDRESS ?
								<div>
									<div>
										{formatDecimal2(parseFloat(item.decimals && item.decimals.toString() !== "0" ? formatUnits(item.onlyMinQuantity, item.decimals) : item.onlyMinQuantity.toString()), FORMAT_DECIMAL2) + " " + item.onlyTokenName}
									</div>
									<div className='flex text-gray-500'>
										{formatAddress(item.onlyContractAddress)}
										<LinkTo
											copyText={item.onlyContractAddress}
											url={`${scanUrl}/token/${item.onlyContractAddress}#balances`}
										/>
									</div>
								</div>
								:
								item.customizedConditionContractAddress !== ZERO_ADDRESS ?
									<div>
										<div>
											{intl.get("condition-customize")}
										</div>
										<div className='flex text-gray-500'>
											{formatAddress(item.customizedConditionContractAddress)}
											<LinkTo
												copyText={item.customizedConditionContractAddress}
												url={`${scanUrl}/address/${item.customizedConditionContractAddress}`}
											/>
										</div>
									</div>
									:
									intl.get("condition-no")
						}
					</div>
				</td>
				<td className='text-center'>
					{item.isVesting ?
						<div>
							<div className='text-sm justify-center flex'>
								{intl.get("token-allocation-standard-vesting")}
								<a href={`${BASE_URL}/vesting/${item.addr}`} target='_blank' rel="noreferrer">
									<FiExternalLink className='ml-2 mt-1 cursor-pointer' />
								</a>
							</div>
							<div className='flex text-gray-500 text-sm'>
								{formatAddress(item.customizedVestingContractAddress)}
								<LinkTo
									copyText={item.customizedVestingContractAddress}
									url={`${scanUrl}/address/${item.customizedVestingContractAddress}`}
								/>
							</div>
						</div>
						:
						item.customizedVestingContractAddress === ZERO_ADDRESS ?
							<div className='text-sm'>
								{intl.get("token-allocation-no-vesting")}
							</div> :
							<div>
								<div>
									{intl.get("token-vesting-customize")}
								</div>
								<div className='flex text-gray-500'>
									{formatAddress(item.customizedVestingContractAddress)}
									<LinkTo
										copyText={item.customizedVestingContractAddress}
										url={`${scanUrl}/address/${item.customizedVestingContractAddress}`}
									/>
								</div>
							</div>
					}
				</td>
				<td className="text-center text-sm">
					{BN(item.crowdFundingRate).eq(0) ? intl.get("free") : formatDecimal2(parseFloat(formatEther(item.crowdFundingRate)), FORMAT_DECIMAL2) + " " + symbol}
				</td>
				<td className="text-center">
					<div className="text-sm text-center">
						{mintPercent(item).toFixed(2).toString() + "%"}
					</div>
					<div className='bg-primary-content h-2 rounded-full'>
						<progress className="progress progress-secondary float-left" value={mintPercent(item).toString()} max="100"></progress>
					</div>
					<div className="text-sm text-center text-gray-500">
						{item.totalRollups + "/" + item.maxRollups}
					</div>
				</td>
				{/* <td className=' justify-center '>
			<a href={`${scanUrl}/token/${item.addr}#balances`} target='_blank' rel="noreferrer">
				<FiExternalLink />
			</a>
		</td> */}
				<td className='text-center'>
					{item.isIFOMode && <>
						<label
							htmlFor="ifoModal"
							className="btn btn-outline btn-secondary btn-sm"
							onClick={() => {
								setShowIFODetails(true);
								setMintItem(item);
							}}
						>
							<FiMoreHorizontal />
						</label>
					</>}
				</td>
				<td className="text-center">
					{mintPercent(item) < 100 &&
						<label
							htmlFor="mintModal"
							className="btn btn-outline btn-secondary btn-sm"
							onClick={() => {
								setMintItem(item);
								checkTokenAddress(item.addr, 1)
							}}
						>
							{intl.get("mint")}
						</label>
					}
				</td>
				<td className="text-center">
					{/* Conversations */}
					<div className="avatar-group -space-x-4">
						{Array.from({ length: CONVERSATION_SLOT }, (_, index) => index).map((index) => {
							const name = conversationData.get(item.addr)?.names[index];
							if (name !== "" && name !== undefined) {

								const logo = conversationData.get(item.addr)?.logos[index];

								return (
									<label
										key={index}
										className={`avatar bg-${getConversationBgColor(index)} placeholder`}
										onClick={(e) => onSlotClick(e, index)}
									>
										{logo !== "" ?
											<div className="w-10 cursor-pointer">
												{/* <img src={IPFS_GATEWAY + logo} alt={name} /> */}
												<LogoItem cid={logo as string} />

											</div> :
											<div className="w-10 font-bold cursor-pointer text-neutral-content">
												<span>{index + 1}</span>
											</div>}
									</label>)
							} else {
								return (
									<label
										key={index}
										className={`avatar bg-${getConversationBgColor(index)} placeholder`}
										onClick={(e) => onSlotClick(e, index)}
									>
										<div className="w-10 font-bold cursor-pointer text-neutral-content">
											<span>{intl.get("empty")}</span>
										</div>
									</label>)
							}
						}
						)}
					</div>
				</td>
			</tr>
		</>)
}

export default TokenListItem;
