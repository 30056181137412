import { bsc, bscTestnet, mainnet, polygonMumbai, goerli, polygon, arbitrum, optimism, base } from 'wagmi/chains'
import { NetworkData } from './utils/types';

export const CHAINS = [mainnet, goerli, bsc, bscTestnet, polygon, polygonMumbai, arbitrum, optimism, base];
export const PROJECT_ID = "afc5645f77c08fc2a0a49697db686122"; // wallet connecter Id
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const WEB3STORAGE_EMAIL = process.env.REACT_APP_WEB3STORAGE_EMAIL as any;
export const WEB3STORAGE_SPACE = "Baja";
export const CONVERSATION_SLOT = 3;
export const TICK_SPACE = 60;
export const FORMAT_DECIMAL2 = 3;
export const MAX_TOKEN_DESCRIPTION_LENGTH = 16; // just one slot on blockchain
export const IPFS_GATEWAY = ".ipfs.w3s.link";// "https://gateway.pinata.cloud/ipfs/"; //"https://cloudflare-ipfs.com/ipfs/";//"https://ipfs.io/ipfs/"
export const WEBSTORAGE_APIKEY = process.env.REACT_APP_WEBSTORAGE_API_KEY as string;
export const IPIFY_API_KEY = process.env.REACT_APP_IPIFYIF_API_KEY as string;
export const MIN_FREEZE_TIME = 30;
export const PASSED_RATIO = 0.3;
export const BASE_URL = "https://ferc20.co/";
export const IMAGE_FILE_SIZE = 256 * 1024; // 256 k
export const TEST_TICKS = [ // ######
	"----1",
	"----2",
	"----3"
];
export const IMAGE_PROXY = (url: string) => `https://apoqrsgtqq.cloudimg.io/${url}`;

const NETWORK_CONFIG = {
	v3: [
		{
			id: 1,
			network: "Ethereum Mainnet",
			contractAddress: "0x17fe21fAB4784eCaE27c7bB43d3d3cf3b73e7aA7",
			whitelist: "0xF6a071aDfb73c9CE6bC7bb52671127573faFbf5f",
			fercToken: "0x2eCBa91da63C29EA80Fbe7b52632CA2d1F8e5Be0",
			voteForLaunchpad: "0xb9250f2dc0706f172F3565c11fcF9f7CFB2F27A7",
			socialContractAddress: "0x02dA6E2a2cD6bf12088Ff44625Fa4730057048Ec",
			wethAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
			uniswapV3Factory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
			nonfungiblePositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
			scanUrl: "https://etherscan.io",
			bgColor: "#dddddd",
			foreColor: "#000", fundingCommissions: 100, tickLength: [4, 5]
		}, {
			id: 10,
			network: "Optimism",
			contractAddress: "0xCc51d2C260CC5aF7B88164aBA485C9770e673Eb3",
			whitelist: "0x248A109eCD460A3480ccd0D0cd520f23c9a2023B",
			fercToken: "0xb055ffca6ec2849a5700fa851b28ff636c20b6e0",
			voteForLaunchpad: "0x77C31E5f57f49ed3acada28bae3595cE44056076",
			socialContractAddress: "0x7A2439C1a273F7801a10D3fFF348351A9431C331",
			wethAddress: "0x4200000000000000000000000000000000000006",
			uniswapV3Factory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
			nonfungiblePositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
			scanUrl: "https://optimistic.etherscan.io",
			bgColor: "#dddddd",
			foreColor: "#000", fundingCommissions: 100, tickLength: [4, 5]
		}, {
			id: 56,
			network: "BinanceBSC",
			contractAddress: "0x43507D187A897D370736F4099f1FfF44b1281EDa",
			whitelist: "0x4e14B9B88D709785B31366308f0d9C8C5DC2941B",
			fercToken: "0xC365B8Cbde40cB902CaE1BDDf425a4c9E1f60d3f",
			voteForLaunchpad: "0x5Dc15E8319a89B3677A4b9Ce95018d95B513514e",
			socialContractAddress: "0x71d70d22228e42EBCc8B524AbD292454Df257143",
			wethAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			uniswapV3Factory: "0xdB1d10011AD0Ff90774D0C6Bb92e5C5c8b4461F7",
			nonfungiblePositionManager: "0x7b8A01B39D58278b5DE7e48c8449c9f4F5170613",
			scanUrl: "https://www.bscscan.com",
			bgColor: "#f0b90a",
			foreColor: "#000", fundingCommissions: 100, tickLength: [4, 5]
		}, {
			id: 137,
			network: "Polygon Mainnet",
			contractAddress: "0xFcdef438907e5B3D1195ecF4663f0D63b40b960E",
			whitelist: "0x2f156E7092De49bBc4f5AEbD155FB40538f8F846",
			fercToken: "0xC365B8Cbde40cB902CaE1BDDf425a4c9E1f60d3f",
			voteForLaunchpad: "0x9BE309209BDe223cf52205510642C321DEf0afAf",
			socialContractAddress: "0x51298f6e93B34E229AAbb2E692d03Ea0147D3f09",
			wethAddress: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
			uniswapV3Factory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
			nonfungiblePositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
			scanUrl: "https://polygonscan.com",
			bgColor: "#8148e5",
			foreColor: "#fff", fundingCommissions: 100, tickLength: [4, 5]
		}, {
			id: 42161,
			network: "Abitrum One",
			contractAddress: "0xcF2D65F62CAe6Db823062112b2623693805de348",
			whitelist: "0x03B3cfc722e816967a6a06b2b231Db9679433696",
			fercToken: "0xC365B8Cbde40cB902CaE1BDDf425a4c9E1f60d3f",
			voteForLaunchpad: "0xcE7C57AB53bC53899b122Eb92dF32fF549c2d4F6",
			socialContractAddress: "0xCc51d2C260CC5aF7B88164aBA485C9770e673Eb3",
			wethAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
			uniswapV3Factory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
			nonfungiblePositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
			scanUrl: "https://arbiscan.io",
			bgColor: "#27a1f0",
			foreColor: "#fff", fundingCommissions: 100, tickLength: [4, 5]
		},
		{
			id: 80001,
			network: "mumbai",
			contractAddress: "0x24a6ADAfb08Fb568f1ef6165fE977A8178bff2f5",
			whitelist: "0xF67159AB3F61055d7a01b5DBEdc7c2dD554A453d",
			fercToken: "0x0241F2b4E3C4895ADb438F8ab10FCcCfeE1e1fDC",
			voteForLaunchpad: "0x601172b19b8FC546aaeE0027d5B1fD4936078848",
			socialContractAddress: "0x1e951De2CB1B499D10c63F585279977c2F50A9d6", //"0x16eeC227430775424836ABfb80cA0f4cf3BaE13B",
			wethAddress: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
			uniswapV3Factory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
			nonfungiblePositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
			scanUrl: "https://mumbai.polygonscan.com",
			bgColor: "#8148e5",
			foreColor: "#fff", fundingCommissions: 100, tickLength: [4, 5]
		},
		{
			id: 8453,
			network: "Base Mainnet",
			contractAddress: "0x43E1d73dDBE3E8ED47965C7F35b4959eB7e3C0a6",
			whitelist: "0x838d70128cA816087C73d82F9aA925CDE0023ff2",
			fercToken: "0xae549E0b6eaa248282EA43e2f1C85E9D4032fEF4",
			voteForLaunchpad: "0x10F57B1A84bECe30aeB08fFf4953EADdDb3df9d8",
			socialContractAddress: "0xF50E40A8237Fee32d0A1a6a9e979A2Eb950D00aA", //"0x16eeC227430775424836ABfb80cA0f4cf3BaE13B",
			wethAddress: "0x4200000000000000000000000000000000000006",
			uniswapV3Factory: "0x33128a8fC17869897dcE68Ed026d694621f6FDfD",
			nonfungiblePositionManager: "0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1",
			scanUrl: "https://basescan.org",
			bgColor: "#8148e5",
			foreColor: "#fff", fundingCommissions: 500, tickLength: [2, 6]
		},

	],
	v31: [
		{
			id: 1,
			network: "Ethereum Mainnet",
			contractAddress: "0x03685807f3db5Aff080dB5a710f2AC107355115a",
			whitelist: "0xa404F2ed6474e6d17654721C576b0C047a124185",
			fercToken: "0x2eCBa91da63C29EA80Fbe7b52632CA2d1F8e5Be0",
			voteForLaunchpad: "0xCf2fE580B20A9406D4A4172429A81F0d444cDDF7",
			socialContractAddress: "0xE28851342a5d2c70423CF376D0EaD787a0ec0E29",
			wethAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
			uniswapV3Factory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
			nonfungiblePositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
			scanUrl: "https://etherscan.io",
			bgColor: "#dddddd",
			foreColor: "#000", fundingCommissions: 500, tickLength: [2, 6]
		}, {
			id: 10,
			network: "Optimism",
			contractAddress: "0xcD05EBdabb7FB4f335e46fb0B82eF305E9bA22e2",
			whitelist: "0xcAc83F2b920F8b587ebC789d77332632e9f8bD5d",
			fercToken: "0xb055ffca6ec2849a5700fa851b28ff636c20b6e0",
			voteForLaunchpad: "0xCa1Ce81726924E39536C9C15F2536dDdc4Ba388c",
			socialContractAddress: "0x83FE1eA163766740E2515dA0Bfe6884d4e5D3565",
			wethAddress: "0x4200000000000000000000000000000000000006",
			uniswapV3Factory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
			nonfungiblePositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
			scanUrl: "https://optimistic.etherscan.io",
			bgColor: "#dddddd",
			foreColor: "#000", fundingCommissions: 500, tickLength: [2, 6]
		}, {
			id: 56,
			network: "BinanceBSC",
			contractAddress: "0x0Ad3107C0278818ffb31E4C2D30810bbd6a4553e",
			whitelist: "0x6B677fd969CB698561792145f69c676f691C5ffB",
			fercToken: "0xC365B8Cbde40cB902CaE1BDDf425a4c9E1f60d3f",
			voteForLaunchpad: "0xC25Fce6BC3890E1AC2F1E5ee3D649DeA9EF82d3D",
			socialContractAddress: "0x2ab0c9B29Eb0F5388EE1203F7f1419187a9CA50d",
			wethAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			uniswapV3Factory: "0xdB1d10011AD0Ff90774D0C6Bb92e5C5c8b4461F7",
			nonfungiblePositionManager: "0x7b8A01B39D58278b5DE7e48c8449c9f4F5170613",
			scanUrl: "https://www.bscscan.com",
			bgColor: "#f0b90a",
			foreColor: "#000", fundingCommissions: 500, tickLength: [2, 6]
		}, {
			id: 137,
			network: "Polygon Mainnet",
			contractAddress: "0x6D461E687Aa996Dc08A2F89777534f5adbDe4D0a",
			whitelist: "0xc3782A39E891928162D7d9d20c8375A6b3ebD523",
			fercToken: "0xC365B8Cbde40cB902CaE1BDDf425a4c9E1f60d3f",
			voteForLaunchpad: "0xB2039dDFa0b2a0EEe3551Df895a078439A5a4BDB",
			socialContractAddress: "0x39EE57E354401330F81674940b8dbe443e977239",
			wethAddress: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
			uniswapV3Factory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
			nonfungiblePositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
			scanUrl: "https://polygonscan.com",
			bgColor: "#8148e5",
			foreColor: "#fff", fundingCommissions: 500, tickLength: [2, 6]
		}, {
			id: 42161,
			network: "Abitrum One",
			contractAddress: "0x83FE1eA163766740E2515dA0Bfe6884d4e5D3565",
			whitelist: "0xd72BCDab90C6cB03A2a046A8397d4b296e1Ba25F",
			fercToken: "0xC365B8Cbde40cB902CaE1BDDf425a4c9E1f60d3f",
			voteForLaunchpad: "0x5edE26a0C707B9A84EbD6c982F07cA77b96174ec",
			socialContractAddress: "0xe06523ACe0ac20F0fb04EF691bD65a15bB952BA5",
			wethAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
			uniswapV3Factory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
			nonfungiblePositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
			scanUrl: "https://arbiscan.io",
			bgColor: "#27a1f0",
			foreColor: "#fff", fundingCommissions: 500, tickLength: [2, 6]
		},
		// {
		// 	id: 80001,
		// 	network: "mumbai",
		// 	contractAddress: "0x16dabfAD608EDAC8bC77ab92a89f66E44AF6EdfA",
		// 	contractAddressV2: "0x14eC761DefCa418309488e20963B3070152DDc3E",
		// 	// contractAddress: "0xc23aF3E393a1E15CB29B21b1854da6BCEA143d13",
		// 	// contractAddress: "0x67Fc45643B241528e16528d4FF981f1506bD03eD", // public test on Aug.1
		// 	contractAddress: "0x24a6ADAfb08Fb568f1ef6165fE977A8178bff2f5",
		// 	// whitelist: "0x8267d2Ce76CBA1A7DA42bff31bee620c661704d2", // public test on Aug.1
		// 	whitelist: "0xF67159AB3F61055d7a01b5DBEdc7c2dD554A453d",
		// 	// fercToken: "0x90A4e1118BAcA4F6337f667890a780A6E34FB757",
		// 	// fercToken: "0x787d22E30e87027B16b3bF37F273aa13bBed8d57", // public test on Aug.1
		// 	fercToken: "0x0241F2b4E3C4895ADb438F8ab10FCcCfeE1e1fDC",
		// 	// voteForLaunchpad: "0xe62B960CB40637575E393C4804360dD98728C377", // public test on Aug.1
		// 	voteForLaunchpad: "0x601172b19b8FC546aaeE0027d5B1fD4936078848",
		// 	socialContractAddress: "0x1e951De2CB1B499D10c63F585279977c2F50A9d6", //"0x16eeC227430775424836ABfb80cA0f4cf3BaE13B",
		// 	wethAddress: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
		// 	uniswapV3Factory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
		// 	nonfungiblePositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
		// 	scanUrl: "https://mumbai.polygonscan.com",
		// 	bgColor: "#8148e5",
		// 	foreColor: "#fff", fundingCommissions: 500, tickLength: [2, 6]
		// },
		{
			id: 80001,
			network: "mumbai",
			contractAddress: "0x118EF48a98795E4186BC2E7d0D7E02a8528AF899",
			whitelist: "0xb2fE299a233912B86E5ee5f0D00bF215C3C672E1",
			fercToken: "0x0241F2b4E3C4895ADb438F8ab10FCcCfeE1e1fDC",
			voteForLaunchpad: "0x54013CFfae4d4144B90F28d4Ec1cAED1E19aFc41",
			socialContractAddress: "0xB0800baFC3801f38E48BB07D6B4059472b2109CD", //"0x16eeC227430775424836ABfb80cA0f4cf3BaE13B",
			wethAddress: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
			uniswapV3Factory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
			nonfungiblePositionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
			scanUrl: "https://mumbai.polygonscan.com",
			bgColor: "#8148e5",
			foreColor: "#fff", fundingCommissions: 500, tickLength: [2, 6]
		},
		{
			id: 8453,
			network: "Base Mainnet",
			contractAddress: "0x43E1d73dDBE3E8ED47965C7F35b4959eB7e3C0a6",
			whitelist: "0x838d70128cA816087C73d82F9aA925CDE0023ff2",
			fercToken: "0xae549E0b6eaa248282EA43e2f1C85E9D4032fEF4",
			voteForLaunchpad: "0x10F57B1A84bECe30aeB08fFf4953EADdDb3df9d8",
			socialContractAddress: "0xF50E40A8237Fee32d0A1a6a9e979A2Eb950D00aA", //"0x16eeC227430775424836ABfb80cA0f4cf3BaE13B",
			wethAddress: "0x4200000000000000000000000000000000000006",
			uniswapV3Factory: "0x33128a8fC17869897dcE68Ed026d694621f6FDfD",
			nonfungiblePositionManager: "0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1",
			scanUrl: "https://basescan.org",
			bgColor: "#8148e5",
			foreColor: "#fff", fundingCommissions: 500, tickLength: [2, 6]
		},
	]
} as any;

export const getNetwork = (id: number, version: string) => {
	if (version === '' || version === undefined) return null
	return NETWORK_CONFIG[version].find((v: NetworkData, i: number) => v.id === id)
};