import { FC, useEffect, useState } from "react";
import { ApplicationData, LaunchpadData, NewLaunchpadProps } from "../utils/types";
import intl from "../utils/intl";
import VoteForLaunchABI from "../abi/VoteForLaunch.json";
import { writeContract, waitForTransaction, readContract } from "@wagmi/core";
import toast from "react-hot-toast";
import LinkTo from "./LinkTo";
import { BigNumber, ethers } from "ethers";
import InscriptionABI from "../abi/Inscription.json";
import { useAccount } from "wagmi";
import { BN, formatEther, parseEther, voteUrl } from "../utils/common";

const NewLaunchpad: FC<NewLaunchpadProps> = ({
	network,
	version
}) => {
	const [loading, setLoading] = useState(false);
	const [tick, setTick] = useState("");
	const [expireDays, setExpireDays] = useState("10");
	const [description, setDescription] = useState("");
	const [showVoteUrl, setShowVoteUrl] = useState(false);
	const [depositForApplying, setDepositForApplying] = useState("0");

	const { address: currentAddress } = useAccount();

	useEffect(() => {
		if (network.voteForLaunchpad !== undefined) {
			readContract({
				address: network.voteForLaunchpad as any,
				abi: VoteForLaunchABI,
				functionName: "newVoteDeposit",
				args: []
			}).then((amount: any) => {
				setDepositForApplying(formatEther(BN(amount)));
			})
		}
	}, [network.voteForLaunchpad]);

	const makeFileObjects = () => {
		// const blob = new Blob([description], { type: 'application/text' }); // plain text
		const blob = new Blob([JSON.stringify({ description })], { type: 'application/json' }); // json text
		return [
			new File([blob], 'description.json')
		];
	}

	const addApplication = async () => {
		// upload description to IPFS
		if (description === "") {
			toast.error("Description can not be empty");
			return;
		}

		if (tick.trim().length < network.tickLength[0] || tick.trim().length > network.tickLength[1]) {
			toast.error("Tick size should be " + network.tickLength[0] + " to " + network.tickLength[1]);
			return;
		}

		if (parseInt(expireDays) > 10) {
			toast.error("Max voting days is 10 days.");
			return;
		}

		setLoading(true);
		try {
			toast.success("Uploading description to ipfs network");
			const descriptionCid = await window.w3sClient.put(makeFileObjects());
			// console.log("cid", descriptionCid);

			if (!descriptionCid.trim().startsWith("Qm") && !descriptionCid.trim().startsWith("bafyb")) {
				toast.error("Upload ipfs is wrong");
				setLoading(false);
				return;
			}

			// check allowance
			const amount: BigNumber = parseEther(depositForApplying);
			const allowance = await readContract({
				address: network.fercToken as any,
				abi: InscriptionABI,
				functionName: "allowance",
				args: [currentAddress, network.voteForLaunchpad]
			});

			if (BN(allowance).lt(amount)) {
				// approve
				const { hash } = await writeContract({
					address: network.fercToken as any,
					abi: InscriptionABI,
					functionName: "approve",
					args: [network.voteForLaunchpad, amount],
				})

				waitForTransaction({ hash }).then(async (data) => {
					if (data.status === 'success') {
						toast.success("Approve successfully");
						await doAddApplication(descriptionCid);
					} else {
						toast.error("Approve Error");
						setLoading(false);
					}
				})
			} else {
				await doAddApplication(descriptionCid);
			}
		} catch (err) {
			toast.error("Error: " + (err as any).shortMessage);
			setLoading(false);
		}
	}

	const doAddApplication = async (_descriptionCid: string) => {
		try {
			const { hash } = await writeContract({
				address: network.voteForLaunchpad as any,
				abi: VoteForLaunchABI,
				functionName: 'newVote',
				args: [
					tick.trim().toLowerCase(),
					parseInt(expireDays) * 24 * 3600, // production
					// 500, // ###### development
					_descriptionCid
				],
			});

			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success("Add successfully");
					setShowVoteUrl(true);
				} else {
					toast.error("Add Error");
				}
				setLoading(false);
			})
		} catch (err) {
			toast.error("Error: " + (err as any).shortMessage);
			setLoading(false);
		}
	}

	return (
		<div>
			<input type="checkbox" id="newLaunchpad" className="modal-toggle" />
			<label htmlFor="newLaunchpad" className="modal cursor-pointer">
				<label className="modal-box w-12/12 max-w-2xl text-sm md:text-md" htmlFor="">
					<label htmlFor="newLaunchpad" className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</label>
					<h3 className="font-bold text-lg">{intl.get("new-launchpad")}</h3>
					<div className="divider" />

					<div className="input-group flex justify-center mb-3">
						<span className="w-[40%] text-left">🐶 {intl.get("launchpad-tick")}</span>
						<input
							type="text"
							defaultValue={tick}
							placeholder={`${network.tickLength && network.tickLength[0]}-${network.tickLength && network.tickLength[1]} characters like 'abcd'...`}
							className="input input-bordered w-[60%]"
							onChange={(e) => setTick(e.target.value)}
						/>
					</div>

					<div className="input-group flex justify-center mb-3">
						<span className="w-[40%] text-left">🐙 {intl.get("launchpad-expire-seconds")}</span>
						<input
							type="text"
							defaultValue={expireDays}
							placeholder="Expire days of this proposal"
							className="input input-bordered w-[60%]"
							onChange={(e) => setExpireDays(e.target.value)}
						/>
					</div>

					<div className="input-group flex justify-center mb-3">
						<span className="w-[40%] text-left">🐹 {intl.get("launchpad-description")}</span>
						<textarea
							value={description}
							placeholder="Enter description of your project, this description will be saved on IPFS"
							className="input input-bordered w-[60%] py-2 px-4 h-[150px]"
							onChange={(e) => setDescription(e.target.value)}
						></textarea>

					</div>

					<div className="input-group flex justify-center mb-3">
						<span className="w-[40%] text-left">🦄 {intl.get("launchpad-deposit-for-new")}</span>
						<span className="input input-bordered w-[60%] bg-base-100">{depositForApplying}</span>
					</div>

					<div className="modal-action">
						<label
							className={`btn btn-secondary ${loading ? 'loading' : ''}`}
							onClick={() => addApplication()}
						>{intl.get("launchpad-apply")}
						</label>
					</div>

					{showVoteUrl &&
						<div className="modal-footer flex">
							<span>{intl.get("launchpad-vote-url")}&nbsp;</span>
							<span>{voteUrl(tick)}</span>
							<LinkTo
								copyText={voteUrl(tick)}
								url={voteUrl(tick)}
							/>
						</div>}
				</label>
			</label>
		</div>
	)
}

export default NewLaunchpad;