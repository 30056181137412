import { FC, useEffect, useState } from "react";
import { MintProps } from "../utils/types";
import { BN, toNumber } from "../utils/common";
import intl from "../utils/intl";
import { BigNumber } from "ethers";

const Mint: FC<MintProps> = ({
	canMint,
	mintRepeat,
	setMintRepeat,
	mintItem,
	resetTip,
}) => {
	const [canRepeat, setCanRepeat] = useState(false);
	const [maxMintSize, setMaxMintSize] = useState(0);

	useEffect(() => {
		if(mintItem && mintItem.freezeTime !== undefined) {
			const freezeTime = toNumber(mintItem.freezeTime);
			setCanRepeat(freezeTime === 0);
			if(freezeTime > 0) setMintRepeat(1);
		}

		if(mintItem && mintItem.maxRollups !== undefined && mintItem.totalRollups !== undefined && mintItem.maxMintSize !== undefined) {
			const _balanceRollups = BN(mintItem?.maxRollups).sub(mintItem?.totalRollups as BigNumber);
			const _maxMintSize = mintItem?.maxMintSize as BigNumber;
			setMaxMintSize(
				_balanceRollups.lt(_maxMintSize) ? toNumber(_balanceRollups) : toNumber(_maxMintSize)
			);		
		}
	}, [mintItem, setMintRepeat]);

	return(
	<>
		<div>
			<input type="checkbox" id="mintModal" className="modal-toggle" />
			<label htmlFor="mintModal" className="modal cursor-pointer">
				<label className="modal-box text-sm md:text-md" htmlFor="">
					<label htmlFor="mintModal" className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</label>
					<h3 className="font-bold text-lg">{intl.get("mint")}</h3>
					<div className="divider"/>

					<div className="input-group flex justify-center mb-3">
						<span className="w-[40%] text-left">🌞&nbsp;{intl.get("tick1")}</span>
						<span className="input input-bordered w-[60%] bg-base-100">{mintItem?.tick}</span>
					</div>

					{canRepeat && 
					<div className="input-group flex justify-center mb-3">
						<span className="w-[40%] text-left">⚡️&nbsp;{intl.get("rollup-mint") + " x " + mintRepeat }</span>
						<div className="w-[60%] ">
							<input 
								type="range" 
								min={"1"} 
								max={maxMintSize.toString()} 
								step={"1"}
								value={mintRepeat.toString()} 
								className="range range-secondary mt-1"
								onChange={(e) => setMintRepeat(parseInt(e.target.value))}
							/>	
							<div className="w-full flex justify-between text-xs -mt-1">
								{Array.from({ length: maxMintSize }, (_, index) => index).map((index) => 
									<span key={index} className="bg-base-100 text-base-400 px-1">|</span>
								)}
							</div>
						</div>
					</div>}

					<div className="modal-action">
						<label 
							htmlFor="mintModal2"
							className={`btn ${canMint ? 'btn-secondary' : 'btn-disabled'}`}
							onClick={() => resetTip()}
						>{intl.get("next")}
						</label>
					</div>
				</label>
			</label>
		</div>
	</>)
}

export default Mint;