import dayjs from "dayjs";
import { BigNumber, ethers, providers, utils } from "ethers";
import { NetworkData, UniswapV3Price } from "./types";
import { BASE_URL, FORMAT_DECIMAL2, IPIFY_API_KEY, TICK_SPACE, getNetwork } from "../config";
import { Chain } from "wagmi";
import { HttpTransport } from "viem";
import { type PublicClient, getPublicClient } from '@wagmi/core'
import { publicIpv4} from 'public-ip';

export const BN = (num: any): BigNumber => BigNumber.from(num);
export const formatEther = (wei: ethers.BigNumberish): string => ethers.utils.formatEther(wei);
export const parseEther = (eth: string): BigNumber => ethers.utils.parseEther(eth);

const Q96 = BN(2).pow(96);
const MIN_TICK = -887220;
const MAX_TICK = 887220;

export const formatAddress = (address: string | undefined): string => {
	if (address === null) return "";
	return address?.substr(0, 6) + "..." + address?.substr(address?.length - 4, 4);
};

export const formatDate = (timestamp: number | undefined): string => {
	if (timestamp === undefined) return "";
	const date = new Date(timestamp);
	const formatter = dayjs(date);
	return formatter.format("YYYY/MM/DD HH:mm:ss");
};

export const addCommas = (num: string) => {
	var str = num.split('.');
	if (str[0].length >= 4) {
		str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
	}
	if (parseInt(str[1]) === 0) return str[0];
	else {
		if (str[1] && str[1].length >= 4) {
			str[1] = str[1].replace(/(\d{3})/g, '$1 ');
		}
		return str.join('.');
	}
}

export const secondsToHMS = (seconds: number) => {
	if (seconds === 0) return "Free";
	let hours = Math.floor(seconds / 3600);
	let minutes = Math.floor((seconds - (hours * 3600)) / 60);
	let remainingSeconds = seconds - (hours * 3600) - (minutes * 60);

	return (hours === 0 ? "" : hours + " hour")
		+ (minutes === 0 ? "" : minutes + " min")
		+ (remainingSeconds === 0 ? "" : remainingSeconds + ' sec');
}

export const toNumber = (value: any) => BN(value).toNumber();
export const toString = (value: any) => BN(value).toString();

export const countZero = (number: number, len: number) => {
	const str = number.toFixed(30).toString();
	const decimalIndex = str.indexOf('.');
	const decimalPart = decimalIndex === -1 ? '' : str.slice(decimalIndex + 1);
	let zeroCount = 0;
	for (let i = 0; i < decimalPart.length; i++) {
		if (decimalPart[i] !== '0') {
			break;
		} else {
			zeroCount++;
		}
	}
	if (zeroCount === 30) return -len;
	else return zeroCount;
}

export const formatDecimal = (number: number, len: number) => {
	return number.toFixed(countZero(number, len) + len);
}

export const formatDecimal2 = (number: number, len: number) => {
	const str = number.toFixed(30).toString();
	const decimalIndex = str.indexOf('.');
	const intPart = decimalIndex === -1 ? str : str.substring(0, decimalIndex);
	const decimalPart = decimalIndex === -1 ? '' : str.slice(decimalIndex + 1);
	const zeroCount = countZero(number, len);
	// console.log(number, zeroCount, len - 1); // 1, 5
	if (zeroCount > len - 1) return intPart.replace(/(\d)(?=(\d{3})+$)/g, '$1,') + ".0{" + zeroCount + "}" + decimalPart.substring(zeroCount, zeroCount + len);
	else return addCommas(number.toFixed(zeroCount + len > 0 ? zeroCount + len - 1 : zeroCount + len));
}

export const tickToPrice = (tick: number) => {
	if (tick === MIN_TICK) return "0";
	else if (tick === MAX_TICK) return "∞";
	else return formatDecimal2(1.0001 ** tick, FORMAT_DECIMAL2);
}

export const getTickAtSqrtRatio = (sqrtPriceX96: BigNumber): number => Math.round(Math.log(toNumber((BN(sqrtPriceX96).div(Q96)).pow(2))) / Math.log(1.0001) / TICK_SPACE) * TICK_SPACE;

export const getTickAtPrice = (price: number): number => Math.round(Math.log(price) / Math.log(1.0001) / TICK_SPACE) * TICK_SPACE;

export const getTokenAmounts = (liquidity: BigNumber, currentPrice: UniswapV3Price, tickLow: number, tickHigh: number) => {
	const sqrtRatioA = Math.sqrt(1.0001 ** tickLow);
	const sqrtRatioB = Math.sqrt(1.0001 ** tickHigh);
	// console.log("sqrtRatioA", sqrtRatioA);
	// console.log("sqrtRatioB", sqrtRatioB);	
	// console.log("sqrtPriceX96", currentPrice.sqrtPriceX96);

	const currentTick = toNumber(currentPrice.tick);
	const sqrtPrice = Math.sqrt(1.0001 ** toNumber(currentPrice.tick));	// 方案2

	// console.log("liquidity", liquidity)
	// console.log("tick", tickLow, tickHigh);
	// console.log("Q96", Q96);
	// console.log("currentTick: ", currentTick);
	// console.log("sqrtPrice:", sqrtPrice);
	// console.log("currentPrice", sqrtPrice**2);

	let amount0: BigNumber = BN(0);
	let amount1: BigNumber = BN(0);

	if (currentTick <= tickLow) {
		// console.log("< lowerTick")
		const _a = (sqrtRatioB - sqrtRatioA) / (sqrtRatioA * sqrtRatioB);
		amount0 = multiply(liquidity, _a);
	} else if (currentTick > tickHigh) {
		// console.log("> upperTick")
		amount1 = multiply(liquidity, (sqrtRatioB - sqrtRatioA));
	} else if (currentTick > tickLow && currentTick <= tickHigh) {
		// console.log("between lower and upper ticks")
		const _a = (sqrtRatioB - sqrtPrice) / (sqrtPrice * sqrtRatioB);
		const _b = sqrtPrice - sqrtRatioA;
		amount0 = multiply(liquidity, _a);
		amount1 = multiply(liquidity, _b);
	}
	// console.log("Amount Token0 wei: " + amount0);
	// console.log("Amount Token1 wei: " + amount1);
	// console.log("================================")
	return {
		amount0,
		amount1,
		price: sqrtPrice ** 2,
	}
}

const oneBN: BigNumber = utils.parseUnits("1", 18);
export const multiply = (bn: BigNumber | string, number: number): BigNumber => {
	const bnForSure = BN(bn);
	const numberBN = utils.parseUnits(number.toFixed(18).toString(), 18);
	return bnForSure.mul(numberBN).div(oneBN);
}

export const divide = (bn: BigNumber | string, number: number): BigNumber => {
	const bnForSure = BN(bn);
	const numberBN = utils.parseUnits(number.toFixed(18).toString(), 18);
	return bnForSure.div(numberBN).div(oneBN);
}

export const compareAddress = (address0: string, address1: string): boolean => {
	// return true if address0 smaller than address1
	const address0Hex = utils.hexlify(address0);
	const address1Hex = utils.hexlify(address1);
	const result = (address0Hex < address1Hex);
	return result;
}


export const getContractAddressByVersion = (network: NetworkData): string | any => {
	return network.contractAddress;
}

export const getNetworkData = (chain: Chain,version='v31') => {
	const _network = getNetwork(chain.id,version);
	return ({
		id: chain.id,
		name: chain.name,
		symbol: chain.nativeCurrency.symbol,
		decimals: chain.nativeCurrency.decimals,
		scanUrl: _network?.scanUrl as string,
		contractAddress: _network?.contractAddress as string,
		wethAddress: _network?.wethAddress as string,
		uniswapV3Factory: _network?.uniswapV3Factory as string,
		nonfungiblePositionManager: _network?.nonfungiblePositionManager as string,
		socialContractAddress: _network?.socialContractAddress as string,
		bgColor: _network?.bgColor as string,
		foreColor: _network?.foreColor as string,
		fercToken: _network?.fercToken as string,
		voteForLaunchpad: _network?.voteForLaunchpad as string,
		whitelist: _network?.whitelist as string,
		fundingCommissions:_network?.fundingCommissions,
		tickLength:_network?.tickLength
	} as NetworkData)
}

export function publicClientToProvider(publicClient: PublicClient) {
	const { chain, transport } = publicClient
	const network = {
		chainId: chain.id,
		name: chain.name,
		ensAddress: chain.contracts?.ensRegistry?.address,
	}

	if (transport.type === 'fallback') {
		return new providers.FallbackProvider(
			(transport.transports as ReturnType<HttpTransport>[]).map(
				({ value }) => new providers.JsonRpcProvider(value?.url, network),
			),
		)
	}
	return new providers.JsonRpcProvider(transport.url, network)
}

export function getEthersProvider({ chainId }: { chainId?: number } = {}) {
	const publicClient = getPublicClient({ chainId })
	return publicClientToProvider(publicClient)
}

export const getWeb3Provider = (): ethers.providers.JsonRpcProvider => {
	return new ethers.providers.Web3Provider(window.ethereum);
}

export const voteUrl = (_tick: string) => {
	return BASE_URL + _tick;
}

export const getIPAndGeo = async () => {
	//https://geo.ipify.org/api/v2/country?apiKey=at_VRCheR7IyCrMHhmQbvxn8K39sgYVe&ipAddress=8.8.8.8
	const ip = await publicIpv4();
	const response = await fetch(`https://geo.ipify.org/api/v2/country?apiKey=${IPIFY_API_KEY}&ipAddress=${ip}`);
	return await response.json();
}

export const checkDoubleIP = async (freezetime: BigNumber) => {
	// console.log(freezetime);
	const ip = await publicIpv4();
	console.log(ip, localStorage.getItem("ferc_ip"))
	const lastIPTimestamp = localStorage.getItem("ferc_ip_time") ? localStorage.getItem("ferc_ip_time") as string : "0";
	// console.log((new Date()).getTime(), parseInt(lastIPTimestamp), parseInt(BN(freezetime).mul(1000).toString()));
	// console.log((new Date()).getTime() - parseInt(lastIPTimestamp) > parseInt(BN(freezetime).mul(1000).toString()));
	if(ip === localStorage.getItem("ferc_ip")) {
		if ((new Date()).getTime() - parseInt(lastIPTimestamp) > parseInt(BN(freezetime).mul(1000).toString())) return false;
		else return true;
	} else return false;
}

export const resetIP = async () => {
	localStorage.setItem("ferc_ip", await publicIpv4());
	localStorage.setItem("ferc_ip_time", (new Date()).getTime().toString());
}