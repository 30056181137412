import { FC, useState } from "react";
import { AddLiquidityProps } from "../utils/types";
import intl from "../utils/intl";
import { compareAddress, getTickAtPrice } from "../utils/common";
import { writeContract, waitForTransaction } from "@wagmi/core";
import InitialFairOfferingABI from '../abi/InitialFairOffering.json';
import toast from "react-hot-toast";

const AddLiquidity: FC<AddLiquidityProps> = ({
	canAddLiquidity,
	tokenAddress,
	ifoContractAddress,
	currentPrice,
	wethAddress,
	reload
}) => {
	const supportPricRange = false;

	const [loading, setLoading] = useState(false);
	const [lowerPrice, setLowerPrice] = useState("0");
	const [upperPrice, setupperPrice] = useState("0");
	const [isFullRange, setIsFullRange] = useState("1");
	const [slippage, setSlippage] = useState("1000");
	// const [liquidityRatio, setLiquidityRatio] = useState("2000");


	const addLiquidity = async () => {
		let _lowerPrice;
		let _upperPrice;
		if (supportPricRange) {
			if (isFullRange === "1") {
				_lowerPrice = -887220;
				_upperPrice = 887220;
			} else {
				if (compareAddress(wethAddress, tokenAddress)) {
					_lowerPrice = getTickAtPrice(parseFloat(upperPrice)) * (-1);
					_upperPrice = getTickAtPrice(parseFloat(lowerPrice)) * (-1);
				} else {
					_lowerPrice = getTickAtPrice(parseFloat(lowerPrice));
					_upperPrice = getTickAtPrice(parseFloat(upperPrice));
				}
			}

			// console.log(compareAddress(wethAddress, tokenAddress)); // weth > token
			// console.log(liquidityRatio);
			// console.log(slippage);
			if (compareAddress(wethAddress, tokenAddress)) {
				const a = _upperPrice;
				_upperPrice = -_lowerPrice;
				_lowerPrice = -a;
			}
			// console.log(_lowerPrice);
			// console.log(_upperPrice);
		}

		setLoading(true);
		try {
			const { hash } = await writeContract({
				address: ifoContractAddress as any,
				abi: InitialFairOfferingABI,
				functionName: 'addLiquidity',
				// args: supportPricRange ? [liquidityRatio, _lowerPrice, _upperPrice, slippage] : [liquidityRatio, slippage],
				// args: [slippage],
				args: ["10000"],
			});

			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success("Add liquidity successfully");
				} else {
					toast.error("Add liquidity Error");
				}
				reload();
				setLoading(false);
			})
		} catch (err) {
			console.log(err);

			toast.error("Error: " + (err as any).shortMessage);
			setLoading(false);
		}
	}

	return (
		<>
			<div>
				<input type="checkbox" id="addLiquidityModal" className="modal-toggle" />
				<label htmlFor="addLiquidityModal" className="modal cursor-pointer">
					<label className="modal-box text-sm md:text-md" htmlFor="">
						<label className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" htmlFor="addLiquidityModal">✕</label>
						<h3 className="font-bold text-lg">{intl.get("add-liquidity")}</h3>
						<div className="divider" />

						{/* Stop the partitial liquidity adding */}
						{/* <div className="input-group flex justify-center mb-3">
						<span className="w-[40%] text-left">🦖 {intl.get("add-liquidity-ratio")}</span>
						<select 
							className="select select-bordered w-[60%] max-w-xs"
							onChange={(e) => setLiquidityRatio(e.currentTarget.value)}
							defaultValue="2000" 
						>
							<option value="1000">10%</option>
							<option value="2000">20%</option>
							<option value="3000">30%</option>
							<option value="4000">40%</option>
							<option value="5000">50%</option>
							<option value="6000">60%</option>
							<option value="7000">70%</option>
							<option value="8000">80%</option>
							<option value="9000">90%</option>
							<option value="10000">100%</option>
						</select>
					</div> */}
						{canAddLiquidity ? 
						<div className="flex">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
							<span className="ml-2 text-base">{intl.get("add-liquidity-desc")}</span>
						</div>
						:
						<div>
							<span className="ml-2 text-base">{intl.get("liquidity-added")}</span>
						</div>}

						{supportPricRange &&
							<div>
								<div className="input-group flex justify-center mb-3">
									<span className="w-[40%] text-left">🦕 {intl.get("add-liquidity-ratio")}</span>
									<select
										className="select select-bordered w-[60%] max-w-xs"
										onChange={(e) => setIsFullRange(e.currentTarget.value)}
										defaultValue="1"
									>
										<option value="1">{intl.get("full-range")}</option>
										<option value="0">{intl.get("set-tick-range")}</option>
									</select>
								</div>

								{isFullRange === "0" &&
									<div>
										<div className="flex justify-center mb-3">
											<span className="w-[40%] text-left ml-4">🐢 {intl.get("current-price")}</span>
											<span className="w-[60%] text-left ml-4">{currentPrice}</span>
										</div>

										<div className="input-group flex justify-center mb-3">
											<span className="w-[40%] text-left">🐊 {intl.get("lower-price")}</span>
											<input
												type="text"
												value={lowerPrice}
												placeholder=""
												className="input input-bordered w-[60%]"
												onChange={(e) => setLowerPrice(e.target.value)}
											/>
										</div>

										<div className="input-group flex justify-center mb-3">
											<span className="w-[40%] text-left">🐳 {intl.get("upper-price")}</span>
											<input
												type="text"
												value={upperPrice}
												placeholder=""
												className="input input-bordered w-[60%]"
												onChange={(e) => setupperPrice(e.target.value)}
											/>
										</div>
									</div>}
							</div>}

						{/* <div className="input-group flex justify-center mb-3">
						<span className="w-[40%] text-left">🐙 {intl.get("slippage")}</span>
						<select 
							className="select select-bordered w-[60%] max-w-xs"
							onChange={(e) => setSlippage(e.currentTarget.value)}
							defaultValue="100" 
						>
							<option value="30">0.3%</option>
							<option value="100">1%</option>
							<option value="300">3%</option>
							<option value="500">5%</option>
							<option value="1000">10%</option>
						</select>
					</div> */}
						{canAddLiquidity &&
							<div className="modal-action">
								<button
									className={`btn ${loading ? 'loading btn-disabled' : 'btn-secondary'}`}
									onClick={() => addLiquidity()}
								>
									{intl.get("add-liquidity")}
								</button>
							</div>}
					</label>
				</label>
			</div>
		</>)
}

export default AddLiquidity;