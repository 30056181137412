import { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WhitelistMain from '../components/WhitelistMain';

function Whitelist() {
	const [version, setVersion] = useState(
		localStorage.getItem("ferc_version") ? localStorage.getItem("ferc_version") as string : "v31"
	);

	return (
		<div>
			<WhitelistMain version={version} />
			<Header updateVersion={(version) => setVersion(version)} />
			<Footer />
		</div>
	);
}

export default Whitelist;
