import { FC } from "react";
import { ConfirmBoxProps } from "../utils/types";
import intl from "../utils/intl";

const ConfirmBox:FC<ConfirmBoxProps> = ({
	title,
	message,
	confirmFun,
	cancelFun,
}) => {
	return(
	<>
		<div>
			<input type="checkbox" id="confirmBox" className="modal-toggle" />
			<label htmlFor="confirmBox" className="modal cursor-pointer">
				<label className="modal-box text-sm md:text-md" htmlFor="">
					<label className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => cancelFun()}>✕</label>
					<h3 className="font-bold text-lg">{title}</h3>
					<div className="divider"/>
					<div className="">{message}</div>
					<div className="modal-action">
						<label 
							className={`btn btn-secondary`}
							onClick={() => cancelFun()}
						>{intl.get("cancel")}</label>
						<label 
							className={`btn btn-primary`}
							onClick={() => confirmFun()}
						>{intl.get("confirm")}</label>
					</div>
				</label>
			</label>
		</div>
	</>)
}

export default ConfirmBox;