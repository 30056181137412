import { FC, useEffect, useState } from "react";
import { NetworkData, WhitelistMainProps } from "../utils/types";
import intl from "../utils/intl";
import InscriptionFactoryABI from '../abi/InscriptionFactory.json';
import WhitelistABI from "../abi/Whitelist.json";
import toast, { Toaster } from "react-hot-toast";
import { useNetwork } from "wagmi";
import { getNetwork } from "../config";
import { readContract } from "@wagmi/core";
import { formatAddress, getContractAddressByVersion, getNetworkData, toNumber } from "../utils/common";
import { useParams } from "react-router-dom";
import LinkTo from "./LinkTo";
import useDeviceDetect from "../utils/useDeviceDetect";

const CheckWhitelistMain: FC<WhitelistMainProps> = ({
	version
}) => {
	const params = useParams();
	const tokenAddress = params.tokenAddress;
	const isMobile = useDeviceDetect();

	const [loading, setLoading] = useState(false);
	const [address, setAddress] = useState(params.user);
	const [message, setMessage] = useState('');

	const [network, setNetwork] = useState({} as NetworkData);
	const { chain } = useNetwork();

	useEffect(() => {
		if (chain) {
			setNetwork(getNetworkData(chain, version));
		}
	}, [chain, version]);

	const checkStatus = async () => {
		if (address === "") {
			toast.error(intl.get("address-not-found"));
			return;
		}

		setLoading(true);
		// Check tick is existed
		const inscriptionId = await readContract({
			address: network.contractAddress as any,
			abi: InscriptionFactoryABI,
			functionName: "getIncriptionIdByAddress",
			args: [tokenAddress?.trim().toLowerCase()]
		});

		if (toNumber(inscriptionId) === 0) {
			toast.error(intl.get("tick-not-exists"));
			setLoading(false);
		}

		const status = await readContract({
			address: network.whitelist as any,
			abi: WhitelistABI,
			functionName: "getStatus",
			args: [tokenAddress?.trim().toLowerCase(), address]
		});

		if (status) setMessage(intl.get("whitelist-checking-ok"));
		else setMessage(intl.get("whitelist-checking-ko"));
		setLoading(false);
	}

	return (
		<div>
			<div className="hero pt-[120px] pb-5">
				<div className="hero-content text-center">
					<div className="max-w-md">
						<h1 className="text-4xl font-bold">{intl.get("whitelist-checking")} 🐸</h1>
						<p className="py-6">{intl.get("whitelist-checking-introduction")}</p>
						<div className="mb-1">
							{/* <p className="mb-1">🐸 {intl.get("whitelist-sub-introduction")} 🐸</p> */}
							<p className="flex justify-center">
								{intl.get("whitelist") + ": " + formatAddress(network.whitelist)}
								<LinkTo
									copyText={network.whitelist}
									url={`${network.scanUrl}/address/${network.whitelist}`}
								/>
							</p>

							<p className="flex justify-center">
							</p>
						</div>
					</div>
				</div>
			</div>

			{/* Set one by one */}
			<div className="bg-base-200 text-center text-sm md:w-[50%] md:ml-[25%] md:p-12 w-[100%] ml-[0%] p-3 rounded-lg">
				{/* Token address */}
				<div className="input-group flex justify-center mb-3">
					<span className="w-[40%] text-left">{intl.get("tick2")}</span>
					<span className="input input-bordered w-[60%] bg-base-100">{isMobile ? formatAddress(tokenAddress) : tokenAddress}</span>
				</div>

				{/* Address */}
				<div className="input-group flex justify-center mb-3">
					<span className="w-[40%] text-left">{intl.get("participant-address")}</span>
					<input
						type="text"
						className="input input-bordered w-[60%] bg-base-100"
						value={isMobile ? formatAddress(address) : address}
						onChange={(e) => setAddress(e.target.value)}
						onClick={(e) => e.currentTarget.select()}
					/>
				</div>
				<div className="text-primary">{message}</div>

				<button className={`btn mt-5 btn-wide ${loading ? 'btn-disabled loading' : 'btn-primary'}`} onClick={() => checkStatus()}>{intl.get("whitelist-checking")}</button>

				<div className="text-primary mt-5	pb-10 md:pb-0">
					<a href={`/whitelist/${tokenAddress}`} target="_blank" rel="noreferrer">
						{intl.get("open-whitelist-management")}
					</a>
				</div>
			</div>

			{/* Toast */}
			<Toaster toastOptions={{
				duration: 4000,
			}} />

		</div>)
}

export default CheckWhitelistMain;