import { FC, useState } from "react";
import intl from "../utils/intl";
import { writeContract, waitForTransaction } from "@wagmi/core";
import InitialFairOfferingABI from '../abi/InitialFairOffering.json';
import toast from "react-hot-toast";
import { CollectPoolFeeProps } from "../utils/types";

const CollectPoolFee: FC<CollectPoolFeeProps> = ({
	canModify,
	tokenId,
	ifoContractAddress,
	reload
}) => {
	const [loading, setLoading] = useState(false);

	const collectFee = async () => {
		setLoading(true);
		try {
			const { hash } = await writeContract({
				address: ifoContractAddress as any,
				abi: InitialFairOfferingABI,
				functionName: 'collectFee',
				args: [tokenId],
			});
	
			waitForTransaction({hash}).then(async (data) => {
				if(data.status === 'success') {
					toast.success("Collect fee successfully");
				} else {
					toast.error("Collect fee Error");
				}
				reload();
				setLoading(false);
			})	
		} catch (err) {
			toast.error("Error: " + (err as any).shortMessage);
			setLoading(false);
		}
	}

	return(
	<>
		<div>
			<input type="checkbox" id="collectFeeModal" className="modal-toggle" />
			<label htmlFor="collectFeeModal" className="modal cursor-pointer">
				<label className="modal-box text-sm md:text-md" htmlFor="">
					<label htmlFor="collectFeeModal" className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</label>
					<h3 className="font-bold text-lg">{intl.get("collect-fee")}</h3>
					<div className="divider"/>

					{`You are going to collect fee of ${tokenId} to FTO contract`}

					<div className="modal-action">
						<button 
								className={`btn ${loading ? 'loading btn-disabled' : 'btn-secondary'}`} 
								onClick={() => collectFee()}
							>
								{intl.get("collect-fee")}
						</button>
					</div>
				</label>
			</label>
		</div>
	</>)
}

export default CollectPoolFee;