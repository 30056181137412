import { useState, useEffect, FC } from "react";
import { CommitteeMainProps } from "../utils/types";
import intl from "../utils/intl";
import { candidatesList } from "../committee_config";
import { BN, addCommas, formatAddress, formatEther } from "../utils/common";
import { IMAGE_PROXY } from "../config";
import LinkTo from "./LinkTo";
import { readContracts } from "@wagmi/core";
import InscriptionABI from '../abi/Inscription.json';
import { useNetwork } from 'wagmi';
import toast, { Toaster } from "react-hot-toast";

const CommitteeMain: FC<CommitteeMainProps> = ({
	version
}) => {
	const { chain } = useNetwork();
	const [memberList, setMemberList] = useState<any[]>([]);

	useEffect(() => {
		if (chain) {
			console.log("chain", chain);
			if (chain.id !== 1) toast.error("Please connect Ethereum Mainnet");
			else getFERCBalance();
		}
	}, []);

	const getFERCBalance = () => {
		let contracts = [];
		for (let i = 0; i < candidatesList.length; i++) {
			contracts.push({
				address: "0x2eCBa91da63C29EA80Fbe7b52632CA2d1F8e5Be0" as any,
				abi: InscriptionABI as any,
				functionName: "balanceOf",
				args: [candidatesList[i].address],
			})
		}
		readContracts({
			contracts
		}).then((_balances: Array<any>) => {
			for (let i = 0; i < _balances.length; i++) {
				candidatesList[i].balance = parseFloat(formatEther(BN(_balances[i].result)));
			}
			candidatesList.sort((a, b) => b.balance - a.balance);
			setMemberList(candidatesList)
		})
	}

	// useEffect(() => {
	// 	if (candidatesList.length > 0) {
	// 		candidatesList.sort((a, b) => b.balance - a.balance);
	// 	}
	// }, [candidatesList])

	return (
		<div className="mb-20">
			<div className="hero pt-[120px] pb-5">
				<div className="hero-content text-center">
					<div className="max-w-md">
						<h1 className="text-4xl font-bold">{intl.get("committee")} 🐸</h1>
						<p className="py-6">{intl.get("committee-introduction")}</p>
					</div>
				</div>
			</div>

			{/* Committee list */}
			{/* Show twitter logo, twitter url, tg url, discord account, address, ferc balance */}
			<div className="overflow-x-auto w-full md:w-[90%] m-auto md:m-auto md:mb-3 bg-base-300 md:rounded-lg px-3 py-5 md:p-5 text-center">
				<table className="table table-zebra w-full">
					<thead>
						<tr>
							<th className="">#</th>
							<th className="">twitter</th>
							<th className="">telegram</th>
							<th className="">discord</th>
							<th className="">address</th>
							<th className="">ferc balance</th>
						</tr>
					</thead>
					<tbody>
						{memberList.map((item, index) =>
							<tr key={index}>
								<td>{index + 1}</td>
								<td>
									<img
										src={IMAGE_PROXY(item.twitterLogo)}
										className={`mask mask-circle w-12`}
										alt={""}
										onError={(e) => { e.currentTarget.src = "/images/default-logo.jpeg" }}
									/>
									<div className="flex text-sm mt-1">
										{item.twitterName}
										<LinkTo copyText={item.twitterName} url={`https://twitter.com/${item.twitterName}`} />
									</div>
								</td>
								<td>{item.tgAccount}</td>
								<td>{item.discordAccount}</td>
								<td>
									<div className="flex">
										{formatAddress(item.address)}
										<LinkTo copyText={item.address} url={`https://etherscan.io/address/${item.address}`} />
									</div>
								</td>
								<td>
									{item.balance === undefined ? "0 ferc" : addCommas(item.balance.toFixed(2)) + " ferc"}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>

			{/* Toast */}
			<Toaster toastOptions={{
				duration: 4000,
			}} />

		</div>
	)
}

export default CommitteeMain;