import { FC, useEffect, useState } from "react";
import { IMAGE_PROXY, IPFS_GATEWAY } from "../config";
import { LogoItemProps } from "../utils/types";

const LogoItem: FC<LogoItemProps> = ({
	cid,
	isVoted,
	isGrid,
}) => {
	const [ipfsImage, setIpfsImage] = useState("");

	useEffect(() => {
		const g = (async (cid: string) => {
			try {
				const res = await window.w3sClient.get(cid); // Web3Response
				const files = await res?.files(); // Web3File[]
				if (files && files.length > 0) {
					const file = files[0];
					setIpfsImage(`https://${cid}${IPFS_GATEWAY}/${file.name}`)
				} else {
					setIpfsImage(`https://${cid}${IPFS_GATEWAY}`)
				}
			} catch (error) {
				setIpfsImage(`https://${cid}${IPFS_GATEWAY}`)
			}
		})
		if (cid) {
			g(cid)
		}

	}, [cid])


	return (
		<div>
		{isVoted && 
			<div className={`absolute float-right -mt-3 -ml-2 ${isGrid ? 'w-6 h-6' : 'w-4 h-4'}`}>
				<img src="/images/verified.png" alt=""/>
			</div>
		}
		<img
			src={IMAGE_PROXY(ipfsImage)}
			className={`mask mask-circle`}
			alt={""}
			onError={(e) => { e.currentTarget.src = "/images/default-logo.jpeg" }}
		/>
		</div>
	)
}

export default LogoItem;