import { FC, useState } from "react";
import { ConversationPosData, TokenListProps } from "../utils/types";
import { ZERO_ADDRESS } from "../config";
import SocialBox from "./SocialBox";
import TokenGridItem from "./TokenGridItem";
import { BN } from "../utils/common";
import { fetchToken } from '@wagmi/core'


const TokenGrid: FC<TokenListProps> = ({
	list,
	showType,
	scanUrl,
	symbol,
	version,
	conversationData,
	factory,
	socialContractAddress,
	currentAddress,
	rate,
	setMintItem,
	setShowIFODetails,
	checkTokenAddress,
}) => {
	const [conversation, setConversation] = useState({} as ConversationPosData);
	const [deployer, setDeployer] = useState("");

	return (
		<>
			<div className="grid md:grid-cols-4 ">
				{list && list.map((item, key) => {
					if (showType === 1 && BN(item.totalRollups).eq(item.maxRollups)) return null;
					else if (showType === 2 && !BN(item.totalRollups).eq(item.maxRollups)) return null;
					else if (item.addr === ZERO_ADDRESS) return null;
					else {
						return (
							<TokenGridItem
								key={key}
								item={item}
								version={version}
								scanUrl={scanUrl}
								currentAddress={currentAddress}
								conversationData={conversationData}
								symbol={symbol}
								setMintItem={setMintItem}
								rate={rate}
								setShowIFODetails={setShowIFODetails}
								checkTokenAddress={checkTokenAddress}
								setConversation={setConversation}
								setDeployer={setDeployer}
							/>
						)
					}
				}
				)}
			</div>
			<SocialBox
				factory={factory}
				currentAddress={currentAddress}
				socialContractAddress={socialContractAddress}
				conversation={conversation}
				deployer={deployer}
			/>
		</>)
}

export default TokenGrid;