import intl from 'react-intl-universal';
import en_US from "../locales/en_US.json";
import zh_CN from "../locales/zh_CN.json";
import th_TH from "../locales/th_TH.json";
import ja_JP from "../locales/ja_JP.json";
import es_ES from "../locales/es_ES.json";
import fr_FR from "../locales/fr_FR.json";
import ko_KR from "../locales/ko_KR.json";
import vi_VI from "../locales/vi_VI.json";
import ru_RU from "../locales/ru_RU.json";
import de_DE from "../locales/de_DE.json";

const locales: any = {
  en: en_US,
  zh: zh_CN,
  th: th_TH,
  ja: ja_JP,
  es: es_ES,
  fr: fr_FR,
  ko: ko_KR,
  vi: vi_VI,
  ru: ru_RU,
  de: de_DE,
};
// let lang = navigator.userAgent.indexOf("lang=[zh]") > -1 ? "zh" : navigator.userAgent.indexOf("lang=[en]") > -1 ? "en" :
//   (navigator.languages && navigator.languages[0]) || navigator.language;

// if (!locales[lang]) {
// 	// Default language
//   lang = "en";
// }

const lang = localStorage.getItem("ferc_lang") ? localStorage.getItem("ferc_lang") : "en";

intl.init({
  currentLocale: lang as string, //lang.split("-")[0],
  locales: locales,
});

export default intl;
