import { FC, useEffect, useRef, useState } from "react";
import { ApplicationData, Ballot, IPFSDescriptionJSON, LaunchPadMainProps, LaunchpadData, NetworkData } from "../utils/types";
import intl from "../utils/intl";
import toast, { Toaster } from "react-hot-toast";
import { useNetwork, useAccount } from "wagmi";
import VoteForLaunchABI from "../abi/VoteForLaunch.json";
import InscriptionABI from "../abi/Inscription.json";
import { IMAGE_PROXY, IPFS_GATEWAY, PASSED_RATIO, TEST_TICKS, ZERO_ADDRESS } from "../config";
import { readContract, writeContract, waitForTransaction } from "@wagmi/core";
import { BN, formatAddress, formatDate, formatDecimal2, formatEther, getNetworkData, getWeb3Provider, parseEther } from "../utils/common";
import { useParams } from "react-router-dom";
import { BigNumber, ethers } from "ethers";
import NewLaunchpad from "./NewLaunchpad";
import LinkTo from "./LinkTo";
import LaunchpadTable from "./LaunchpadTable";
import Rate from "./Rate";
import ConfirmBox from "./ConfirmBox";
import { confirmAlert } from 'react-confirm-alert';
import useDeviceDetect from '../utils/useDeviceDetect';

const LaunchPadMain: FC<LaunchPadMainProps> = ({
	version
}) => {
	const params = useParams();
	const tick = params.tick;
	const isMobile = useDeviceDetect();

	const [loading, setLoading] = useState(false);
	const [loadingCancel, setLoadingCancel] = useState(false);
	const [loadingRemove, setLoadingRemove] = useState(false);

	const [network, setNetwork] = useState({} as NetworkData);
	const [application, setApplication] = useState({} as ApplicationData);
	const [isVote, setIsVote] = useState(true);
	const [myDeposit, setMyDeposit] = useState(BN("0"));
	const [myBallots, setMyBallots] = useState(BN("0"));
	const [myVoted, setMyVoted] = useState(BN("0"));
	const [myFercBalance, setMyFercBalance] = useState(BN("0"));
	const [totalBallots, setTotalBallots] = useState(BN("0"));
	const [totalVoters, setTotalVoters] = useState(BN("0"));
	const [currentVotes, setCurrentVotes] = useState(Array<Ballot>);
	const [isExpireForVote, setIsExpireForVote] = useState(false);
	const [isExpireForDeploy, setIsExpireForDeploy] = useState(false);
	const [modalTitle, setModalTitle] = useState("");
	const [modalInputTitle, setModalInputTitle] = useState("");
	const [modalValue, setModalValue] = useState("0");
	const [modalAction, setModalAction] = useState("");
	const [launchpadList, setLaunchpadList] = useState(Array<LaunchpadData>);
	const [totalVoted, setTotalVoted] = useState(BN("0"));
	const [totalDeposit, setTotalDeposit] = useState(BN("0"));
	const [cancelGas, setCancelGas] = useState(BN("0"));

	// const [minVote, setMinVote] = useState("0");
	const [popupWindowMessage, setPopupWindowMessage] = useState("");
	const [voteRatio, setVoteRatio] = useState(0);

	const { chain } = useNetwork();
	const { address: currentAddress } = useAccount();

	const contract = useRef({} as ethers.Contract);

	useEffect(() => {
		if (chain && version) {
			const _network = getNetworkData(chain, version);
			setNetwork(_network);
			contract.current = new ethers.Contract(_network.voteForLaunchpad, VoteForLaunchABI, getWeb3Provider().getSigner());
			if (tick !== undefined) loadApplicationDetails(_network);
			else loadLaunchpadList(_network);
		}
	}, [chain, version]);

	useEffect(() => {
		setVoteRatio(totalBallots === undefined || totalBallots === undefined || totalBallots.isZero() || application.topVotes === undefined ? 0 : BN(application.topVotes).mul(10000).div(totalBallots).toNumber() / 100);
	}, [application.topVotes, totalBallots]);

	const loadLaunchpadList = (_network: NetworkData) => {
		setLaunchpadList([]);
		readContract({
			address: _network.voteForLaunchpad as any,
			abi: VoteForLaunchABI,
			functionName: "getVotedApplications",
			args: [currentAddress]
		}).then((votedData: any) => {
			votedData = votedData as Array<any>;
			// console.log('voted data', votedData);
			// parse vote data
			if (votedData[0].length === 0) {
				return;
			}

			let listData = new Array<LaunchpadData>();
			for (let i = 0; i < votedData[0].length; i++) {
				if (TEST_TICKS.includes(votedData[7][i])) continue;
				if (BN(votedData[0][i].expireAt).isZero() || votedData[0][i].applicant === ZERO_ADDRESS) continue;
				listData.push({
					application: votedData[0][i] as ApplicationData,
					myVotes: votedData[1][i],
					isExpireForVote: votedData[2][i],
					isExpireForDeploy: votedData[3][i],
					canDeploy: votedData[4][i],
					code: votedData[5][i],
					description: votedData[6][i],
					tick: votedData[7][i],
				})
			}
			setLaunchpadList(listData);
		});

		readContract({
			address: _network.fercToken as any,
			abi: InscriptionABI,
			functionName: "balanceOf",
			args: [currentAddress]
		}).then((_myFercBalance) => setMyFercBalance(BN(_myFercBalance)));

		readContract({
			address: _network.voteForLaunchpad as any,
			abi: VoteForLaunchABI,
			functionName: "deposits",
			args: [currentAddress],
		}).then((_myDeposit) => setMyDeposit(BN(_myDeposit)));

		readContract({
			address: _network.voteForLaunchpad as any,
			abi: VoteForLaunchABI,
			functionName: "ballots",
			args: [currentAddress]
		}).then((_myBallots) => setMyBallots(BN(_myBallots)));

		readContract({
			address: _network.voteForLaunchpad as any,
			abi: VoteForLaunchABI,
			functionName: "totalBallots",
			args: []
		}).then((_totalBallots) => setTotalBallots(BN(_totalBallots)));

		readContract({
			address: _network.voteForLaunchpad as any,
			abi: VoteForLaunchABI,
			functionName: "totalVoted",
			args: []
		}).then((_totalVoted) => setTotalVoted(BN(_totalVoted)));

		readContract({
			address: _network.voteForLaunchpad as any,
			abi: VoteForLaunchABI,
			functionName: "totalDeposit",
			args: [],
		}).then((_totalDeposit) => setTotalDeposit(BN(_totalDeposit)));
	}

	const loadApplicationDetails = (_network: NetworkData) => {
		try {
			readContract({
				address: _network.voteForLaunchpad as any,
				abi: VoteForLaunchABI,
				functionName: "getApplication",
				args: [tick?.trim().toLowerCase()]
			}).then((application: any) => {
				// console.log("application", application);
				if ((application as ApplicationData).applicant === ZERO_ADDRESS || BN(application.expireAt).isZero()) {
					setIsVote(false);
					return;
				}
				// console.log("passed", application.passed);
				setApplication(application as ApplicationData);

				fetchCid((application as ApplicationData).cid).then((desc: string) => {
					setApplication({
						...(application as ApplicationData),
						description: desc
					})
				})
			})

			readContract({
				address: _network.voteForLaunchpad as any,
				abi: VoteForLaunchABI,
				functionName: "ballots",
				args: [currentAddress]
			}).then((_myBallots) => setMyBallots(BN(_myBallots)));

			readContract({
				address: _network.voteForLaunchpad as any,
				abi: VoteForLaunchABI,
				functionName: "totalBallots",
				args: []
			}).then((_totalBallots) => setTotalBallots(BN(_totalBallots)));

			readContract({
				address: _network.voteForLaunchpad as any,
				abi: VoteForLaunchABI,
				functionName: "totalVoters",
				args: [tick]
			}).then((_totalVoters) => {
				setTotalVoters(BN(_totalVoters))
				if (!BN(_totalVoters).isZero()) {
					readContract({
						address: _network.voteForLaunchpad as any,
						abi: VoteForLaunchABI,
						functionName: "getVotesByAddress",
						args: [tick, currentAddress]
					}).then((_myVoted) => setMyVoted(BN(_myVoted)));

					readContract({
						address: _network.voteForLaunchpad as any,
						abi: VoteForLaunchABI,
						functionName: "getCurrentVotes",
						args: [tick],
					}).then((_currentVotes: any) => setCurrentVotes(_currentVotes as Array<Ballot>));
				}
			});

			readContract({
				address: _network.voteForLaunchpad as any,
				abi: VoteForLaunchABI,
				functionName: "isExpireForVote",
				args: [tick],
			}).then((_isExpireForVote) => {
				setIsExpireForVote(_isExpireForVote as boolean);
				if (_isExpireForVote) contract.current.estimateGas.cancelFailedApplication(tick?.trim().toLowerCase()).then((_cancelGas) => {
					setCancelGas(_cancelGas);
				});
			});

			readContract({
				address: _network.voteForLaunchpad as any,
				abi: VoteForLaunchABI,
				functionName: "isExpireForDeploy",
				args: [tick],
			}).then((_isExpireForDeploy) => {
				setIsExpireForDeploy(_isExpireForDeploy as boolean);
				if (_isExpireForDeploy) contract.current.estimateGas.cancelFailedApplication(tick?.trim().toLowerCase()).then((_cancelGas) => {
					setCancelGas(_cancelGas);
				});
			});


			// get block timestamp			

		} catch (err) {
			toast.error("Error: " + (err as any).shortMessage);
		}
	}

	const fetchCid = async (cid: string) => {
		try {
			const url = `https://${cid}${IPFS_GATEWAY}/description.json`;
			// console.log("url", url);
			const response = await fetch(url);
			const data: IPFSDescriptionJSON = await response.json();
			return data.description;
		} catch (err) {
			return "Error to load from IPFS"
		}
	}

	const deposit = async () => {
		// console.log("Deposit")
		const amount = parseEther(modalValue);
		setLoading(true);
		// check allowance
		const allowance = await readContract({
			address: network.fercToken as any,
			abi: InscriptionABI,
			functionName: "allowance",
			args: [currentAddress, network.voteForLaunchpad]
		});

		if (BN(allowance).lt(amount)) {
			// approve
			try {
				const { hash } = await writeContract({
					address: network.fercToken as any,
					abi: InscriptionABI,
					functionName: "approve",
					args: [network.voteForLaunchpad, amount],
				})
				waitForTransaction({ hash }).then(async (data) => {
					if (data.status === 'success') {
						toast.success("Approve successfully");
						await doDeposit(amount);
					} else {
						toast.error("Approve Error");
						setLoading(false);
					}
				})
			} catch (err) {
				toast.error("Error: " + (err as any).shortMessage);
				setLoading(false);
			}
		} else {
			await doDeposit(amount);
		}
	}

	const doDeposit = async (amount: BigNumber) => {
		try {
			const { hash } = await writeContract({
				address: network.voteForLaunchpad as any,
				abi: VoteForLaunchABI,
				functionName: "deposit",
				args: [amount]
			});
			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success("Deposit successfully");
					setMyDeposit(myDeposit.add(amount));
					setMyBallots(myBallots.add(amount));
					setMyFercBalance(myFercBalance.sub(amount));
				} else {
					toast.error("Deposit Error");
				}
				setLoading(false);
			})
		} catch (err) {
			toast.error("Error: " + (err as any).shortMessage);
			setLoading(false);
		}
	}

	const withdraw = async () => {
		const amount = parseEther(modalValue);
		setLoading(true);
		try {
			const { hash } = await writeContract({
				address: network.voteForLaunchpad as any,
				abi: VoteForLaunchABI,
				functionName: "withdraw",
				args: [amount]
			})
			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success("Withdraw successfully");
					setMyDeposit(myDeposit.sub(amount));
					setMyBallots(myBallots.sub(amount));
					setMyFercBalance(myFercBalance.add(amount));
				} else {
					toast.error("Withdraw Error");
				}
				setLoading(false);
			})
		} catch (err) {
			toast.error("Error: " + (err as any).shortMessage);
			setLoading(false);
		}
	}

	const vote = async () => {
		const amount = parseEther(modalValue);
		if (myBallots.isZero()) {
			toast.error(intl.get("launchpad-no-ballots"));
			return;
		}
		setLoading(true);

		try {

			const { hash } = await writeContract({
				address: network.voteForLaunchpad as any,
				abi: VoteForLaunchABI,
				functionName: "addVote",
				args: [tick?.trim().toLowerCase(), amount]
			});
			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success("Vote successfully");
					setMyBallots(myBallots.sub(amount));
					setMyVoted(myVoted.add(amount));
					setApplication({
						...application,
						totalVotes: BN(application.totalVotes).add(amount),
						topVotes: BN(application.totalVotes).add(amount)
					})
				} else {
					toast.error("Vote Error");
				}
				setLoading(false);
			})
		} catch (err) {
			toast.error("Error: " + (err as any).shortMessage);
			setLoading(false);
		}
	}

	const cancelVote = async () => {
		// console.log("Cancel vote")
		setLoadingCancel(true);
		try {
			const { hash } = await writeContract({
				address: network.voteForLaunchpad as any,
				abi: VoteForLaunchABI,
				functionName: "cancelVote",
				args: [tick?.trim().toLowerCase()]
			});
			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success("Cancel vote successfully");
					setMyBallots(myBallots.add(myVoted));
					setMyVoted(BN(0));
					setApplication({
						...application,
						totalVotes: BN(application.totalVotes).sub(myVoted)
					})
				} else {
					toast.error("Cancel vote Error");
				}
				setLoadingCancel(false);
			})
		} catch (err) {
			toast.error("Error: " + (err as any).shortMessage);
			setLoadingCancel(false);
		}
	}

	const removeFailedApplication = async () => {
		setLoadingRemove(true);
		try {
			const { hash } = await writeContract({
				address: network.voteForLaunchpad as any,
				abi: VoteForLaunchABI,
				functionName: "cancelFailedApplication",
				args: [tick?.trim().toLowerCase()]
			});
			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success("Remove failed application successfully");
				} else {
					toast.error("Remove failed application Error");
				}
				setLoadingRemove(false);
			});
		} catch (err) {
			toast.error("Error: " + (err as any).shortMessage);
			setLoadingRemove(false)
		}
	}

	const removeUndeployedApplication = async () => {
		try {
			setLoadingRemove(true);
			const { hash } = await writeContract({
				address: network.voteForLaunchpad as any,
				abi: VoteForLaunchABI,
				functionName: "cancelUndeployedApplication",
				args: [tick?.trim().toLowerCase()]
			});
			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success("Remove undeploy but passed application successfully");
				} else {
					toast.error("Remove undeploy but passed application error");
				}
				setLoadingRemove(false);
			});
		} catch (err) {
			toast.error("Error: " + (err as any).shortMessage);
			setLoadingRemove(false)
		}
	}

	return (
		<div className={`${tick === undefined ? "bg-base-200 h-full" : ""}`}>
			<div className="hero pt-[120px] pb-5">
				<div className="hero-content text-center">
					<div className="max-w-md">
						<h1 className="text-4xl font-bold">{intl.get("launchpad")} 🐸</h1>
						<p className="py-6">{intl.get("launchpad-introduction")}</p>
						<div className="mb-1">
							<p className="flex justify-center mb-3">
								{intl.get("launchpad-contract") + formatAddress(network.voteForLaunchpad)}
								<LinkTo
									copyText={network.voteForLaunchpad}
									url={`${network.scanUrl}/address/${network.voteForLaunchpad}`}
								/>
							</p>
							<p className="flex justify-center">
							</p>
						</div>
						{tick === undefined &&
							<label
								htmlFor="newLaunchpad"
								className="btn btn-primary w-[150px] mt-5"
							>
								{intl.get("new-launchpad")}
							</label>}
					</div>
				</div>
			</div>

			{/* Show applications list */}
			{tick === undefined &&
				<div className="">
					<div className="bg-base-200 text-center md:w-[60%] md:ml-[20%] w-[100%] ml-[0%] md:p-12 p-3 text-sm md:text-md rounded-lg">

						<div className="stats shadow mb-8">

							<div className="stat w-[120px] md:w-[200px] px-1 md:px-6">
								<div className="stat-title">{intl.get("launchpad-total-deposit")}</div>
								<div className="stat-value font-light my-3 text-2xl md:text-4xl">{totalDeposit && formatDecimal2(parseInt(formatEther(totalDeposit)), 3)}</div>
								<div className="stat-desc">{intl.get("launchpad-total-voted") + ":" + (totalVoted && formatDecimal2(parseInt(formatEther(totalVoted)), 3))}</div>
							</div>
							<div className="stat w-[120px] md:w-[200px] px-1 md:px-6">
								<div className="stat-title">{intl.get("launchpad-my-ballots")}</div>
								<div className="stat-value font-light my-3 text-2xl md:text-4xl">{myBallots && formatDecimal2(parseInt(formatEther(myBallots)), 3)}</div>
								<div className="stat-desc">{intl.get("launchpad-my-votes") + ":" + formatDecimal2((parseInt(formatEther(myDeposit)) - parseInt(formatEther(myBallots))), 3)}</div>
							</div>

							<div className="stat w-[120px] md:w-[200px] px-1 md:px-6">
								<div className="stat-title">{intl.get("launchpad-my-deposit")}</div>
								<div className="stat-value font-light my-3 text-2xl md:text-4xl">{myDeposit && formatDecimal2(parseInt(formatEther(myDeposit)), 3)}</div>
								<div className="stat-desc">{intl.get("launchpad-ferc-balance") + ":" + formatDecimal2(parseFloat(formatEther(myFercBalance)), 3)}</div>
							</div>

						</div>
						<div className="input-group flex justify-center">
							<label
								htmlFor="launchpadModal"
								className="btn btn-primary w-[35%]"
								onClick={() => {
									setModalTitle(intl.get("launchpad-deposit"));
									setModalInputTitle(intl.get("launchpad-deposit-amount"));
									setModalValue("0");
									setModalAction("deposit");
									setPopupWindowMessage(intl.get("launchpad-introduction-deposit"))
								}}
							>
								{intl.get("launchpad-deposit")}
							</label>
							<label
								htmlFor="launchpadModal"
								className="btn btn-secondary w-[35%]"
								onClick={() => {
									setModalTitle(intl.get("launchpad-withdraw"));
									setModalInputTitle(intl.get("launchpad-withdraw-amount"));
									setModalValue("0");
									setModalAction("withdraw");
									setPopupWindowMessage(intl.get("launchpad-introduction-withdraw"))
								}}
							>
								{intl.get("launchpad-withdraw")}
							</label>
						</div>




						{/* My FERC balance */}
						{/* <div className="input-group flex justify-center mb-3">
							<span className="w-[35%] text-left">🐯 {intl.get("launchpad-ferc-balance")}</span>
							<span className="input input-bordered w-[65%] bg-base-100">
								{myFercBalance && formatDecimal2(parseFloat(formatEther(myFercBalance)), 3)}
							</span>
						</div> */}

						{/* Deposit */}
						{/* <div className="input-group flex justify-center mb-3">
							<span className="w-[35%] text-left">🐸 {intl.get("launchpad-my-deposit")}</span>
							<span className="input input-bordered w-[35%] bg-base-100" >
								{myDeposit && parseInt(formatEther(myDeposit))}
							</span>
							<label
								htmlFor="launchpadModal"
								className="btn btn-primary w-[15%]"
								onClick={() => {
									setModalTitle(intl.get("launchpad-deposit"));
									setModalInputTitle(intl.get("launchpad-deposit-amount"));
									setModalValue("0");
									setModalAction("deposit");
									setPopupWindowMessage(intl.get("launchpad-introduction-deposit"))
								}}
							>
								{intl.get("launchpad-deposit")}
							</label>
							<label
								htmlFor="launchpadModal"
								className="btn btn-secondary w-[15%]"
								onClick={() => {
									setModalTitle(intl.get("launchpad-withdraw"));
									setModalInputTitle(intl.get("launchpad-withdraw-amount"));
									setModalValue("0");
									setModalAction("withdraw");
									setPopupWindowMessage(intl.get("launchpad-introduction-withdraw"))
								}}
							>
								{intl.get("launchpad-withdraw")}
							</label>
						</div> */}

						{/* Ballots */}
						{/* <div className="input-group flex justify-center mb-3">
							<span className="w-[35%] text-left">🐤 {intl.get("launchpad-my-ballots")}</span>
							<span className="input input-bordered w-[65%] bg-base-100" >
								{myBallots && parseInt(formatEther(myBallots))}
							</span>
						</div> */}



						{/* Total Deposit */}
						{/* <div className="input-group flex justify-center mb-3">
							<span className="w-[35%] text-left">🍩 {intl.get("launchpad-total-deposit")}</span>
							<span className="input input-bordered w-[65%] bg-base-100" >
								{totalDeposit && parseInt(formatEther(totalDeposit))}
							</span>
						</div> */}

						{/* Total Voted */}
						{/* <div className="input-group flex justify-center mb-3">
							<span className="w-[35%] text-left">🍯 {intl.get("launchpad-total-voted")}</span>
							<span className="input input-bordered w-[65%] bg-base-100" >
								{totalVoted && parseInt(formatEther(totalVoted))}
							</span>
						</div> */}

					</div>

					<div className="overflow-x-auto w-full md:w-[90%] m-auto md:m-auto md:mb-3 bg-base-300 md:rounded-lg px-3 py-5 md:p-5 text-center">
						<LaunchpadTable
							list={launchpadList}
							scanUrl={network.scanUrl}
							voteForLaunchpadAddress={network.voteForLaunchpad}
							totalBallots={totalBallots}
						/>
					</div>
				</div>}

			{/* Show application details */}
			{tick !== undefined ?
				!isVote ?
					<div className="text-center border-gray-400 text-white text-2xl font-bold bg-warning md:w-[50%] md:ml-[25%] w-[94%] ml-[3%] p-8 rounded-lg ">
						{intl.get("launchpad-no-vote", { tick })}
					</div>
					:
					application.applicant !== ZERO_ADDRESS &&
					<div className="bg-base-200 text-center md:w-[60%] md:ml-[20%] w-[100%] ml-[0%] md:p-12 p-3 text-sm md:text-md rounded-lg">
						{/* Tick name */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[35%] text-left">🐶 {intl.get("launchpad-tick")}</span>
							<span className="input input-bordered w-[65%] bg-base-100">
								{tick}
							</span>
						</div>

						{/* Applicant */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[35%] text-left">🦊 {intl.get("launchpad-applicant")}</span>
							<span className="input input-bordered w-[65%] bg-base-100">
								{isMobile ? formatAddress(application.applicant) : application.applicant}
							</span>
						</div>

						{/* Expire At */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[35%] text-left">🐙 {intl.get("launchpad-expire-at")}</span>
							<span className="input input-bordered w-[65%] bg-base-100">
								{application.expireAt && formatDate(BN(application.expireAt).toNumber() * 1000)}
							</span>
						</div>

						{/* Description */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[35%] text-left">🐹 {intl.get("launchpad-description")}</span>
							<textarea
								value={application.description}
								className="input input-bordered w-[65%] py-2 px-4 h-[150px]"
								readOnly
							/>
						</div>

						{/* Current votes */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[35%] text-left">🐻 {intl.get("launchpad-total-votes")}</span>
							<span className="input input-bordered w-[65%] bg-base-100">
								{application.totalVotes && parseInt(formatEther(application.totalVotes))}
							</span>
						</div>

						{/* Current voters */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[35%] text-left">🦁 {intl.get("launchpad-voters")}</span>
							<span className="input input-bordered w-[65%] bg-base-100">
								{totalVoters && BN(totalVoters).toString()}
							</span>
						</div>


						{/* total ballots */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[35%] text-left">🐯 {intl.get("launchpad-total-ballots") + "/" + intl.get("launchpad-min-ballots")}</span>
							<span className="input input-bordered w-[65%] bg-base-100">
								{totalBallots &&
									formatDecimal2(parseFloat(formatEther(totalBallots)), 3) + "/" +
									formatDecimal2(parseFloat(formatEther(totalBallots)) * PASSED_RATIO, 3)}
							</span>
						</div>

						{/* Ratio & stars*/}
						{!totalBallots.isZero() &&
							<div>
								<div className="input-group flex justify-center mb-3">
									<span className="w-[35%] text-left">🐷 {intl.get("launchpad-ratio")}</span>
									<span className="input input-bordered w-[65%] bg-base-100">
										{voteRatio.toString() + "%"}
									</span>
								</div>
								<div className="input-group flex justify-center mb-3">
									<span className="w-[35%] text-left">🦑 {intl.get("launchpad-stars")}</span>
									<div className="w-[65%] p-2 text-left">
										<Rate totalBallots={totalBallots} topVotes={application.topVotes} />
									</div>
								</div>
							</div>
						}

						{/* Ballots */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[35%] text-left">🐤 {intl.get("launchpad-my-ballots")}</span>
							<span className="input input-bordered w-[35%] bg-base-100" >
								{myBallots && parseInt(formatEther(myBallots))}
							</span>
							<label
								htmlFor="launchpadModal"
								className={`btn btn-primary w-[30%] ${isExpireForVote || myBallots.isZero() || !myVoted.isZero() ? "btn-disabled" : ""}`}
								onClick={() => {
									setModalTitle(intl.get("launchpad-vote"));
									setModalInputTitle(intl.get("launchpad-vote-amount"));
									setModalValue("0"); // minVote = application.totalVotes * myBallots / totalBallots
									setModalAction("vote");
									setPopupWindowMessage(intl.get("min-vote"));
								}}
							>
								{intl.get("launchpad-vote")}
							</label>
						</div>

						{/* My Votes */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[35%] text-left">🦀 {intl.get("launchpad-my-votes")}</span>
							<span className="input input-bordered w-[35%] bg-base-100"
							>{myVoted && parseInt(formatEther(myVoted))}</span>
							<div
								className={`btn btn-secondary w-[30%] ${loadingCancel ? "loading" : ""} ${myVoted.isZero() ? "btn-disabled" : ""}`}
								onClick={() => {
									cancelVote();
								}}
							>
								{intl.get("launchpad-cancel-vote")}
							</div>
						</div>

						{/* If not passed and expire for voting, show cancel button */}
						{isExpireForVote && !application.passed &&
							<>
								<label
									htmlFor="confirmBox"
									className={`btn btn-base-100 btn-outline w-full ${loadingRemove ? "loading btn-disabled" : ""}`}
									onClick={async () => {
										if (totalVoters.isZero()) removeFailedApplication();
										else {
											confirmAlert({
												customUI: ({ onClose }) => {
													return (
														<ConfirmBox
															title="Confirm"
															message={intl.get("launchpad-confirm-remove", { voters: BN(totalVoters).toString(), gas: BN(cancelGas).toString() })}
															confirmFun={removeFailedApplication}
															cancelFun={onClose}
														/>
													)
												}
											})
										}
									}}
								>{intl.get("launchpad-cancel-failed")}</label>
							</>}

						{/* If passed and expire for deploying, show cancel button */}
						{isExpireForDeploy && application.passed && !application.deployed &&
							<>
								<div
									className={`btn btn-base-100 btn-outline w-full ${loadingRemove ? "loading btn-disabled" : ""}`}
									onClick={async () => {
										if (totalVoters.isZero()) removeUndeployedApplication();
										else {
											confirmAlert({
												customUI: ({ onClose }) => {
													return (
														<ConfirmBox
															title="Confirm"
															message={intl.get("launchpad-confirm-remove", { voters: BN(totalVoters).toString(), gas: BN(cancelGas).toString() })}
															confirmFun={removeUndeployedApplication}
															cancelFun={onClose}
														/>
													)
												}
											})
										}
									}}
								>{intl.get("launchpad-cancel-expire-deploy")}</div>
							</>}

						{/* Passed stamp */}
						{application.passed &&
							<div className="absolute top-[300px]">
								<img src="/images/passed.png" className="w-[60%]" />
							</div>}

						{/* Current votes table */}
						{currentVotes.sort((a, b) => {
							const sub = BN(b.amount).sub(BN(a.amount));
							return parseFloat(formatEther(sub));
						}).length > 0 &&
							<div className="mb-2 mt-8">
								<div className="font-bold">{intl.get("current-vote-list")}</div>
								<table className="table table-zebra w-full">
									<thead>
										<tr>
											<th>{intl.get("address")}</th>
											<th>{intl.get("launchpad-vote")}</th>
										</tr>
									</thead>
									<tbody>
										{currentVotes.map((value: Ballot, idx) =>
											<tr key={idx}>
												<td className={`text-sm flex ${value.addr === currentAddress ? 'text-primary' : ''}`}>{isMobile ? formatAddress(value.addr) : value.addr}
													<LinkTo
														copyText={value.addr}
														url={network.scanUrl + "/address/" + value.addr}
													/>
												</td>
												<td className={`text-sm ${value.addr === currentAddress ? 'text-primary font-bold' : ''}`}>{formatEther(value.amount)}</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						}

					</div>
				: <></>}

			<div className="h-24" />

			<NewLaunchpad
				version={version}
				network={network}
			/>

			{/* Toast */}
			<Toaster toastOptions={{
				duration: 4000,
			}} />

			{/* Popup window */}
			<div>
				<input type="checkbox" id="launchpadModal" className="modal-toggle" />
				<label htmlFor="launchpadModal" className="modal cursor-pointer">
					<label className="modal-box text-sm md:text-md" htmlFor="">
						<label htmlFor="launchpadModal" className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</label>
						<h3 className="font-bold text-lg">{modalTitle}</h3>
						<div className="divider" />
						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">🌞&nbsp;{modalInputTitle}</span>
							<input
								type="text"
								value={modalValue}
								className="input input-bordered w-[60%]"
								onChange={(e) => setModalValue(e.target.value)}
							/>
						</div>
						{((modalAction === "vote") || (modalAction === "deposit") || (modalAction === "withdraw"))
							&& <div className="text-sm ml-2">{popupWindowMessage}</div>}
						<div className="modal-action">
							<label
								className={`btn btn-secondary ${loading ? 'loading' : ''}`}
								onClick={() => {
									if (modalValue === "" || modalValue === "0") {
										toast.error("Value is empty");
										return;
									}
									if (modalAction === "deposit") {
										deposit();
									} else if (modalAction === "withdraw") {
										withdraw();
									} else if (modalAction === "vote") {
										vote();
									}
								}}
							>{intl.get("confirm")}
							</label>
						</div>
					</label>
				</label>
			</div>
		</div>
	)
}

export default LaunchPadMain;
