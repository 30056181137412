import { FC, useEffect, useRef, useState } from "react";
import { writeContract, readContract, waitForTransaction } from "@wagmi/core";
import UniswapV3FactoryABI from '../abi/UniswapV3Factory.json';
import InscriptionABI from '../abi/Inscription.json';
import InitialFairOfferingABI from '../abi/InitialFairOffering.json';
import UniswapV3PoolABI from '../abi/UniswapV3Pool.json';
import VestingABI from "../abi/Vesting.json";
import toast from "react-hot-toast";
import { IFODetailsProps, InscriptionData, MintData, Position, PositionAmount, UniswapV3Price } from "../utils/types";
import { BN, compareAddress, formatAddress, formatDate, formatDecimal2, formatEther, getTokenAmounts, parseEther, secondsToHMS, tickToPrice, toNumber } from "../utils/common";
import { BigNumber } from "ethers";
import { BASE_URL, FORMAT_DECIMAL2, ZERO_ADDRESS } from "../config";
import { AiOutlineDown, AiTwotoneAlert } from "react-icons/ai";
import Tooltip from "./Tooltip";
import intl from "../utils/intl";
import AddLiquidity from "./AddLiquidity";
import RemoveLiquidity from "./RemoveLiquidity";
import LinkTo from "./LinkTo";
import useDeviceDetect from "../utils/useDeviceDetect";
import CollectPoolFee from "./CollectPoolFee";

const IFODetails: FC<IFODetailsProps> = ({
	mintItem,
	currentAddress,
	symbol,
	wethAddress,
	scanUrl,
	ifShow,
	setShowIFODetails,
	network
}) => {
	const supportRemoveLiquidity = false;
	const isMobile = useDeviceDetect();

	const [refundLoading, setRefundLoading] = useState(false);
	const [applyLoading, setApplyLoading] = useState(false);
	const [poolAddress, setPoolAddress] = useState("");
	const [ifoTokenBalance, setIfoTokenBalance] = useState(BN("0"));
	const [ifoEtherBalance, setIfoEtherBalance] = useState(BN("0"));
	const [canAddLiquidity, setCanAddLiquidity] = useState(false);
	const [canModify, setCanModify] = useState(true);
	const [canRefund, setCanRefund] = useState(true);
	const [liquidities, setLiquidities] = useState(new Array<Position>());
	const [mintData, setMintData] = useState({} as MintData);
	const [progressPercent, setProgressPercent] = useState(0);
	const [showProgress, setShowProgress] = useState(false);
	const [currentTokenId, setCurrentTokenId] = useState(0);
	const [launchTime, setLaunchTime] = useState(-1);

	const currentPrice = useRef({} as UniswapV3Price);
	const poolPrice = useRef("0");
	const poolToken = useRef(["", ""]);

	useEffect(() => {
		if (ifShow && mintItem.addr && mintItem.wethAddress && mintItem.ifoContractAddress) {
			setLiquidities(new Array<Position>());
			getPoolAddress().then(() =>
				getIfoBalance().then(() =>
					// checkIfModifyLiquidity().then(() => 
					getLiquiditis().then(() =>
						getUserMintData())))
			getLaunchTime()
		}
	}, [mintItem]);

	const getPoolAddress = async () => {
		const pair = [mintItem.addr, mintItem.wethAddress, 3000];
		const pool = await readContract({
			address: mintItem.uniswapV3Factory as any,
			abi: UniswapV3FactoryABI,
			functionName: 'getPool',
			args: pair,
		}) as string;
		setShowProgress(true);
		setProgressPercent(10);
		setPoolAddress(pool);

		// Get current sqrtPriceX96 and tick
		if (pool !== ZERO_ADDRESS) {
			const slot0 = await readContract({
				address: pool as any,
				abi: UniswapV3PoolABI,
				functionName: 'slot0',
				args: []
			}) as any;
			setProgressPercent(20);
			currentPrice.current = {
				sqrtPriceX96: slot0[0],
				tick: slot0[1]
			};
		}
	}

	const getIfoBalance = async () => {
		let balance: any = await readContract({
			address: mintItem.addr as any,
			abi: InscriptionABI,
			functionName: 'balanceOf',
			args: [mintItem.ifoContractAddress]
		});
		setProgressPercent(30);
		setIfoTokenBalance(BN(balance))

		balance = await readContract({
			address: mintItem.wethAddress as any,
			abi: InscriptionABI,
			functionName: 'balanceOf',
			args: [mintItem.ifoContractAddress]
		})
		setProgressPercent(40);
		setIfoEtherBalance(BN(balance));
		setCanAddLiquidity(!BN(balance).isZero());
	}

	const getLiquiditis = async () => {
		if (mintItem.ifoContractAddress === ZERO_ADDRESS) return;
		const positions = await readContract({
			address: mintItem.ifoContractAddress as any,
			abi: InitialFairOfferingABI,
			functionName: 'positions',
			args: [1, 10] // Improve the pageNo and pageSize
		}) as any;
		setProgressPercent(65);
		const _liquidities = new Array<Position>();

		for (let i = 0; i < positions.length; i++) {
			// get liquidity
			// tokenId = 83439; // Mock 
			const position = positions[i];
			setProgressPercent(65 + 30 * i / positions.length);

			if (BN(position.liquidity).eq(0)) continue;

			// Get token pair sorted by token/Eth, and price
			if (poolPrice.current === "0") {
				const _currentAmount: PositionAmount = getTokenAmounts(BN(position.liquidity), currentPrice.current, position.tickLower, position.tickUpper);
				if (position.token0 === wethAddress) {
					poolPrice.current = parseEther((1 / _currentAmount.price).toFixed(18)).toString();
					poolToken.current = [symbol, mintItem.tick];
				} else if (position.token1 === wethAddress) {
					poolPrice.current = parseEther(_currentAmount.price.toFixed(18)).toString();
					poolToken.current = [mintItem.tick, symbol];
				}
				// console.log("pool price: ", poolPrice.current);
			}

			const _parsedLiquidity = parseLiquidity(position);
			if (_parsedLiquidity !== null) _liquidities.push(_parsedLiquidity);
		}
		// console.log("liquidity ===> ", _liquidities);
		setLiquidities(_liquidities);
	}

	const getUserMintData = async () => {
		if (mintItem.ifoContractAddress === ZERO_ADDRESS) return;
		const _mintData = await readContract({
			address: mintItem.ifoContractAddress as any,
			abi: InitialFairOfferingABI,
			functionName: 'mintData',
			args: [currentAddress]
		}) as MintData;
		// console.log("mintData ===> ", _mintData);
		setMintData(parseMintData(_mintData));
		setProgressPercent(100);
		setShowProgress(false);
		// setTimeout(() => setShowProgress(false), 1500);
	}

	const getLaunchTime = async () => {
		if (mintItem.ifoContractAddress === ZERO_ADDRESS) return;
		try {
			const _launchTime = await readContract({
				address: mintItem.ifoContractAddress as any,
				abi: InitialFairOfferingABI,
				functionName: 'launchTime',
				args: []
			}) as any;
			setLaunchTime(toNumber(_launchTime.toString()));
		} catch (error) {
			setLaunchTime(-1);
		}
	}

	const parseLiquidity = (position: any) => {
		if (currentPrice === undefined) return null;
		// console.log("================================")
		// console.log("tokenId: " + position.tokenId);
		// console.log("currentPrice", currentPrice.current);
		const _currentAmount: PositionAmount = getTokenAmounts(BN(position.liquidity), currentPrice.current, position.tickLower, position.tickUpper);

		return {
			amount0: _currentAmount.amount0,
			amount1: _currentAmount.amount1,
			...position,
		} as Position;
	}

	const parseMintData = (_mintData: any) => {
		return {
			ethAmount: _mintData[0],
			tokenAmount: _mintData[1],
			tokenLiquidity: _mintData[2]
		} as MintData;
	}

	const reload = async () => {
		setShowProgress(true);
		setProgressPercent(0);
		await getIfoBalance();
		await getIfoBalance();
		await getLiquiditis();
		await getUserMintData();
	}

	const isVesting = () => mintItem.isIFOMode && mintItem.isVesting && mintItem.customizedVestingContractAddress !== ZERO_ADDRESS;

	const refund = async () => {
		// Check the totalRollups >= maxRollups
		if (BN(mintItem.totalRollups).gte(mintItem.maxRollups)) {
			toast.error("Mint has finished, you can not refund");
			return;
		}

		setRefundLoading(true);
		try {
			// Get balance
			let balance = BN("0");
			const _isVesting = isVesting();

			if (_isVesting) {
				balance = await readContract({
					address: mintItem.customizedVestingContractAddress as any,
					abi: VestingABI,
					functionName: 'allocation',
					args: [currentAddress]
				}) as BigNumber;
			} else {
				balance = await readContract({
					address: mintItem.addr as any,
					abi: InscriptionABI,
					functionName: 'balanceOf',
					args: [currentAddress]
				}) as BigNumber;
			}

			console.log('balance', BN(balance).toString());

			if (BN(balance).lt(mintData.tokenAmount)) {
				toast.error("Your balance is not enough");
				setRefundLoading(false);
				return;
			}

			if (_isVesting) {
				// call refund and refund from vesting contract
				doRefund();
			} else {
				// Get allowance
				const allowance = await readContract({
					address: mintItem.addr as any,
					abi: InscriptionABI,
					functionName: 'allowance',
					args: [currentAddress, mintItem.ifoContractAddress],
				}) as BigNumber;

				if (BN(allowance).lt(mintData.tokenAmount)) {
					// Approve allowance
					const { hash } = await writeContract({
						address: mintItem.addr as any,
						abi: InscriptionABI,
						functionName: 'approve',
						args: [mintItem.ifoContractAddress, mintData.tokenAmount]
					});

					waitForTransaction({ hash }).then(async (data) => {
						if (data.status === 'success') {
							doRefund();
						} else {
							toast.error("Approve Error");
							setRefundLoading(false);
						}
					})
				} else {
					// Allowance is enough
					doRefund();
				}
			}
		} catch (err) {
			setRefundLoading(false);
			toast.error("Error: " + (err as any).shortMessage);
		}
	}

	const doApply = async () => {
		try {
			setApplyLoading(true)
			const { hash } = await writeContract({
				address: mintItem.ifoContractAddress as any,
				abi: InitialFairOfferingABI,
				functionName: 'applyAddLiquidity',
				args: [],
			});

			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success("Apply successfully");
				} else {
					toast.error("Apply Error");
				}
			})
			setApplyLoading(false)
		} catch (err) {
			setApplyLoading(false);
			toast.error("Error: " + (err as any).shortMessage);
		}
	}

	const doRefund = async () => {
		try {
			const { hash } = await writeContract({
				address: mintItem.ifoContractAddress as any,
				abi: InitialFairOfferingABI,
				functionName: 'refund',
				args: [],
			});

			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success("Refund successfully");
					await getUserMintData();
					setRefundLoading(false);
				} else {
					toast.error("Refund Error");
					setRefundLoading(false);
				}
			})
		} catch (err) {
			setRefundLoading(false);
			toast.error("Error: " + (err as any).shortMessage);
		}
	}

	const totalDonation = (item: InscriptionData): string => formatEther(BN(item.maxRollups).mul(item.crowdFundingRate));

	const totalMintable = (item: InscriptionData): string => formatEther(BN(item.maxRollups).mul(item.limitPerMint).div(BN(10000).sub(item.liquidityTokenPercent)).mul(BN(10000)));

	const tokenToLiquidityTotal = (item: InscriptionData): string => formatEther(BN(item.limitPerMint).div(BN(10000).sub(item.liquidityTokenPercent)).mul(item.liquidityTokenPercent).mul(item.maxRollups));

	const etherToLiquidityTotal = (item: InscriptionData): string => formatEther(BN(item.maxRollups).mul(item.crowdFundingRate).mul(item.liquidityEtherPercent).mul(10000 - network.fundingCommissions).div(100000000));

	const initialRate = (item: InscriptionData): number => parseFloat(etherToLiquidityTotal(item)) / parseFloat(tokenToLiquidityTotal(item));

	const crowdFundingRatePerToken = (item: InscriptionData): string => BN(item.crowdFundingRate).div(BN(parseInt(formatEther(item.limitPerMint)).toString())).toString();

	const premiumRate = (item: InscriptionData): number => initialRate(item) / parseFloat(formatEther(crowdFundingRatePerToken(item)));

	const fundingRatePerToken = (item: InscriptionData): string => formatEther(crowdFundingRatePerToken(item)).toString();

	return (
		<>
			{ifShow && mintItem.addr &&
				<div>
					<input type="checkbox" id="ifoModal" className="modal-toggle" onChange={(e) => {
						setShowIFODetails(e.target.checked);
						ifShow = e.target.checked;
					}} />
					<label htmlFor="ifoModal" className="modal cursor-pointer">
						<label className="modal-box w-12/12 max-w-4xl text-sm md:text-md" htmlFor="">
							<label htmlFor="ifoModal" className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</label>
							<h3 className="font-bold text-lg">{intl.get("ifo-management")}</h3>
							{showProgress && <progress className="progress progress-secondary" value={progressPercent} max="100"></progress>}
							<div className="divider" />

							{/* <label className="flex justify-center mb-3">
						<span className="w-[40%] text-left font-bold">{intl.get("details")}</span>
						<span className="w-[60%] text-right font-bold"></span>
					</label> */}

							{/* Tick */}
							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">🌞&nbsp;{intl.get("tick1")}</span>
								<span className="w-[60%] text-right font-bold">{mintItem.tick}</span>
							</label>

							{/* Percent of Ether to Liquidity */}
							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">🌼&nbsp;{intl.get("liquidityEtherPercent", { symbol })}</span>
								<span className="w-[60%] text-right font-bold">{mintItem.liquidityEtherPercent && (toNumber(mintItem.liquidityEtherPercent) / 100).toFixed(2) + "%"}</span>
							</label>

							{/* Percent of Token to Liquidity */}
							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">☀️&nbsp;{intl.get("liquidityTokenPercent", { tick: mintItem.tick })}</span>
								<span className="w-[60%] text-right font-bold">{mintItem.liquidityTokenPercent && (toNumber(mintItem.liquidityTokenPercent) / 100).toFixed(2) + "%"}</span>
							</label>




							{/* Show more */}
							<div className="collapse">
								<input type="checkbox" />
								<div className="collapse-title text-center text-sm text-secondary">
									<div className="flex -ml-2">
										<AiOutlineDown className="" />
										<div className="ml-2 -mt-[3px]">{intl.get("more")}</div>
									</div>
								</div>
								<div className="collapse-content p-0">

									{/* deployer */}
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">🌝&nbsp;{intl.get("deployer")}</span>
										<span className="w-[60%] text-right font-bold">{formatAddress(mintItem.deployer)}</span>
										<LinkTo
											copyText={mintItem.deployer}
											url={`${scanUrl}/address/${mintItem.deployer}`}
										/>
									</label>
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">🚀&nbsp;{intl.get("deployed-time")}</span>
										<span className="w-[60%] text-right font-bold">{formatDate(toNumber(mintItem.timestamp) * 1000)}</span>
									</label>

									{/* cap */}
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">💫&nbsp;{intl.get("hard-cap")}</span>
										<span className="w-[60%] text-right font-bold">{mintItem.cap && formatDecimal2(parseFloat(formatEther(mintItem.cap)), FORMAT_DECIMAL2) + " " + mintItem.tick}</span>
									</label>

									{/* Total donation */}
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">🌟&nbsp;{intl.get("total-donation")}</span>
										<span className="w-[60%] text-right font-bold">{formatDecimal2(parseFloat(totalDonation(mintItem)), FORMAT_DECIMAL2) + " " + symbol}</span>
									</label>

									{/* Max rollups */}
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">☄️&nbsp;{intl.get("max-rollups")}</span>
										<span className="w-[60%] text-right font-bold">{mintItem.maxRollups && mintItem.maxRollups.toString()}</span>
									</label>


									{/* Minted rollups */}
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">☀️&nbsp;{intl.get("minted-rollups")}
										</span>
										<span className="w-[60%] text-right font-bold">{mintItem.totalRollups && mintItem.totalRollups.toString()}</span>
									</label>


									{/* Total mintable */}
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">🔥&nbsp;{intl.get("total-mintable")}
											{!isMobile && <Tooltip message={intl.get("tooltip-total-mintable")} />}
										</span>
										<span className="w-[60%] text-right font-bold">{formatDecimal2(parseFloat(totalMintable(mintItem)), FORMAT_DECIMAL2) + " " + mintItem.tick}</span>
									</label>

									{/* Total minted */}
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">☂️&nbsp;{intl.get("total-mint")}
											{!isMobile && <Tooltip message={intl.get("tooltip-minted-times")} />}
										</span>
										<span className="w-[60%] text-right font-bold">{mintItem.totalSupply && formatDecimal2(parseFloat(formatEther(mintItem.totalSupply)), FORMAT_DECIMAL2) + " " + mintItem.tick}</span>
									</label>

									{/* Total tokens to miner */}
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">💥&nbsp;{intl.get("total-token-to-minter", { tick: mintItem.tick })}
											{!isMobile && <Tooltip message={intl.get("tooltip-total-token-to-minter")} />}
										</span>
										<span className="w-[60%] text-right font-bold">{mintItem.limitPerMint && formatDecimal2(parseFloat(formatEther(BN(mintItem.limitPerMint).mul(mintItem.maxRollups))), FORMAT_DECIMAL2) + " " + mintItem.tick}</span>
									</label>

									{/* Total tokens to liquidity */}
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">💦&nbsp;{intl.get("total-token-to-liquidity", { tick: mintItem.tick })}
											{!isMobile && <Tooltip message={intl.get("tooltip-tokens-to-liquidity")} />}
										</span>
										<span className="w-[60%] text-right font-bold">{formatDecimal2(parseFloat(tokenToLiquidityTotal(mintItem)), FORMAT_DECIMAL2) + " " + mintItem.tick}</span>
									</label>

									{/* Total Ether to liquidity */}
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">❄️&nbsp;{intl.get("total-token-to-liquidity", { tick: symbol })}
											{!isMobile && <Tooltip message={intl.get("tooltip-total-ether-to-liquidity")} />}
										</span>
										<span className="w-[60%] text-right font-bold">{formatDecimal2(parseFloat(etherToLiquidityTotal(mintItem)), FORMAT_DECIMAL2) + " " + symbol}</span>
									</label>

									{/* Funding rate per token */}
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">🌧&nbsp;{intl.get("funding-rate")}
											{!isMobile && <Tooltip message={intl.get("tooltip-funding-rate")} />}
										</span>
										<span className="w-[60%] text-right font-bold">{formatDecimal2(parseFloat(fundingRatePerToken(mintItem)), FORMAT_DECIMAL2) + " " + symbol + "/" + mintItem.tick}</span>
									</label>

									{/* Refund fee rate */}
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">🌏&nbsp;{intl.get("refund-fee-rate")}
										</span>
										<span className="w-[60%] text-right font-bold">{(toNumber(mintItem.refundFee) / 100) + "%"}</span>
									</label>


									{/* Initial rate of uniswap */}
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">🌸&nbsp;{intl.get("initial-rate", { tick: "Eth" })}
											{!isMobile && <Tooltip message={intl.get("tooltip-initial-rate")} />}
										</span>
										<span className="w-[60%] text-right font-bold">{formatDecimal2(initialRate(mintItem), FORMAT_DECIMAL2) + " " + symbol + "/" + mintItem.tick}</span>
									</label>

									{/* Initial rate to crowd funding rate */}
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">🍀&nbsp;{intl.get("initial-rate-to-crowd-funding")}
											{!isMobile && <Tooltip message={intl.get("tooltip-initial-rate-to-crowd-funding")} />}
										</span>
										<span className="w-[60%] text-right font-bold">{"x " + formatDecimal2(premiumRate(mintItem), FORMAT_DECIMAL2)}</span>
									</label>

									{toNumber(mintItem.freezeTime) === 0 &&
										<label className="flex justify-center mb-3">
											<span className="w-[40%] text-left">🌛&nbsp;{intl.get("rollup-size")}
												{!isMobile && <Tooltip message={intl.get("tooltip-rollup-size")} />}
											</span>
											<span className="w-[60%] text-right font-bold">{toNumber(mintItem.maxMintSize)}</span>
										</label>}

									{/* minBalanceToManagerLiquidity */}
									{/* <label className="flex justify-center mb-3">
									<span className="w-[40%] text-left">{intl.get("minBalanceToManagerLiquidity")}
									{!isMobile && <Tooltip message={intl.get("tooltip-minBalanceToManagerLiquidity")}/>}
									</span>
									<span className="w-[60%] text-right font-bold">{"> " + formatDecimal2(parseFloat(formatEther(mintItem.minBalanceToManagerLiquidity)), FORMAT_DECIMAL2) + " " + mintItem.tick}</span>
								</label> */}

									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">🌈&nbsp;{intl.get("free-mint-interval")}
											{!isMobile && <Tooltip message={intl.get("tooltip-free-mint-interval")} />}
										</span>
										<span className="w-[60%] text-right font-bold">{secondsToHMS(toNumber(mintItem.freezeTime))}</span>
									</label>
								</div>
							</div>

							<div className="divider -mt-3" />

							{progressPercent === 100 && <>
								{/* FTO/IFO Data */}
								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left font-bold">{intl.get("ifo")}</span>
									<span className="w-[60%] text-right text-primary font-bold"></span>
								</label>

								{/* FTO/IFO contract address */}
								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left">🐥&nbsp;{intl.get("ifo-contract")}</span>
									<span className="w-[60%] text-right font-bold">{formatAddress(mintItem.ifoContractAddress)}</span>
									<LinkTo
										copyText={mintItem.ifoContractAddress}
										url={`${scanUrl}/address/${mintItem.ifoContractAddress}`}
									/>
								</label>

								{/* Balance of ether in IFO contract */}
								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left">🦁&nbsp;{intl.get("ifo-ether-balance", { symbol })}
										{!isMobile && <Tooltip message={intl.get("tooltip-ifo-ether-balance")} />}
									</span>
									<span className="w-[60%] text-right font-bold">{ifoEtherBalance && formatDecimal2(parseFloat(formatEther(ifoEtherBalance)), FORMAT_DECIMAL2 + 2) + " " + symbol}</span>
								</label>

								{/* Balance of token in IFO contract */}
								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left">🐸&nbsp;{intl.get("ifo-token-balance", { tick: mintItem.tick })}</span>
									<span className="w-[60%] text-right font-bold">{ifoTokenBalance && formatDecimal2(parseFloat(formatEther(ifoTokenBalance)), FORMAT_DECIMAL2) + " " + mintItem.tick}</span>
								</label>

								{launchTime >= 0 && <label className="flex justify-center mb-3">
									<span className="w-[40%] text-left">🥮&nbsp;{intl.get("add-liquidity-deadline")}</span>
									<span className="w-[60%] text-right font-bold">
										{launchTime === 0 ?
											<button className="btn btn-secondary btn-xs" onClick={doApply}>{intl.get("apply-add-liquidity")}</button> : <>{formatDate(launchTime * 1000)}</>
										}</span>
								</label>
								}

								{/* {launchTime === 0 &&
									<div className="modal-action">
										<label
											// htmlFor="doApply"
											className={`btn btn-outline btn-sm mr-4 btn-secondary ${canModify ? '' : 'btn-disabled'}`}
											onClick={doApply}
										>
											{intl.get("apply-add-liquidity")}
										</label>
									</div>} */}
								{(BN(mintItem.totalRollups).eq(mintItem.maxRollups) && !ifoEtherBalance.isZero()) &&
									<div className="modal-action">
										<label
											htmlFor="addLiquidityModal"
											className={`btn btn-outline btn-sm mr-4 btn-secondary ${canModify ? '' : 'btn-disabled'}`}
										>
											{intl.get("add-liquidity")}
										</label>
									</div>}

								<div className="divider" />

								{/* UniswapV3 Pool */}
								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left font-bold">{intl.get("uniswapV3-pool")}</span>
									<span className="w-[60%] text-right text-primary font-bold"></span>
								</label>

								{/* Pool address */}
								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left">🦐&nbsp;{intl.get("pool-address")}</span>
									<span className="w-[60%] text-right font-bold">{formatAddress(poolAddress)}</span>
									<LinkTo
										copyText={poolAddress}
										url={`${scanUrl}/address/${poolAddress}`}
									/>
								</label>

								{/* Current Price */}
								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left">🦀&nbsp;{intl.get("current-price")}</span>
									<span className="w-[60%] text-right font-bold">{
										poolPrice.current === undefined ? "" : poolPrice.current === "0" ? formatDecimal2(initialRate(mintItem), FORMAT_DECIMAL2).toString() + " " + symbol + "/" + mintItem.tick :
											formatDecimal2(parseFloat(formatEther(poolPrice.current)), FORMAT_DECIMAL2) + " " + symbol + "/" + mintItem.tick
									}</span>
								</label>

								{liquidities.length > 0 &&
									<div>
										<label className="flex justify-center mb-3">
											<span className="w-[40%] text-left font-bold">🐙&nbsp;{intl.get("positions")}</span>
											<span className="w-[60%] text-right text-primary font-bold"></span>
										</label>

										<table className="table table-zebra w-full">
											<thead>
												<tr>
													<th>Id
														{!isMobile && <Tooltip message="TokenId of liquidity NFT" />}
													</th>
													<th className="text-center">{poolToken.current[0]}
														{!isMobile && <Tooltip message="Balance of position" />}
													</th>
													<th className="text-center">{poolToken.current[1]}
														{!isMobile && <Tooltip message="Balance of position" />}
													</th>
													{supportRemoveLiquidity && <>
														<th className="text-center">Lower
															{!isMobile && <Tooltip message="Lower tick" />}
														</th>
														<th className="text-center">Upper
															{!isMobile && <Tooltip message="Upper tick" />}
														</th>
														<th className="text-right">Action</th>
													</>}
													<th className="text-center">Collect</th>
												</tr>
											</thead>
											<tbody>
												{liquidities.map((item: Position, index: number) => {
													if (BN(item.liquidity).gt(0)) return (
														<tr key={index}>
															<td>{toNumber(item.tokenId)}</td>
															<td className="text-center">{item.amount0 && formatDecimal2(parseFloat(formatEther(item.amount0)), FORMAT_DECIMAL2)}</td>
															<td className="text-center">{item.amount1 && formatDecimal2(parseFloat(formatEther(item.amount1)), FORMAT_DECIMAL2)}</td>
															{supportRemoveLiquidity && <>
																<td className="text-center">{compareAddress(wethAddress, mintItem.addr) ? tickToPrice(-item.tickUpper) : tickToPrice(item.tickLower)}</td>
																<td className="text-center">{compareAddress(wethAddress, mintItem.addr) ? tickToPrice(-item.tickLower) : tickToPrice(item.tickUpper)}</td>
																<td className="text-right">
																	<label
																		htmlFor='removeLiquidityModal'
																		className={`btn btn-outline btn-sm btn-secondary ${canModify ? "" : "btn-disabled"}`}
																		onClick={() => setCurrentTokenId(toNumber(item.tokenId))}
																	>
																		{intl.get("reduce-liquidity")}
																	</label>
																</td></>}
															<td className="text-center">
																<label
																	htmlFor='collectFeeModal'
																	className={`btn btn-outline btn-sm btn-secondary`}
																	onClick={() => setCurrentTokenId(toNumber(item.tokenId))}
																>
																	{intl.get("collect-fee")}
																</label>
															</td>
														</tr>)
												})}
											</tbody>
										</table>
									</div>}

								<div className="divider" />

								{/* My mint record */}
								{mintData && mintData.ethAmount && BN(mintData.ethAmount).gt(0) &&
									<div>
										<label className="flex justify-center mb-3">
											<span className="w-[40%] text-left font-bold">{intl.get("my-balance")}</span>
											<span className="w-[60%] text-right text-primary font-bold"></span>
										</label>

										<label className="flex justify-center mb-3">
											<span className="w-[40%] text-left">🐠&nbsp;{intl.get("my-mint-eth", { symbol })}</span>
											<span className="w-[60%] text-right text-primary font-bold">{mintData.ethAmount && formatDecimal2(parseFloat(formatEther(mintData.ethAmount)), FORMAT_DECIMAL2) + " " + symbol}</span>
										</label>

										<label className="flex justify-center mb-3">
											<span className="w-[40%] text-left">
												<div>🐳&nbsp;{intl.get("my-mint-token", { tick: mintItem.tick })}</div>
												<div>{isVesting() &&
													<div className="flex font-bold text-primary">
														{intl.get("check-vesting-contract")}
														<LinkTo
															copyText={`/vesting/${mintItem.addr}`}
															url={`${BASE_URL}/vesting/${mintItem.addr}`}
														/>
													</div>}</div>
											</span>
											<span className="w-[60%] text-right text-primary font-bold">{mintData.tokenAmount && formatDecimal2(parseFloat(formatEther(mintData.tokenAmount)), FORMAT_DECIMAL2) + " " + mintItem.tick}</span>
										</label>

										<label className="flex justify-center mb-3">
											<span className="w-[100%] text-left text-xs text-secondary flex"><AiTwotoneAlert />{intl.get("memo-refund")}</span>
										</label>

										{BN(mintItem.totalRollups).lt(mintItem.maxRollups) &&
											<div className="modal-action">
												<button
													className={`btn btn-outline btn-sm mr-4 ${canRefund ? '' : 'btn-disabled'} ${refundLoading ? 'loading btn-disabled' : 'btn-secondary'}`}
													onClick={() => refund()}
												>
													{intl.get("refund")}
												</button>
											</div>}
									</div>
								}
							</>}
						</label>
					</label>
					<AddLiquidity
						// canModify={canModify}
						canAddLiquidity={canAddLiquidity}
						tokenAddress={mintItem.addr}
						ifoContractAddress={mintItem.ifoContractAddress}
						wethAddress={wethAddress}
						reload={() => reload()}
						currentPrice={
							poolPrice.current === undefined ? "" : poolPrice.current === "0" ? formatDecimal2(initialRate(mintItem), FORMAT_DECIMAL2).toString() + " " + symbol + "/" + mintItem.tick :
								formatDecimal2(parseFloat(formatEther(poolPrice.current)), FORMAT_DECIMAL2) + " " + symbol + "/" + mintItem.tick
						}
					/>
					{supportRemoveLiquidity &&
						<RemoveLiquidity
							canModify={canModify}
							ifoContractAddress={mintItem.ifoContractAddress}
							reload={() => reload()}
							tokenId={currentTokenId}
						/>}

					<CollectPoolFee
						canModify={canModify}
						ifoContractAddress={mintItem.ifoContractAddress}
						reload={() => reload()}
						tokenId={currentTokenId}
					/>
				</div>
			}
		</>)
}

export default IFODetails;
