import { FC } from "react";
import { InfoBarProps } from "../utils/types"

const InfoBar: FC<InfoBarProps> = ({
	title,
	value
}) => {
	return (
		<div className="input-group flex justify-center mb-3">
			<span className="w-[35%] text-left">{title}</span>
			<span className="input input-bordered w-[65%] bg-base-100">{value}</span>
		</div>
	)
}

export default InfoBar;