import React, { FC, useEffect, useState } from 'react';
import Logo from './Logo';
import { Web3Button } from '@web3modal/react'
import { useNetwork } from 'wagmi';
import { BASE_URL, getNetwork } from '../config';
import { BsSun, BsMoon } from 'react-icons/bs';
import useDeviceDetect from '../utils/useDeviceDetect';

interface HeadProps {
	updateVersion: (version: string) => void;
}

const Header: FC<HeadProps> = ({
	updateVersion
}) => {
	const isMobile = useDeviceDetect();
	const [bgColor, setBgColor] = useState("#fff");
	const [foreColor, setForeColor] = useState("#000");
	const [chainName, setChainName] = useState("");
	const [theme, setTheme] = useState(
		localStorage.getItem("ferc_theme") ? localStorage.getItem("ferc_theme") : "light"
	);
	const [version, setVersion] = useState(
		localStorage.getItem("ferc_version") ? localStorage.getItem("ferc_version") : "v31"
	);

	const { chain } = useNetwork();

	useEffect(() => {
		if (chain) {
			const chainId = chain.id;
			const network = getNetwork(chainId, "v31");
			setChainName(chain.name);
			setBgColor(network ? network.bgColor : "#fff");
			setForeColor(network ? network.foreColor : "#000");
		}
	}, [chain]);

	useEffect(() => {
		localStorage.setItem("ferc_theme", theme as string);
		const localTheme = localStorage.getItem("ferc_theme");
		// add custom data-theme attribute to html tag required to update theme using DaisyUI
		document.querySelector("html")?.setAttribute("data-theme", localTheme as string);
	}, [theme]);

	useEffect(() => {
		localStorage.setItem("ferc_version", version as string);
		updateVersion(version as string);
	}, [version]);

	const handleToggle = (e: any) => {
		if (e.target.checked) setTheme("halloween");
		else setTheme("light");
	}

	const changeLanguages = (lang: string) => {
		localStorage.setItem("ferc_lang", lang);
		window.location.reload();
	}

	return (
		<>
			<div className="navbar md:py-5 md:px-10 p-3 bg-base-100 fixed flex justify-between top-0 left-0">
				<div className="cursor-pointer" >
					<Logo />
					<select className="select select-sm max-w-xs ml-3 pl-1 pr-3" defaultValue={version as string} onChange={(e) => {
						const versionValue = e.target.value;
						if (versionValue === 'v31' || versionValue === 'v3') {
							localStorage.setItem("ferc_version", versionValue)
							updateVersion(versionValue)
							setVersion(versionValue)
						} else {
							window.open('https://erc20.cash', '_blank');
						}
					}}>
						<option value="v31">V3.1</option>
						<option value="v3">V3</option>
						<option value="v1&v2">V1&V2</option>
					</select>
				</div>
				<div>
					{!isMobile && <ul className="menu menu-horizontal px-1">
						<li tabIndex={0}>
							<a>
								{localStorage.getItem("ferc_lang") ? localStorage.getItem("ferc_lang") as string : "en"}
								<svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
							</a>
							<ul className="p-2 bg-base-100 cursor-pointer">
								<li ><a onClick={() => changeLanguages("en")} className="py-1">🇺🇸 English</a></li>
								<li ><a onClick={() => changeLanguages("ja")} className="py-1">🇯🇵 日本語</a></li>
								<li ><a onClick={() => changeLanguages("ru")} className="py-1">🇷🇺 русский</a></li>
								<li ><a onClick={() => changeLanguages("zh")} className="py-1">🇨🇳 中文</a></li>
								<li ><a onClick={() => changeLanguages("de")} className="py-1">🇩🇪 Deutsch</a></li>
								<li ><a onClick={() => changeLanguages("th")} className="py-1">🇹🇭 ภาษาไทย</a></li>
								<li ><a onClick={() => changeLanguages("es")} className="py-1">🇪🇸 España</a></li>
								<li ><a onClick={() => changeLanguages("fr")} className="py-1">🇫🇷 Français</a></li>
								<li ><a onClick={() => changeLanguages("ko")} className="py-1">🇰🇷 한국어</a></li>
								<li ><a onClick={() => changeLanguages("vi")} className="py-1">🇻🇳 Tiếng Việt</a></li>
								{/* <li><a>Submenu 1</a></li>
								<li><a>Submenu 2</a></li> */}
							</ul>
						</li>
					</ul>}
					<label className="swap swap-rotate w-12 h-12">
						<input type="checkbox"
							onChange={handleToggle}
							// show toggle image based on localstorage theme
							checked={theme === "light" ? false : true}
						/>
						<div className="swap-on"><BsSun className='text-xl mr-5' /></div>
						<div className="swap-off"><BsMoon className='text-xl mr-5' /></div>
					</label>

					<div
						className={`hidden md:block mr-3 text-sm px-3 py-2 rounded-md cursor-pointer`}
						style={{ backgroundColor: bgColor, color: foreColor }}
					>
						{chainName}
					</div>

					<div className="flex-none gap-2">
						<Web3Button />
					</div>
				</div>
			</div>
		</>)
}

export default Header;