import {FC} from "react";
import { LinkToProps } from "../utils/types";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { FiCopy, FiExternalLink } from "react-icons/fi";

const LinkTo:FC<LinkToProps> = ({
	copyText,
	url
}) => {

	return(<>
		<span>
		<CopyToClipboard
			text={copyText}
			onCopy={() => {
				toast.success("Copied");
			}}
		>
			<FiCopy className='ml-2 mt-1 cursor-pointer	' />
		</CopyToClipboard>
	</span>
	{url !== undefined &&
	<span>
		<a href={url} target='_blank' rel="noreferrer">
			<FiExternalLink className='ml-2 mt-1 cursor-pointer'/>
		</a>
	</span>}
	</>
	)
}

export default LinkTo;