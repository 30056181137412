import appinfo from "../../package.json";
import { BASE_URL } from "../config";

const Logo = () => {
	return (
		<div className="flex tracking-wide cursor-default">
			<div className="text-[#F28C18] font-bold text-2xl md:text-3xl mr-1" onClick={() => window.open(BASE_URL, "_self")}>fair</div>
			<div className="font-extrabold text-2xl md:text-3xl" onClick={() => window.open(BASE_URL, "_self")}>ERC20</div>
		</div>
	)
}

export default Logo;