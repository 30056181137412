import { FC, useEffect, useImperativeHandle, useState } from "react";
import { writeContract, readContract, waitForTransaction } from "@wagmi/core";
import InscriptionV3ABI from '../abi/Inscription.json';
import VestingABI from '../abi/Vesting.json';
import toast from "react-hot-toast";
import { TokenDetailsProps } from "../utils/types";
import { BN, formatAddress, formatDecimal2, formatEther, resetIP, secondsToHMS, toNumber } from "../utils/common";
import { BigNumber } from "ethers";
import { BASE_URL, FORMAT_DECIMAL2, ZERO_ADDRESS } from "../config";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import Tooltip from "./Tooltip";
import intl from "../utils/intl";
import LinkTo from "./LinkTo";
import useDeviceDetect from "../utils/useDeviceDetect";
import { formatUnits } from "ethers/lib/utils";

const TokenDetails: FC<TokenDetailsProps> = ({
	mintItem,
	mintRepeat,
	currentAddress,
	symbol,
	scanUrl,
	loadInscriptionData,
	tdRef
}) => {
	const isMobile = useDeviceDetect();

	const [minting, setMinting] = useState(false);
	const [confirmTip, setConfirmTip] = useState(false);
	const [tip, setTip] = useState({ show: false, mintedTimes: 0, nextMintFee: 0 });
	const [balanceToken, setBalanceToken] = useState("");
	const [isVesting, setIsVesting] = useState(false);

	const mint = async () => {

		if (mintItem.tick === "" || mintRepeat <= 0 || mintRepeat > toNumber(mintItem.maxMintSize)) return;
		setMinting(true);
		if (!mintItem) return;

		// if(await checkDoubleIP(mintItem.freezeTime)) {
		// 	toast.error("You can not mint in freeze period");
		// 	setMinting(false);
		// 	return;
		// }

		try {
			if (toNumber(mintItem.freezeTime) > 0) {
				// can not batch mint
				const mintFee: any = await readContract({
					address: mintItem.addr as any,
					abi: InscriptionV3ABI,
					account: currentAddress as any,
					functionName: 'getMintFee',
					args: [currentAddress]
				});

				const mintedTimes = toNumber(mintFee[0]);
				const nextMintFee = mintFee[1];

				if (mintedTimes > 0) {
					setMinting(false);
					// show the tip input box
					setTip({
						show: true,
						mintedTimes,
						nextMintFee
					})
					if (confirmTip) await doMint("mint", BN(mintItem.crowdFundingRate).add(nextMintFee));
					// else toast.error("You have to confirm to pay the tip before you continue.")
				} else {
					resetTip();
					await doMint("mint", BN(mintItem.crowdFundingRate));
				}
			} else {
				// can do batch mint
				await doMint("batchMint", BN(mintItem.crowdFundingRate).mul(mintRepeat), mintRepeat);
			}
		} catch (err) {
			// console.log(err)
			setMinting(false);
			toast.error("Error: " + (err as any).shortMessage);
		}
	}

	useEffect(() => {
		if (mintItem !== undefined) {
			if (mintItem.isVesting && mintItem.customizedVestingContractAddress !== ZERO_ADDRESS) {
				getAllocationBalance().then((_balance: any) => {
					setBalanceToken(formatDecimal2(parseFloat(formatEther(_balance)), FORMAT_DECIMAL2));
				})
				setIsVesting(true);
			} else {
				if (mintItem.balance === undefined) setBalanceToken("-");
				else if (BN(mintItem.balance).eq(BN(0))) setBalanceToken("0");
				else setBalanceToken(mintItem.balance && formatDecimal2(parseFloat(formatEther(mintItem.balance)), FORMAT_DECIMAL2));
				setIsVesting(false);
			}
		}
	}, [, mintItem]);

	const resetTip = () => {
		setTip({
			show: false,
			mintedTimes: 0,
			nextMintFee: 0,
		})
	}

	const doMint = async (functionName: string, fee: BigNumber, repeat: number = 1) => {
		setMinting(true);
		const { hash } = await writeContract({
			address: mintItem.addr as any,
			abi: InscriptionV3ABI,
			functionName,
			args: functionName === "mint" ? [currentAddress] : [currentAddress, repeat],
			value: fee as any,
		});
		resetIP();

		waitForTransaction({ hash }).then(async (data) => {
			if (data.status === 'success') {
				toast.success("Mint successfully");
			} else {
				toast.error("Mint Error");
			}
			loadInscriptionData();
			resetTip();
			setConfirmTip(false);
			setMinting(false);
		})
	}

	const getAllocationBalance = async () => {
		return await readContract({
			address: mintItem.customizedVestingContractAddress as any,
			abi: VestingABI,
			functionName: 'allocation',
			args: [currentAddress]
		});;
	}

	const conditionSatisfied = () =>
		mintItem.onlyContractAddress === ZERO_ADDRESS ||
		(mintItem.onlyBalanceOf && BN(mintItem.onlyBalanceOf).gte(BN(mintItem.onlyMinQuantity)));

	useImperativeHandle(tdRef, () => ({
		resetTip: () => resetTip(),
	}));

	return (
		<>
			{mintItem.addr &&
				<div>
					<input type="checkbox" id="mintModal2" className="modal-toggle" />
					<label htmlFor="mintModal2" className="modal cursor-pointer">
						<label className="modal-box w-12/12 max-w-3xl md:text-md text-sm" htmlFor="">
							<label htmlFor="mintModal2" className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</label>
							<h3 className="font-bold text-lg">{intl.get("mint")}</h3>

							<div className="divider" />
							{/* <p className="py-4">Input your requirements</p> */}

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">🌞&nbsp;{intl.get("tick1")}</span>
								<span className="w-[60%] text-right font-bold">{mintItem.tick}</span>
							</label>

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">🌼&nbsp;{intl.get("name")}</span>
								<span className="w-[60%] text-right font-bold">{mintItem.name}</span>
							</label>

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">🌎&nbsp;{intl.get("contract")}
									{!isMobile && <Tooltip
										message={intl.get("tooltip-contract")}
									/>}
								</span>
								<span className="w-[60%] text-right font-bold">
									{mintItem.addr && formatAddress(mintItem.addr)}
								</span>
								{!isMobile && <LinkTo
									copyText={mintItem.addr}
									url={`${scanUrl}/token/${mintItem.addr}`}
								/>}
							</label>

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">🌎&nbsp;{intl.get("deployer")}
								</span>
								<span className="w-[60%] text-right font-bold">
									{mintItem.deployer && formatAddress(mintItem.deployer)}
								</span>
								<LinkTo
									copyText={mintItem.deployer}
									url={`${scanUrl}/address/${mintItem.deployer}`}
								/>
							</label>

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">✨&nbsp;{intl.get("hard-cap")}</span>
								<span className="w-[60%] text-right font-bold">{mintItem.cap && formatDecimal2(parseFloat(formatEther(mintItem.cap)), FORMAT_DECIMAL2) + " " + mintItem.tick}</span>
							</label>

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">🪐&nbsp;{intl.get("limit-per-mint")}
									{!isMobile && <Tooltip
										message={intl.get("tooltip-limit-per-mint")}
									/>}
								</span>
								<span className="w-[60%] text-right font-bold">{mintItem.limitPerMint && formatDecimal2(parseFloat(formatEther(mintItem.limitPerMint)), FORMAT_DECIMAL2) + " " + mintItem.tick}</span>
							</label>

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">🌈&nbsp;{intl.get("token-vesting")}
								</span>
								{mintItem.customizedVestingContractAddress === ZERO_ADDRESS ?
									<span className="w-[60%] text-right font-bold">
										{intl.get("token-allocation-no-vesting")}
									</span>
									:
									<>
										<span className="w-[60%] text-right font-bold">
											{mintItem.addr && formatAddress(mintItem.customizedVestingContractAddress)}
										</span>
										<LinkTo
											copyText={mintItem.customizedVestingContractAddress}
											url={`${scanUrl}/address/${mintItem.customizedVestingContractAddress}`}
										/>
									</>
								}
							</label>

							{toNumber(mintItem.freezeTime) === 0 &&
								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left">☀️&nbsp;{intl.get("rollup-size")}
										{!isMobile && <Tooltip message={intl.get("tooltip-rollup-size")} />}
									</span>
									<span className="w-[60%] text-right font-bold">{toNumber(mintItem.maxMintSize)}</span>
								</label>}

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">🌸&nbsp;{intl.get("total-mint")}</span>
								<span className="w-[60%] text-right font-bold">{mintItem.totalSupply && formatDecimal2(parseFloat(formatEther(mintItem.totalSupply)), FORMAT_DECIMAL2) + " " + mintItem.tick}</span>
							</label>

							{mintItem.isIFOMode ?
								<div>
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">💦 {intl.get("funding-rate")}
											{!isMobile && <Tooltip
												message={intl.get("tooltip-funding-rate")}
											/>}
										</span>
										<span className="w-[60%] text-right font-bold">{formatDecimal2(parseFloat(formatEther(mintItem.crowdFundingRate)), FORMAT_DECIMAL2) + " " + symbol}</span>
									</label>

									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">☂️ {intl.get("ifo-contract-address")}
											{!isMobile && <Tooltip
												message={intl.get("tooltip-funding-address")}
											/>}
										</span>
										<span className="w-[60%] text-right font-bold">{formatAddress(mintItem.ifoContractAddress)}</span>
										<LinkTo
											copyText={mintItem.ifoContractAddress}
											url={`${scanUrl}/address/${mintItem.ifoContractAddress}`}
										/>
									</label>
								</div>
								:
								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left">☘️ {intl.get("funding-rate")}</span>
									<span className="w-[60%] text-right font-bold">{intl.get("free")}</span>
								</label>

							}

							{mintItem.onlyContractAddress && mintItem.onlyContractAddress !== ZERO_ADDRESS && mintItem.decimals &&
								<div>
									<label className="flex justify-center mb-3">
										<span className="w-[40%] text-left">🌹 {intl.get("you-should-hold")}
											{!isMobile && <Tooltip
												message={intl.get("tooltip-you-should-hold")}
											/>}
										</span>
										<span className="w-[60%] text-right font-bold">
											{formatDecimal2(parseFloat(
												 toNumber(mintItem.decimals) !== 0 ? formatUnits(mintItem.onlyMinQuantity, mintItem.decimals) : mintItem.onlyMinQuantity.toString()), FORMAT_DECIMAL2) + " " + (mintItem.onlyTokenName as string)}
										</span>
										<span>{conditionSatisfied() ?
											<AiFillCheckCircle className="ml-2 mt-1 text-green-400" /> :
											<AiFillCloseCircle className="ml-2 mt-1 text-red-500" />}
										</span>
									</label>
								</div>}

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">🌲 {intl.get("free-mint-interval")}
									{!isMobile && <Tooltip
										message={intl.get("tooltip-free-mint-interval")}
									/>}
								</span>
								<span className="w-[60%] text-right font-bold">{secondsToHMS(toNumber(mintItem.freezeTime))}</span>
							</label>

							<div className="divider" />

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">🍄 {intl.get("my-balance")}
									{isVesting ?
										<div className="flex font-bold text-primary">
											{intl.get("check-vesting-contract")}
											<LinkTo
												copyText={`/vesting/${mintItem.addr}`}
												url={`${BASE_URL}/vesting/${mintItem.addr}`}
											/>
										</div>
										: ""}
								</span>
								<span className="w-[60%] text-right text-primary font-bold">{balanceToken + " " + mintItem.tick}</span>
							</label>

							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left">🌴 {intl.get("i-want-to-mint")}</span>
								<span className="w-[60%] text-right text-primary font-bold">{mintRepeat + " x " + (mintItem.limitPerMint && formatDecimal2(parseFloat(formatEther(mintItem.limitPerMint)), 3)) + " " + mintItem.tick}</span>
							</label>

							{tip.show && mintItem.limitPerMint &&
								<div className="text-primary">
									{`WARNING: You have minted ${tip.mintedTimes * parseFloat(formatEther(mintItem.limitPerMint))} ${mintItem.tick}s freely. You need to pay a tip of ${formatEther(tip.nextMintFee)} ${symbol} if you want to continue minting during the frozen period. Please click the checkbox and MINT button below if you wish to proceed. Or you can wait until the end of the frozen period to mint again.`}
									<label className="cursor-pointer label mt-2">
										<div className="text-secondary flex">
											<input type="checkbox" checked={confirmTip} onChange={() => setConfirmTip(!confirmTip)} className="checkbox checkbox-secondary" />
											<div className="ml-3">{intl.get("accept-tip")}</div>
										</div>
									</label>
								</div>}

							<div className="modal-action">
								{conditionSatisfied() &&
									<button className={`btn ${minting ? 'loading btn-disabled' : 'btn-secondary'}`} onClick={() => mint()}>{intl.get("mint")}</button>
								}
							</div>
						</label>
					</label>
				</div>
			}
		</>)
}

export default TokenDetails;

