import { FC, useEffect, useState } from "react";
import { NetworkData, VestingMainProps } from "../utils/types";
import intl from "../utils/intl";
import InscriptionFactoryABI from '../abi/InscriptionFactory.json';
import VestingABI from '../abi/Vesting.json';
import toast, { Toaster } from "react-hot-toast";
import { useNetwork, useAccount } from "wagmi";
import { FORMAT_DECIMAL2, ZERO_ADDRESS } from "../config";
import { readContract, readContracts, writeContract, waitForTransaction } from "@wagmi/core";
import { BN, formatAddress, formatDate, formatDecimal2, formatEther, getContractAddressByVersion, getNetworkData, toNumber } from "../utils/common";
import { useParams } from "react-router-dom";
import LinkTo from "./LinkTo";
import useDeviceDetect from "../utils/useDeviceDetect";

const VestingMain: FC<VestingMainProps> = ({ version }) => {
	const params = useParams();
	const tokenAddress = params.tokenAddress as string;
	const isMobile = useDeviceDetect();

	const [loading, setLoading] = useState(false);
	const [loadingClaim, setLoadingClaim] = useState(false);
	const [isVesting, setIsVesting] = useState(false);
	const [vestingAddress, setVestingAddress] = useState(ZERO_ADDRESS);
	const [scanUrl, setScanUrl] = useState("");
	const { chain } = useNetwork();

	const currentAddress = useAccount().address as string;
	const [startTime, setStartTime] = useState(0);
	const [duration, setDuration] = useState(0);
	const [allocation, setAllocation] = useState("");
	const [claimed, setClaimed] = useState("");
	const [available, setAvailable] = useState("");
	const [released, setReleased] = useState("");
	const [outstanding, setOutstanding] = useState("");

	useEffect(() => {
		if (chain) {
			const network = getNetworkData(chain, version);
			setScanUrl(network.scanUrl);
			getVestingContract(network);
		}
	}, [chain, version]);

	const getVestingContract = async (network: NetworkData) => {
		setLoading(true);
		const token: any = await readContract({
			address: network.contractAddress as any,
			abi: InscriptionFactoryABI,
			functionName: "getIncriptionByAddress",
			args: [tokenAddress]
		})
		setIsVesting(token[0].isVesting);
		if (token[0].isVesting) {
			setVestingAddress(token[0].customizedVestingContractAddress);
			// setTickAddress(token[0].addr);
			load(token[0].customizedVestingContractAddress);
		} else {
			setLoading(false);
		}
	}

	const load = async (customizedVestingContractAddress: any) => {
		const contract = {
			address: customizedVestingContractAddress,
			abi: VestingABI as any,
		}
		const data = await readContracts({
			contracts: [{
				...contract,
				functionName: "allocation", // 0
				args: [currentAddress]
			}, {
				...contract,
				functionName: "startTime", // 1
				args: []
			}, {
				...contract,
				functionName: "duration", // 2
				args: []
			}, {
				...contract,
				functionName: "claimed", // 3
				args: [currentAddress]
			}, {
				...contract,
				functionName: "released", // 4
				args: [currentAddress]
			}, {
				...contract,
				functionName: "outstanding", // 5
				args: [currentAddress]
			}, {
				...contract,
				functionName: "available", // 6
				args: [currentAddress]
			}]
		});

		setStartTime(toNumber(data[1].result) * 1000);
		setDuration(toNumber(data[2].result) * 1000);

		setAllocation(formatDecimal2(parseFloat(formatEther(BN(data[0].result))), FORMAT_DECIMAL2));
		setClaimed(formatDecimal2(parseFloat(formatEther(BN(data[3].result))), FORMAT_DECIMAL2));
		setReleased(formatDecimal2(parseFloat(formatEther(BN(data[4].result))), FORMAT_DECIMAL2));
		setOutstanding(formatDecimal2(parseFloat(formatEther(BN(data[5].result))), FORMAT_DECIMAL2));
		setAvailable(formatDecimal2(parseFloat(formatEther(BN(data[6].result))), FORMAT_DECIMAL2));
		setLoading(false);
	}

	const claim = async () => {
		console.log("claim");
		if (parseFloat(available) === 0) {
			toast.error("Claimable amount is zero");
			return;
		}
		try {
			setLoadingClaim(true);
			const { hash } = await writeContract({
				address: vestingAddress as any,
				abi: VestingABI,
				functionName: "claim",
				args: [],
			});

			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success("Mint successfully");
					await load(vestingAddress);
				} else {
					toast.error("Mint Error");
				}
				setLoadingClaim(false);
			})
		} catch (err) {
			setLoadingClaim(false);
			toast.error("Error: " + (err as any).shortMessage);
		}
	}

	return (
		<div>
			<div className="hero pt-[120px] pb-5">
				<div className="hero-content text-center">
					<div className="max-w-md">
						<h1 className="text-4xl font-bold">{intl.get("token-vesting")} 🐸</h1>
						<p className="py-6">{intl.get("vesting-introduction")}</p>
						<div className="mb-1">
							<p className="flex justify-center">
								{intl.get("vesting-contract") + formatAddress(vestingAddress)}
								<LinkTo
									copyText={vestingAddress}
									url={`${scanUrl}/address/${vestingAddress}`}
								/>
							</p>

							<p className="flex justify-center">
							</p>
						</div>
					</div>
				</div>
			</div>

			{loading ? <></> :
				!isVesting ?
					<div className="text-center border-gray-400 text-white text-2xl font-bold bg-warning md:w-[50%] md:ml-[25%] w-[94%] ml-[3%] p-8 rounded-lg ">
						{intl.get("vesting-not-support")}
					</div> :
					<div className="bg-base-200 text-center md:w-[50%] md:ml-[25%] md:p-12 w-[100%] ml-[0%] p-3 rounded-lg">
						{/* Tick */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">{intl.get("tick2")}</span>
							<span className="input input-bordered w-[60%] bg-base-100">{isMobile ? formatAddress(tokenAddress) : tokenAddress}</span>
						</div>

						{/* User address */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">{intl.get("participant-address")}</span>
							<span className="input input-bordered w-[60%] bg-base-100">{isMobile ? formatAddress(currentAddress) : currentAddress}</span>
						</div>

						{/* Start time */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">{intl.get("vesting-start-time")}</span>
							<span className="input input-bordered w-[60%] bg-base-100">{formatDate(startTime)}</span>
						</div>

						{/* End time */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">{intl.get("vesting-end-time")}</span>
							<span className="input input-bordered w-[60%] bg-base-100" >{formatDate(startTime + duration)}</span>
						</div>

						{/* Total Allocation */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">{intl.get("vesting-allocation")}</span>
							<span className="input input-bordered w-[60%] bg-base-100">{allocation}</span>
						</div>

						{/* Released */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">{intl.get("vesting-released")}</span>
							<span className="input input-bordered w-[60%] bg-base-100">{released}</span>
						</div>

						{/* locked/outstanding = allication - released */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">{intl.get("vesting-outstanding")}</span>
							<span className="input input-bordered w-[60%] bg-base-100">{outstanding}</span>
						</div>

						{/* Claimed */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">{intl.get("vesting-claimed")}</span>
							<span className="input input-bordered w-[60%] bg-base-100">{claimed}</span>
						</div>

						{/* Claimable/available = released - claimed */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">{intl.get("vesting-available")}</span>
							<span className="input input-bordered w-[60%] bg-base-100">{available}</span>
						</div>

						<button
							className={`btn mt-2 btn-wide ${parseFloat(available) === 0 ? 'btn-disabled' : ''} ${loadingClaim ? 'btn-disabled loading' : 'btn-primary'}`}
							onClick={() => claim()}
						>
							{intl.get("vesting-claim")}
						</button>
					</div>}

			<div className="h-24" />

			{/* Toast */}
			<Toaster toastOptions={{
				duration: 4000,
			}} />

		</div>)
}

export default VestingMain;