import { FC, useEffect, useState } from "react";
import { LaunchpadTableProps } from "../utils/types";
import { BigNumber, ethers } from "ethers";
import { BN, formatAddress, formatDate, formatEther, voteUrl } from "../utils/common";
import LinkTo from "./LinkTo";
import { readContract } from "@wagmi/core";
import VoteForLaunchABI from "../abi/VoteForLaunch.json";
import Rate from "./Rate";
import intl from "../utils/intl";

const LaunchpadTable: FC<LaunchpadTableProps> = ({
	list,
	scanUrl,
	voteForLaunchpadAddress,
	totalBallots,
}) => {
	const [deployExpireTime, setDeployExpireTime] = useState(BN("0"));

	useEffect(() => {
		if (voteForLaunchpadAddress === undefined) return;

		readContract({
			address: voteForLaunchpadAddress as any,
			abi: VoteForLaunchABI,
			functionName: "UNDEPLOYED_EXPIRE",
			args: []
		}).then((time) => {
			setDeployExpireTime(BN(time));
		})
	}, [voteForLaunchpadAddress])

	return (
		<>
			<table className="table table-zebra w-full">
				<thead>
					<tr>
						<th>
							{intl.get("tick1")}
						</th>
						<th>
							{intl.get("launchpad-total-ballots")}
						</th>
						<th>
							{intl.get("launchpad-my-votes")}
						</th>
						<th>
							{intl.get("launchpad-applicant")}
						</th>
						<th>
							{intl.get("launchpad-expire-at")}
						</th>
						<th>
							{intl.get("launchpad-passed")}
						</th>
						<th>
							{intl.get("deployed")}
						</th>
						<th>
							{intl.get("can-deploy")}
						</th>
						<th>
							{intl.get("launchpad-stars")}
						</th>
						<th>
							{intl.get("action")}
						</th>
					</tr>
				</thead>
				<tbody>
					{list.sort((a, b) => {
						const sub = BN(b.myVotes).sub(BN(a.myVotes)); // order by my votes desc
						return parseFloat(formatEther(sub));
					}).map((data, index) =>
						<tr key={index} className="text-sm">
							<td>
								<div className="flex">
									{data.tick}
									<LinkTo
										copyText={voteUrl(data.tick as string)}
										url={voteUrl(data.tick as string)}
									/>
								</div>
							</td>
							<td>{formatEther(data.application.totalVotes)}</td>
							<td>{formatEther(data.myVotes)}</td>
							<td>
								<div className="flex">
									{formatAddress(data.application.applicant)}
									<LinkTo
										copyText={data.application.applicant}
										url={scanUrl + "/address" + data.application.applicant}
									/>
								</div>
							</td>
							<td>
								<div>{formatDate(BN(data.application.expireAt).toNumber() * 1000)}</div>
								{/* <div className={`font-bold ${data.isExpireForVote ? "text-error" : "text-success"}`}>{data.isExpireForVote ? "Expired" : "Voting"}</div> */}
							</td>
							<td>
								{data.application.passed ?
									<div>
										<div className={`font-bold ${data.isExpireForDeploy && !data.application.deployed ? "text-error" : "text-success"}`}>{data.isExpireForDeploy && !data.application.deployed ? intl.get("passed-not-deployed") : intl.get("deploy-before")}</div>
										<div>{formatDate(BN(data.application.passedTimestamp).add(deployExpireTime).toNumber() * 1000)}</div>
									</div>
									:
									<div>
										<div className={`font-bold ${data.isExpireForVote ? 'text-error' : 'text-success'}`}>{`${data.isExpireForVote ? intl.get("vote-fail") : intl.get("voting")}`}</div>
									</div>}
							</td>
							<td>
								<div className={`font-bold ${!data.application.deployed ? "text-error" : "text-success"}`}>{!data.application.deployed ? intl.get("not-deployed") : intl.get("deployed")}</div>
							</td>
							<td>
								<div className={`font-bold ${!data.canDeploy ? "text-error" : "text-success"}`}>{!data.canDeploy ? intl.get('can-not-deploy') : intl.get("can-deploy")}</div>
								<div className={`text-base-400`}>{data.description}</div>
							</td>
							<td className="text-sm">
								{totalBallots === undefined || totalBallots.isZero() || data.application.topVotes === undefined ? 0
									: (BN(data.application.topVotes).mul(10000).div(totalBallots).toNumber() / 100)}
							</td>
							<td>
								<a className="btn btn-primary btn-sm" href={`/launchpad/${data.tick}`} target="_blank" rel="noreferrer">{intl.get("details")}</a>
							</td>
						</tr>)}
				</tbody>
			</table>
		</>)
}

export default LaunchpadTable;