import { FC, StrictMode } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CHAINS, PROJECT_ID } from './config';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import Whitelist from "./pages/Whitelist";
import Home from "./pages/Home";
import CheckWhitelist from './pages/CheckWhitelist';
import Vesting from './pages/Vesting';
import LaunchPad from './pages/LaunchPad';
import Goverance from './pages/Goverance';
import Committee from './pages/Committee';

const App: FC = () => {
	const chains = CHAINS;
	const projectId = PROJECT_ID;

	const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

	const wagmiConfig = createConfig({
		autoConnect: true,
		connectors: w3mConnectors({ projectId, version: 1, chains }),
		publicClient
	})
	const ethereumClient = new EthereumClient(wagmiConfig, chains);

	return (
		<BrowserRouter>
			<StrictMode>
				<WagmiConfig config={wagmiConfig}>
					<Routes>
						<Route index element={<Home />} />
						<Route path='/t/:tokenAddress' element={<Home />} />
						<Route path="/whitelist" element={<Whitelist />} />
						<Route path="/whitelist/:tokenAddress" element={<Whitelist />} />
						<Route path="/check-whitelist/:tokenAddress/:user" element={<CheckWhitelist />} />
						<Route path="/vesting/:tokenAddress" element={<Vesting />} />
						<Route path="/launchpad" element={<LaunchPad />} />
						<Route path="/launchpad/:tick" element={<LaunchPad />} />
						<Route path="/goverance" element={<Goverance />} />
						<Route path="/committee" element={<Committee />} />
					</Routes>
				</WagmiConfig>
				<Web3Modal
					projectId={projectId}
					ethereumClient={ethereumClient}
					themeVariables={{
						'--w3m-font-family': 'Roboto, sans-serif',
						'--w3m-background-color': '#61348a',
						'--w3m-accent-color': '#61348a',
					}}
				/>
			</StrictMode>
		</BrowserRouter>
	);
}

export default App;
