import { FC, useState } from "react";
import { ConversationPosData, TokenListProps } from "../utils/types";
import { ZERO_ADDRESS } from "../config";
import Tooltip from "./Tooltip";
import intl from "../utils/intl";
import SocialBox from "./SocialBox";
import TokenListItem from "./TokenListItem";
import { BN } from "../utils/common";
import useDeviceDetect from "../utils/useDeviceDetect";

const TokenList: FC<TokenListProps> = ({
	list,
	showType,
	scanUrl,
	symbol,
	version,
	conversationData,
	factory,
	socialContractAddress,
	currentAddress,
	rate,
	setMintItem,
	setShowIFODetails,
	checkTokenAddress,
}) => {
	const [conversation, setConversation] = useState({} as ConversationPosData);
	const [deployer, setDeployer] = useState("");
	
	const isMobile = useDeviceDetect();

	return (
		<>
			<table className="table table-zebra w-full">
				<thead>
					<tr>
						<th className="text-center">#</th>
						<th>{intl.get("tick")}</th>
						<th>{intl.get("contract")}</th>
						<th>{intl.get("hc-ahc")}
							{!isMobile && <Tooltip
								message={intl.get("tooltip-hc-ahc")}
							/>}
						</th>
						<th>{intl.get("tpm-lpm")}
							{!isMobile && <Tooltip
								message={intl.get("tooltip-tpm-lpm")}
							/>}
						</th>
						<th className="text-center">{intl.get("frozen-time")}</th>
						<th className="text-center">{intl.get("condition")}</th>
						<th className="text-center">{intl.get("token-vesting")}</th>
						<th className="text-center">{intl.get("funding-rate")}</th>
						<th className="text-center">{intl.get("progress")}</th>
						<th className="text-center">{intl.get("model-ifo")}</th>
						<th className="text-center">{intl.get("mint")}</th>
						<th className="text-center">{intl.get("conversation")}</th>
					</tr>
				</thead>
				<tbody>
					{list && list.map((item, key) => {
						if (showType === 1 && BN(item.totalRollups).eq(item.maxRollups)) return null;
						else if (showType === 2 && !BN(item.totalRollups).eq(item.maxRollups)) return null;
						else if (item.addr === ZERO_ADDRESS) return null;
						else return (
							<TokenListItem
								key={key}
								item={item}
								version={version}
								scanUrl={scanUrl}
								currentAddress={currentAddress}
								conversationData={conversationData}
								symbol={symbol}
								rate={rate}
								setMintItem={setMintItem}
								setShowIFODetails={setShowIFODetails}
								checkTokenAddress={checkTokenAddress}
								setConversation={setConversation}
								setDeployer={setDeployer}
							/>
						)}
					)}
				</tbody>
			</table>
			<SocialBox
				factory={factory}
				currentAddress={currentAddress}
				socialContractAddress={socialContractAddress}
				conversation={conversation}
				deployer={deployer}
			/>
		</>)
}

export default TokenList;