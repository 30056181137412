import Logo from './Logo';
import { BsGithub, BsTelegram, BsTwitter } from 'react-icons/bs';
import { RxAccessibility, RxDiscordLogo, RxTwitterLogo } from "react-icons/rx";
import { BsFillLightningChargeFill } from "react-icons/bs";

const Footer = () => {
	return (
		<footer className="md:fixed bottom-0 footer p-5 md:px-10 md:py-5 bg-neutral text-neutral-content">
			<div className="flex justify-between">
				<div className="mt-2"><Logo /></div>
				<select
					className='select text-sm w-fit bg-neutral'
					defaultValue={localStorage.getItem("ferc_lang") ? localStorage.getItem("ferc_lang") as string : "en"}
					onChange={(e) => {
						localStorage.setItem("ferc_lang", e.currentTarget.value);
						window.location.reload();
					}}
				>
					<option value="en">🇺🇸 English</option>
					<option value="ja">🇯🇵 日本語</option>
					<option value="ru">🇷🇺 русский</option>
					<option value="zh">🇨🇳 中文</option>
					<option value="de">🇩🇪 Deutsch</option>
					<option value="th">🇹🇭 ภาษาไทย</option>
					<option value="es">🇪🇸 España</option>
					<option value="fr">🇫🇷 Français</option>
					<option value="ko">🇰🇷 한국어</option>
					<option value="vi">🇻🇳 Tiếng Việt</option>
				</select>
				{/* <label className="cursor-pointer label tracking-wide mt-2">
					<a href="https://erc20.cash" target="_blank" rel="noreferrer">V1&V2</a>
				</label> */}

			</div>

			<div className="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
				<a className="fill-gray-300 hover:fill-gray-300" href="https://discord.gg/ggDAAu6zKe" target="_blank" rel="noopener noreferrer">
					<RxDiscordLogo className='text-2xl mr-1' />
				</a>
				<a className="fill-gray-300 hover:fill-gray-300" href="https://twitter.com/Ferc20Global" target="_blank" rel="noopener noreferrer">
					<BsTwitter className='text-2xl mr-1' />
				</a>
				<a className="fill-gray-300 hover:fill-gray-300" href="https://t.me/ferc20_en" target="_blank" rel="noopener noreferrer">
					<BsTelegram className='text-2xl mr-1' />
				</a>
				<a href="https://gist.github.com/jackygu2006/39dbc920703bcc46c8aa0fb2a071f720" target="_blank" rel="noreferrer">
					<BsGithub className='mr-1 text-2xl' />
				</a>
				<a href="https://snapshot.org/#/jackygu.eth" target="_blank" rel="noreferrer">
					<BsFillLightningChargeFill className='mr-1 text-2xl' />
				</a>

				<p className="mt-1">Copyright © 2023 - All right reserved</p>
			</div>
		</footer>
	)
}

export default Footer;