import { FC, useEffect, useState } from "react";
import { PaginationProps } from "../utils/types";

const Pagination:FC<PaginationProps> = ({
	totalInscriptions,
	pageSize,
	onPageClick
}) => {
	const [pageTags, setPageTags] =  useState([] as Array<number>);
	const [menuTags, setMenuTags] = useState([] as Array<number>);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		const pages = Math.floor((totalInscriptions - 1) / pageSize) + 1;
		if(pages === 4) setPageTags([1, 2, 3, 4]);
		else if(pages < 4) {
			let arr = [];
			for(let i = 0; i < pages; i++) arr.push(i + 1);
			setPageTags(arr);
		} else {
			let arr = [1, 2, 0]; // 0- show ...
			let menuArr = [];
			for(let i = 3; i < pages - 1; i++) menuArr.push(i);
			for(let i = pages - 2; i < pages; i++) arr.push(i + 1);
			setPageTags(arr);
			setMenuTags(menuArr);
		}
	}, [pageSize, totalInscriptions]);

	return(
	<>
		<div className="btn-group">
			{pageTags.map((v, i) => {
				if(v === 0) return <div key={i} className="dropdown dropdown-top">
					<label tabIndex={0} className="btn">...</label>
					<ul tabIndex={0} className="dropdown-content menu p-1 shadow bg-base-100 rounded-md">
						{menuTags.map((v, j) => <li key={j}>
							<a onClick={() => {setCurrentPage(v); onPageClick(v);}}>{v}</a>
						</li>)}
					</ul>
				</div>
				else return <button key={i} className={`btn ${v === currentPage ? 'btn-active' : ''}`} onClick={() => {
					setCurrentPage(v); 
					onPageClick(v);
				}}>{v}</button>
			})}
		</div>

	</>)
}

export default Pagination;