import { useState } from 'react';
import HomeMain from "../components/HomeMain";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';

function Home() {
	//TODO baja
	// const [version, setVersion] = useState("");
	const [version, setVersion] = useState(
		localStorage.getItem("ferc_version") ? localStorage.getItem("ferc_version") as string : "v31"
	);
	const params = useParams();

	return (
		<div>
			<HomeMain version={version} tokenAddress={params.tokenAddress} />
			<Header updateVersion={(version) => {
				setVersion(version)
			}} />
			<Footer />
		</div>
	);
}

export default Home;
