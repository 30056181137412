export const candidatesList = [
	{
		"twitterLogo": "https://pbs.twimg.com/profile_images/1565388307650846722/qkAKiAEA.jpg",
		"twitterName": "@hongbing55",
		"tgAccount": "@hongbing55",
		"discordAccount": "@hongbing55",
		"address": "0x1763b0D89C0D8B885045000C6142A081cF0637a1",
		"memo": "",
		"balance": 0

	},
	{
		"twitterLogo": "https://pbs.twimg.com/profile_images/1605046027115171841/Qc1sYNLj.jpg",
		"twitterName": "@maozemin",
		"tgAccount": "@MAO MARCUS",
		"discordAccount": "",
		"address": "0x362b638B43F767350ac0A01b67Baad825C108f3d",
		"memo": "(mzm.eth)",
		"balance": 0
	},
	{
		"twitterLogo": "https://pbs.twimg.com/profile_images/1577172788376666112/I2rEGSUB.png",
		"twitterName": "@JACKSON30139081",
		"tgAccount": "@Jacksong1106",
		"discordAccount": "无",
		"address": "0x0b2E47bF0180155B07ca65154D81E70f697a2d31",
		"memo": "",
		"balance": 0
	},
	{
		"twitterLogo": "https://pbs.twimg.com/profile_images/1631687594005389313/dZ49H66c.jpg",
		"twitterName": "@00xx00eth",
		"tgAccount": "@Louis_Liu2049",
		"discordAccount": "Metalee🚀#1181",
		"address": "0x0910d9de5fd668e4af1AF0c55C73139dAC5DfAb6",
		"memo": "",
		"balance": 0
	},
	{
		"twitterLogo": "https://pbs.twimg.com/profile_images/1701982147832324096/JGonOlrx.jpg",
		"twitterName": "@huajie_meta",
		"tgAccount": "@sunshinefuture",
		"discordAccount": "sunshineeee",
		"address": "0x79444E8e592993Facf111869E50214769A1F482B",
		"memo": "",
		"balance": 0
	},
	{
		"twitterLogo": "https://pbs.twimg.com/profile_images/1486711093536116736/7-iesRGl.jpg",
		"twitterName": "@tomhctoBTC",
		"tgAccount": "@tomhcto",
		"discordAccount": "littlenew",
		"address": "0xBC717818951cbC4ef9155a6018488a9Ef14061a8",
		"memo": "",
		"balance": 0
	},
	{
		"twitterLogo": "https://pbs.twimg.com/profile_images/1402536398532628483/33tQdOWW.png",
		"twitterName": "@BajaSX",
		"tgAccount": "@BajaX",
		"discordAccount": "@Bajaxx",
		"address": "0x1f9Df16d51B98D95325e6F0969bf1e02008E288B",
		"memo": "",
		"balance": 0
	}
]
