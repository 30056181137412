import { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CommitteeMain from '../components/CommitteeMain';

function Committee() {
	const [version, setVersion] = useState(
		localStorage.getItem("ferc_version") ? localStorage.getItem("ferc_version") as string : "v31"
	);

	return (
		<div>
			<CommitteeMain version={version} />
			<Header updateVersion={() => { }} />
			<Footer />
		</div>
	);
}

export default Committee;
