import { FC } from "react";
import { ConversationPosData, InscriptionData, TokenItemProps } from "../utils/types";
import { BN, formatAddress, formatDecimal2, formatEther, secondsToHMS, toNumber, toString } from "../utils/common";
import LogoItem from "./LogoItem";
import { FiExternalLink } from "react-icons/fi";
import { BASE_URL, CONVERSATION_SLOT, FORMAT_DECIMAL2, ZERO_ADDRESS } from "../config";
import intl from "../utils/intl";
import Tooltip from "./Tooltip";
import LinkTo from "./LinkTo";
import { BsShare } from "react-icons/bs";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import useDeviceDetect from "../utils/useDeviceDetect";
import { formatUnits } from "ethers/lib/utils";

const TokenGridItem: FC<TokenItemProps> = ({
	item,
	version,
	scanUrl,
	currentAddress,
	conversationData,
	symbol,
	rate,
	setMintItem,
	setShowIFODetails,
	checkTokenAddress,
	setConversation,
	setDeployer,
}) => {
	const isMobile = useDeviceDetect();

	const mintPercent = (item: InscriptionData) => toNumber(item.totalRollups) / toNumber(item.maxRollups) * 100;
	const toLiquidityPerMint = (item: InscriptionData) => formatEther(BN(item.limitPerMint).div(BN(10000).sub(item.liquidityTokenPercent)).mul(item.liquidityTokenPercent));
	const getConversationBgColor = (id: number) => id === 0 ? "primary" : id === 1 ? "secondary" : "accent";
	const getSocialDataByTickPos = (addr: string, tick: string, pos: number) => {
		return {
			addr,
			tick,
			pos,
			name: conversationData.get(addr)?.names[pos],
			logo: conversationData.get(addr)?.logos[pos],
			url: conversationData.get(addr)?.urls[pos],
			rate: conversationData.get(addr)?.rates[pos],
			seller: conversationData.get(addr)?.sellers[pos],
			lastUpdate: conversationData.get(addr)?.lastUpdates[pos],
		} as ConversationPosData;
	}

	const onSlotClick = (e: any, index: number) => {
		const socialData: ConversationPosData = getSocialDataByTickPos(item.addr, item.tick, index);
		if (currentAddress !== item.deployer && socialData.name === "") {
			toast.error("You are not deployer and can not set this slot");
			return;
		} else {
			e.currentTarget.htmlFor = "socialBoxModal";
			setConversation(socialData);
			setDeployer(item.deployer);
		}
	}

	return (
		<>
			<div className="card bg-base-100 shadow-xl m-3">
				<div className="card-body">
					{/* Header */}
					<div className="flex justify-between">
						<div>
							<div className="w-20 h-20">
								<LogoItem cid={item.logoUrl} isVoted={item.isVoted} isGrid={true} />
							</div>
							<div className="flex justify-center">
								<div className="mt-6 ml-2 text-sm font-light ">
									{rate.get(item.addr) !== undefined && '+' + rate.get(item.addr)}
								</div>
								<div className="btn btn-circle btn-base-100 btn-md btn-ghost mt-2">
									<CopyToClipboard onCopy={() => toast.success("Copied")} text={`${BASE_URL}t/${item.addr}`}>
										<BsShare />
									</CopyToClipboard>
								</div>
							</div>
						</div>
						<div className="">
							<div className="font-bold text-lg text-right mb-1">{item.tick}</div>
							<div className="text-sm text-right mb-1">{item.name}</div>
							<div className="text-sm text-right flex mb-2">
								{item && formatAddress(item.addr)}
								<LinkTo
									copyText={item.addr}
									url={`${scanUrl}/token/${item.addr}`}
								/>
							</div>
							{/* Social list */}
							<div className="text-right">
								<div className="avatar-group -space-x-4 justify-end">
									{Array.from({ length: CONVERSATION_SLOT }, (_, index) => index).map((index) => {
										const name = conversationData.get(item.addr)?.names[index];
										if (name !== "" && name !== undefined) {

											const logo = conversationData.get(item.addr)?.logos[index];

											return (
												<label
													key={index}
													className={`avatar bg-${getConversationBgColor(index)} placeholder`}
													onClick={(e) => onSlotClick(e, index)}
												>
													{logo !== "" ?
														<div className="w-10 h-10 cursor-pointer">
															<LogoItem cid={logo as string} isGrid={true} />
														</div>
														:
														<div className="w-10 font-bold cursor-pointer text-neutral-content">
															<span>{index + 1}</span>
														</div>}
												</label>)
										} else {
											return (
												<label
													key={index}
													className={`avatar bg-${getConversationBgColor(index)} placeholder`}
													onClick={(e) => onSlotClick(e, index)}
												>
													<div className="w-10 font-bold cursor-pointer text-neutral-content">
														<span>{intl.get("empty")}</span>
													</div>
												</label>)
										}
									}
									)}
								</div>
							</div>
						</div>
					</div>

					{/* Details */}
					<div className="flex flex-col w-full border-opacity-50">
						<div className="divider text-opacity-50 text-sm">Details</div>
					</div>

					<div className="md:min-h-[220px]">
						{/* Line#1 */}
						<div className="flex justify-between text-sm">
							<div className="text-base-content mb-2">{intl.get("hc-ahc")}
								{!isMobile && <Tooltip
									message={intl.get("tooltip-hc-ahc")}
								/>}
							</div>
							<div className="text-base-content text-right mb-2">
								{formatDecimal2(parseFloat(formatEther(item.cap)), FORMAT_DECIMAL2) + " / "
									+ formatDecimal2(parseFloat(formatEther(item.totalMintable)), FORMAT_DECIMAL2)}
							</div>
						</div>

						{/* Line#2 */}
						<div className="flex justify-between text-sm">
							<div className="text-base-content mb-2">{intl.get("tpm-lpm")}
								{!isMobile && <Tooltip
									message={intl.get("tooltip-tpm-lpm")}
								/>}
							</div>
							<div className="text-right mb-2">
								{formatDecimal2(parseFloat(formatEther(item.limitPerMint)), FORMAT_DECIMAL2) + " / "
									+ formatDecimal2(parseFloat(toLiquidityPerMint(item)), FORMAT_DECIMAL2)}
							</div>
						</div>

						{/* Line#3 */}
						<div className="flex justify-between text-sm">
							<div className="text-base-content mb-2">{intl.get("frozen-time")}</div>
							<div className="text-right mb-2">
								{secondsToHMS(toNumber(item.freezeTime)) + " / "
									+ (toNumber(item.freezeTime) === 0 ? item.maxMintSize.toString() + " mints" : "1 mint")}
							</div>
						</div>

						{/* Line#4 */}
						<div className="flex justify-between text-sm">
							<div className="text-base-content mb-2">{intl.get("condition")}</div>
							<div className="text-sm text-right mb-2">
								{item.isWhitelist ?
									<div className="flex">
										{intl.get("whitelist")}
										<a href={`/check-whitelist/${item.addr}/${currentAddress}`} target='_blank' rel="noreferrer">
											<FiExternalLink className='ml-2 mt-1 cursor-pointer' />
										</a>
									</div>
									:
									item.onlyContractAddress !== ZERO_ADDRESS ?
										<div className="text-right">
											<div>
												{formatDecimal2(parseFloat(item.decimals && item.decimals.toString() !== "0" ? formatUnits(item.onlyMinQuantity, item.decimals) : item.onlyMinQuantity.toString()), FORMAT_DECIMAL2) + " " + item.onlyTokenName}
											</div>
											<div className='flex text-gray-500'>
												{formatAddress(item.onlyContractAddress)}
												<LinkTo
													copyText={item.onlyContractAddress}
													url={`${scanUrl}/token/${item.onlyContractAddress}#balances`}
												/>
											</div>
										</div>
										:
										item.customizedConditionContractAddress !== ZERO_ADDRESS ?
											<div className="text-right">
												<div>
													{intl.get("condition-customize")}
												</div>
												<div className='flex text-gray-500'>
													{formatAddress(item.customizedConditionContractAddress)}
													<LinkTo
														copyText={item.customizedConditionContractAddress}
														url={`${scanUrl}/address/${item.customizedConditionContractAddress}`}
													/>
												</div>
											</div>
											:
											intl.get("condition-no")
								}
							</div>
						</div>

						{/* Line#5 */}
						<div className="flex justify-between text-sm">
							<div className="text-base-content mb-2">{intl.get("token-vesting")}</div>
							<div className="text-right mb-2">
								{item.isVesting ?
									<div>
										<div className='text-sm justify-end flex'>
											{intl.get("token-allocation-standard-vesting")}
											<a href={`${BASE_URL}/vesting/${item.addr}`} target='_blank' rel="noreferrer">
												<FiExternalLink className='ml-2 mt-1 cursor-pointer' />
											</a>
										</div>
										<div className='flex text-gray-500 text-sm justify-end'>
											{formatAddress(item.customizedVestingContractAddress)}
											<LinkTo
												copyText={item.customizedVestingContractAddress}
												url={`${scanUrl}/address/${item.customizedVestingContractAddress}`}
											/>
										</div>
									</div>
									:
									item.customizedVestingContractAddress === ZERO_ADDRESS ?
										<div className='text-sm'>
											{intl.get("token-allocation-no-vesting")}
										</div> :
										<div>
											<div>
												{intl.get("token-vesting-customize")}
											</div>
											<div className='flex text-gray-500'>
												{formatAddress(item.customizedVestingContractAddress)}
												<LinkTo
													copyText={item.customizedVestingContractAddress}
													url={`${scanUrl}/address/${item.customizedVestingContractAddress}`}
												/>
											</div>
										</div>}
							</div>
						</div>

						{/* Line#6 */}
						<div className='flex justify-between text-sm'>
							<div className="text-base-content mb-2">{intl.get("funding-rate")}</div>
							<div className="text-right mb-2">
								{BN(item.crowdFundingRate).eq(0) ? intl.get("free") : formatDecimal2(parseFloat(formatEther(item.crowdFundingRate)), FORMAT_DECIMAL2) + " " + symbol}
							</div>
						</div>
					</div>

					{/* Progress */}
					{/* <div className="flex flex-col w-full border-opacity-50">
					<div className="divider text-opacity-50 text-sm">Progress</div>
				</div> */}

					<div>
						<div className='bg-primary-content h-2 rounded-full'>
							<progress className="progress progress-secondary float-left" value={mintPercent(item).toString()} max="100"></progress>
						</div>
						<div className="text-sm text-center float-left mt-1">
							{mintPercent(item).toFixed(2).toString() + "%"}
						</div>
						<div className="text-sm text-center text-gray-500 float-right mt-1">
							{item.totalRollups + "/" + item.maxRollups}
						</div>
					</div>

					{/* Buttons */}
					<div className="card-actions justify-center mt-2">
						{item.isIFOMode &&
							<label
								htmlFor="ifoModal"
								className="btn btn-primary btn-md w-24"
								onClick={() => {
									setShowIFODetails(true);
									setMintItem(item);
								}}
							>
								{intl.get("ifo-management")}
							</label>}

						{mintPercent(item) < 100 &&
							<label
								htmlFor="mintModal"
								className="btn btn-secondary btn-md w-24"
								onClick={() => {
									setMintItem(item);
									checkTokenAddress(item.addr, 1)
								}}
							>
								{intl.get("mint")}
							</label>}

					</div>
				</div>
			</div>

			{/* <tr key={key}>
			<td className="text-center">
				{toString(item.inscriptionId)}
			</td>
		</tr> */}
		</>)
}

export default TokenGridItem;
