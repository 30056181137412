import { FC, useEffect, useState } from "react";
import { RateProps } from "../utils/types";
import { BN } from "../utils/common";

const Rate:FC<RateProps> = ({
	topVotes,
	totalBallots
}) => {
	const [voteRatio, setVoteRatio] = useState(0);

	useEffect(() => {
		// console.log("totalBallots", totalBallots.toString(), "top vote", topVotes.toString());
		const _ratio = BN(topVotes).mul(10000).div(totalBallots).toNumber() / 100;
		setVoteRatio(totalBallots === undefined || totalBallots.isZero() || topVotes === undefined ? 0 : _ratio);
	},[]);

	return(
		<>
		<div className="rating rating-half rating-md">
			<input type="radio" name="rating-2" className="mask mask-star-2 bg-primary mask-half-1" checked={voteRatio >= 0 && voteRatio < 5} onChange={() => {}}/>
			<input type="radio" name="rating-2" className="mask mask-star-2 bg-primary mask-half-2" checked={voteRatio >= 5 && voteRatio < 10} onChange={() => {}}/>
			<input type="radio" name="rating-2" className="mask mask-star-2 bg-primary mask-half-1" checked={voteRatio >= 10 && voteRatio < 20} onChange={() => {}}/>
			<input type="radio" name="rating-2" className="mask mask-star-2 bg-primary mask-half-2" checked={voteRatio >= 20 && voteRatio < 30} onChange={() => {}}/>
			<input type="radio" name="rating-2" className="mask mask-star-2 bg-primary mask-half-1" checked={voteRatio >= 30 && voteRatio < 40} onChange={() => {}}/>
			<input type="radio" name="rating-2" className="mask mask-star-2 bg-primary mask-half-2" checked={voteRatio >= 40 && voteRatio < 50} onChange={() => {}}/>
			<input type="radio" name="rating-2" className="mask mask-star-2 bg-primary mask-half-1" checked={voteRatio >= 50 && voteRatio < 60} onChange={() => {}}/>
			<input type="radio" name="rating-2" className="mask mask-star-2 bg-primary mask-half-2" checked={voteRatio >= 60 && voteRatio < 70} onChange={() => {}}/>
			<input type="radio" name="rating-2" className="mask mask-star-2 bg-primary mask-half-1" checked={voteRatio >= 70 && voteRatio < 80} onChange={() => {}}/>
			<input type="radio" name="rating-2" className="mask mask-star-2 bg-primary mask-half-2" checked={voteRatio >= 80 && voteRatio <= 100} onChange={() => {}}/>
		</div>
		<div className="text-xs">{voteRatio}</div>
		</>
	)
}

export default Rate;