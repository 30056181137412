import { useState, useEffect, FC } from "react";
import { useAccount, useNetwork } from 'wagmi';
import { GovernanceMainProps, NetworkData } from "../utils/types";
import { formatAddress, formatEther, getContractAddressByVersion, getNetworkData } from "../utils/common";
import intl from "../utils/intl";
import InfoBar from "./InfoBar";
import useDeviceDetect from "../utils/useDeviceDetect";
import FactoryContractABI from "../abi/InscriptionFactory.json";
import VoteForLaunchABI from "../abi/VoteForLaunch.json";
import ConversationABI from "../abi/Conversation.json";
import { readContracts } from "@wagmi/core";

const GoveranceMain: FC<GovernanceMainProps> = ({
	version
}) => {
	const [loading, setLoading] = useState(false);
	const [network, setNetwork] = useState({} as NetworkData);
	const [minTickSize, setMinTickSize] = useState(0);
	const [maxTickSize, setMaxTickSize] = useState(0);
	const [nameSize, setNameSize] = useState(0);
	const [baseTip, setBaseTip] = useState("");
	const [newVoteDeposit, setNewVoteDeposit] = useState("");
	const [voteToken, setVoteToken] = useState("");
	const [inscriptionFactory, setInscriptionFactory] = useState("");
	const [totalBallots, setTotalBallots] = useState("");
	const [undeployedExpire, setUndeployedExpire] = useState(0);
	const [passedRatio, setPassedRatio] = useState("");
	const [maxVotingDays, setMaxVotingDays] = useState(0);
	const [prefixUrls, setPrefixUrls] = useState("");
	const [maxSlots, setMaxSlots] = useState(3);

	const isMobile = useDeviceDetect();

	const { chain } = useNetwork();
	const { address } = useAccount();

	useEffect(() => {
		if (chain) {
			const _network = getNetworkData(chain, version);
			setNetwork(_network);
			load(_network);
		}
	}, [chain, version]);

	const load = async (_network: NetworkData) => {
		setLoading(true);
		const factoryContract = {
			address: _network.contractAddress as any,
			abi: FactoryContractABI as any,
			args: []
		};
		const conversationContract = {
			address: _network.socialContractAddress as any,
			abi: ConversationABI as any,
			args: []
		};
		const voteForLaunchContract = {
			address: _network.voteForLaunchpad as any,
			abi: VoteForLaunchABI as any,
			args: []
		};

		readContracts({
			contracts: [{
				...factoryContract,
				functionName: "minTickSize",
			}, {
				...factoryContract,
				functionName: "maxTickSize",
			}, {
				...factoryContract,
				functionName: "maxNameSize",
			}, {
				...factoryContract,
				functionName: "baseFee",
			}]
		}).then((factoryResult: Array<any>) => {
			setMinTickSize(factoryResult[0].result);
			setMaxTickSize(factoryResult[1].result);
			setNameSize(factoryResult[2].result);
			setBaseTip(formatEther(factoryResult[3].result));
		})

		readContracts({
			contracts: [{
				...voteForLaunchContract,
				functionName: "newVoteDeposit", // wei
			}, {
				...voteForLaunchContract,
				functionName: "voteToken", // address
			}, {
				...voteForLaunchContract,
				functionName: "inscriptionFactory", // address
			}, {
				...voteForLaunchContract,
				functionName: "totalBallots", // wei
			}, {
				...voteForLaunchContract,
				functionName: "UNDEPLOYED_EXPIRE", // seconds
			}, {
				...voteForLaunchContract,
				functionName: "PASSED_RATIO", // 3000 -> 30%
			}, {
				...voteForLaunchContract,
				functionName: "MAX_VOTING_DAYS", /// seconds
			}]
		}).then((voteResult: Array<any>) => {
			setNewVoteDeposit(formatEther(voteResult[0].result));
			setVoteToken(voteResult[1].result);
			setInscriptionFactory(voteResult[2].result);
			setTotalBallots(formatEther(voteResult[3].result));
			setUndeployedExpire(voteResult[4].result / (24 * 3600));
			setPassedRatio(voteResult[5].result / 100 + "%");
			setMaxVotingDays(voteResult[6].result / (24 * 3600));
		});

		// readContracts({
		// 	contracts: [{
		// 		...conversationContract,
		// 		functionName: "prefixUrls",
		// 	}, {
		// 		...conversationContract,
		// 		functionName: "maxPosition",
		// 	}]
		// }).then((conversationResult:Array<any>) => {
		// 	console.log("===>", conversationResult[0].result);
		// 	setPrefixUrls(conversationResult[0].result);
		// 	setMaxSlots(conversationResult[1].result);
		// })

		setLoading(false);
	}

	const getContractAddress = (_address: string) => isMobile ? formatAddress(_address) : _address;

	return (
		<div>
			<div className="hero pt-[120px] pb-5">
				<div className="hero-content text-center">
					<div className="max-w-md">
						<h1 className="text-4xl font-bold">{intl.get("goverance")} 🐸</h1>
						<p className="py-6">{intl.get("goverance-introduction")}</p>
					</div>
				</div>
			</div>

			{!loading &&
				<div className="mb-24 bg-base-200 text-center md:w-[50%] md:ml-[25%] md:p-12 w-[100%] ml-[0%] p-3 rounded-lg">
					{/* Chain Id */}
					<InfoBar title={"Chain ID"} value={chain ? chain.id.toString() : ""} />

					{/* Chain name */}
					<InfoBar title={"Chain name"} value={chain ? chain.name : ""} />

					{/* current address */}
					<InfoBar title={"My address"} value={isMobile ? formatAddress(address as string) : address as string} />

					{/* Factory address */}
					<InfoBar title={"Inscription Factory"} value={getContractAddress(network.contractAddress)} />

					{/* Vote for launch contract address */}
					<InfoBar title={"Vote for launchpad"} value={getContractAddress(network.voteForLaunchpad)} />

					{/* Social contract address */}
					<InfoBar title={"Social slot"} value={getContractAddress(network.socialContractAddress)} />

					{/* UniswapV3 factory contract address */}
					<InfoBar title={"UniswapV3 Factory"} value={getContractAddress(network.uniswapV3Factory)} />

					{/* UniswapV3 NFT Position manager contract address */}
					<InfoBar title={"UniswapV3 NFT Position Manager"} value={getContractAddress(network.nonfungiblePositionManager)} />

					{/* FERC token address */}
					<InfoBar title={"$ferc token"} value={getContractAddress(network.fercToken)} />

					{/* Wrapped token address  */}
					<InfoBar title={"Wrapped coin"} value={getContractAddress(network.wethAddress)} />

					{/* Tick size */}
					<InfoBar title={"Tike size"} value={minTickSize + "-" + maxTickSize} />

					<InfoBar title={"Name size"} value={nameSize.toString()} />

					<InfoBar title={"Base tip"} value={baseTip} />

					<InfoBar title={"Star new vote deposit"} value={newVoteDeposit} />

					<InfoBar title={"Vote token($ferc)"} value={getContractAddress(voteToken)} />

					<InfoBar title={"Is right factory"} value={inscriptionFactory === network.contractAddress ? "yes" : "no"} />

					<InfoBar title={"Total ballots"} value={totalBallots} />

					<InfoBar title={"Undeployed expire days"} value={undeployedExpire.toString()} />

					<InfoBar title={"Passed ratio"} value={passedRatio} />

					<InfoBar title={"Max voting days"} value={maxVotingDays.toString()} />

					{/* <InfoBar title={"Prefix Urls"} value={prefixUrls} />

					<InfoBar title={"Max slots"} value={maxSlots.toString()} /> */}













				</div>}
		</div>
	)
}

export default GoveranceMain;