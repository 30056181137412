import { FC, useEffect, useState } from "react";
import { NetworkData, WhitelistMainProps } from "../utils/types";
import intl from "../utils/intl";
import InscriptionFactoryABI from '../abi/InscriptionFactory.json';
import WhitelistABI from "../abi/Whitelist.json";
import toast, { Toaster } from "react-hot-toast";
import { useNetwork } from "wagmi";
import { ZERO_ADDRESS, getNetwork } from "../config";
import { readContract, writeContract, waitForTransaction } from "@wagmi/core";
import { formatAddress, getContractAddressByVersion, getNetworkData, toNumber } from "../utils/common";
import Tooltip from "./Tooltip";
import { useParams } from "react-router-dom";
import LinkTo from "./LinkTo";
import useDeviceDetect from "../utils/useDeviceDetect";

const WhitelistMain: FC<WhitelistMainProps> = ({
	version
}) => {
	const params = useParams();
	const isMobile = useDeviceDetect();

	const [loadingAdd, setLoadingAdd] = useState(false);
	const [loadingRemove, setLoadingRemove] = useState(false);
	const [loadingBatchAdd, setLoadingBatchAdd] = useState(false);
	const [loadingBatchRemove, setLoadingBatchRemove] = useState(false);
	const [loadingSetOperator, setLoadingSetOperator] = useState(false);
	const [loadingGetOperator, setLoadingGetOperator] = useState(false);

	const [tokenAddress, setTokenAddress] = useState(params.tokenAddress === undefined ? "" : params.tokenAddress);
	const [address, setAddress] = useState("");
	const [addresses, setAddresses] = useState("");
	const [operator, setOperator] = useState("");
	const [network, setNetwork] = useState({} as NetworkData);
	const { chain } = useNetwork();

	useEffect(() => {
		if (chain) {
			setNetwork(getNetworkData(chain, version));
		}
	}, [chain, version]);

	const set = async (_status: boolean) => {
		// If not set, set now
		if (_status) setLoadingAdd(true);
		else setLoadingRemove(true);

		const status = await checkStatus();
		if (!status.result) {
			toast.error(status.message as string);
			if (_status) setLoadingAdd(false);
			else setLoadingRemove(false);
			return;
		} else {
			if ((_status && status.status) || (!_status && !status.status)) {
				toast.error(`Address is ${!_status ? 'not' : ''} in the whitelist already!`);
				if (_status) setLoadingAdd(false);
				else setLoadingRemove(false);
				return;
			} else {
				// Add to list
				await setStatus(_status);
			}
		}
	}

	const setStatus = async (status: boolean) => {
		try {
			const { hash } = await writeContract({
				address: network.whitelist as any,
				abi: WhitelistABI,
				functionName: "set",
				args: [tokenAddress.trim(), address, status]
			})

			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success(`${status ? 'Add whitelist' : 'Remove whitelist'} successfully`);
				} else {
					toast.error(`${status ? 'Add whitelist' : 'Remove whitelist'} Error`);
				}
				if (status) setLoadingAdd(false);
				else setLoadingRemove(false);
			})

		} catch (err) {
			toast.error("Error: " + (err as any).shortMessage);
			if (status) setLoadingAdd(false);
			else setLoadingRemove(false);
		}
	}

	const checkStatus = async () => {
		if (address === "") return { result: false, message: intl.get("address-not-found") };
		// Check token address is existed
		const inscriptionId = await readContract({
			address: network.contractAddress as any,
			abi: InscriptionFactoryABI,
			functionName: "getIncriptionIdByAddress",
			args: [tokenAddress.trim()]
		});

		if (toNumber(inscriptionId) === 0) {
			return {
				result: false,
				message: intl.get("tick-not-exists")
			}
		}

		const status = await readContract({
			address: network.whitelist as any,
			abi: WhitelistABI,
			functionName: "getStatus",
			args: [tokenAddress.trim(), address]
		});
		return { result: true, status }
	}

	const batchSet = async (status: boolean) => {
		if (status) setLoadingBatchAdd(true);
		else setLoadingBatchRemove(true);

		const _addresses = addresses.trim().split('\n').map(line => line.trim());
		if (_addresses.length > 10) {
			toast.error(intl.get("more-than-limitation"));
			return;
		}

		// Check token address is existed
		const inscriptionId = await readContract({
			address: network.contractAddress as any,
			abi: InscriptionFactoryABI,
			functionName: "getIncriptionIdByAddress",
			args: [tokenAddress.trim().toLowerCase()]
		});

		if (toNumber(inscriptionId) === 0) {
			toast.error(intl.get("tick-not-exists"));
			if (status) setLoadingBatchAdd(false);
			else setLoadingBatchRemove(false);
			return;
		}

		batchSetStatus(_addresses, status);
	}

	const batchSetStatus = async (_addresses: Array<string>, status: boolean) => {
		try {
			const { hash } = await writeContract({
				address: network.whitelist as any,
				abi: WhitelistABI,
				functionName: "batchSet",
				args: [tokenAddress.trim(), _addresses, status]
			})

			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success(`${status ? 'Batch add whitelist' : 'Batch remove whitelist'} successfully`);
				} else {
					toast.error(`${status ? 'Batch add whitelist' : 'Batch remove whitelist'} Error`);
				}
				if (status) setLoadingBatchAdd(false);
				else setLoadingBatchRemove(false);
			})
		} catch (err) {
			toast.error("Error: " + (err as any).shortMessage);
			if (status) setLoadingBatchAdd(false);
			else setLoadingBatchRemove(false);
		}
	}

	const getOperatorAddress = async () => {
		// Check token address is existed
		setLoadingGetOperator(true);
		try {
			const inscription = await readContract({
				address: network.contractAddress as any,
				abi: InscriptionFactoryABI,
				functionName: "getIncriptionByAddress",
				args: [tokenAddress.trim()]
			}) as any;

			const token: any = inscription[0] as any;
			if (toNumber(token.inscriptionId) === 0) {
				toast.error(intl.get("tick-not-exists"));
				setLoadingGetOperator(false);
				return;
			}

			const _operator = await readContract({
				address: network.whitelist as any,
				abi: WhitelistABI,
				functionName: "operator",
				args: [token.addr], // token address
			}) as string;

			setOperator(_operator);
			setLoadingGetOperator(false);
		} catch (err) {
			toast.error("Error: " + (err as any).shortMessage);
			setLoadingGetOperator(false);
		}
	}

	const setOperatorAddress = async () => {
		if (operator === ZERO_ADDRESS) {
			toast.error(intl.get("address-not-found"));
			return;
		}

		setLoadingSetOperator(true);
		try {
			const inscription = await readContract({
				address: network.contractAddress as any,
				abi: InscriptionFactoryABI,
				functionName: "getIncriptionByAddress",
				args: [tokenAddress.trim()]
			}) as any;

			const token: any = inscription[0] as any;
			if (toNumber(token.inscriptionId) === 0) {
				toast.error(intl.get("tick-not-exists"));
				setLoadingSetOperator(false);
				return;
			}

			const { hash } = await writeContract({
				address: network.whitelist as any,
				abi: WhitelistABI,
				functionName: "setOperator",
				args: [tokenAddress.trim(), operator], // token address
			});

			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success(`Update operator successfully`);
					setLoadingSetOperator(false);
				} else {
					toast.error(`Update operator Error`);
					setLoadingSetOperator(false);
				}
			})
		} catch (err) {
			toast.error("Error: " + (err as any).shortMessage);
			setLoadingSetOperator(false);
		}

	}

	return (
		<div>
			<div className="hero pt-[120px] pb-5">
				<div className="hero-content text-center">
					<div className="max-w-md">
						<h1 className="text-4xl font-bold">{intl.get("whitelist-setting")} 🐸</h1>
						<p className="py-6">{intl.get("whitelist-introduction")}</p>
						<div className="mb-1">
							<p className="flex justify-center">
								{intl.get("whitelist-contract") + formatAddress(network.whitelist)}
								<LinkTo
									copyText={network.whitelist}
									url={`${network.scanUrl}/address/${network.whitelist}`}
								/>
							</p>

							<p className="flex justify-center">
							</p>
						</div>
					</div>
				</div>
			</div>

			{/* Set one by one */}
			<div className="bg-base-200 text-center md:w-[50%] md:ml-[25%] md:p-12 w-[100%] ml-[0%] p-3 rounded-lg">
				{/* Tick */}
				<div className="input-group flex justify-center mb-3">
					<span className="w-[40%] text-left">{intl.get("tick2")}</span>
					<input
						type="text"
						value={isMobile ? formatAddress(tokenAddress) : tokenAddress}
						placeholder="Token address"
						className="input input-bordered w-[60%]"
						onClick={(e) => e.currentTarget.select()}
						onChange={(e) => setTokenAddress(e.target.value)}
					/>
				</div>

				{/* Address */}
				<div className="input-group flex justify-center mb-3">
					<span className="w-[40%] text-left">{intl.get("participant-address")}</span>
					<input
						type="text"
						value={address}
						placeholder="Ethereum address"
						className="input input-bordered w-[60%]"
						onClick={(e) => e.currentTarget.select()}
						onChange={(e) => setAddress(e.target.value)}
					/>
				</div>

				<div className="input-group flex justify-center mt-5 mb-3">
					<button className={`btn ${loadingAdd ? 'btn-disabled loading' : 'btn-primary'}`} onClick={() => set(true)}>{intl.get("add-to-whitelist")}</button>
					<button className={`btn ${loadingRemove ? 'btn-disabled loading' : 'btn-secondary'}`} onClick={() => set(false)}>{intl.get("remove-from-whitelist")}</button>
				</div>
			</div>

			{/* Batch set */}
			<div className="md:w-[50%] md:ml-[25%] w-[100%] ml-[0%] mt-10 text-center">{intl.get("batch-set")}</div>
			<div className="mt-5 bg-base-200 text-center md:w-[50%] md:ml-[25%] md:p-12 w-[100%] ml-[0%] p-3 rounded-lg">
				{/* Address */}
				<div className="input-group flex justify-center mb-3">
					<span className="w-[40%] text-left align-top">{intl.get("batch-participant-address")}</span>
					<textarea
						value={addresses}
						placeholder="Ethereum addresses, one line one address"
						className="input input-bordered w-[60%] py-2 px-4 h-[200px]"
						onClick={(e) => e.currentTarget.select()}
						onChange={(e) => setAddresses(e.target.value)}
					></textarea>
				</div>

				<div className="input-group flex justify-center mt-5 mb-3">
					<button className={`btn ${loadingBatchAdd ? 'btn-disabled loading' : 'btn-primary'}`} onClick={() => batchSet(true)}>{intl.get("batch-add-to-whitelist")}</button>
					<button className={`btn ${loadingBatchRemove ? 'btn-disabled loading' : 'btn-secondary'}`} onClick={() => batchSet(false)}>{intl.get("batch-remove-from-whitelist")}</button>
				</div>
			</div>

			{/* Authorize operator */}
			<div className="md:w-[50%] md:ml-[25%] w-[100%] ml-[0%] mt-10 text-center">{intl.get("set-operator-address")}</div>
			<div className="mt-5 mb-24 bg-base-200 text-center md:w-[50%] md:ml-[25%] md:p-12 w-[100%] ml-[0%] p-3 rounded-lg">
				{/* Address */}
				<div className="input-group flex justify-center mb-3">
					<span className="w-[40%] text-left">{intl.get("operator-address")}</span>
					<input
						type="text"
						value={operator}
						placeholder="Operator Ethereum address"
						className="input input-bordered w-[60%]"
						onClick={(e) => e.currentTarget.select()}
						onChange={(e) => setOperator(e.target.value)}
					/>
				</div>

				<div className="input-group flex justify-center mt-5 mb-3">
					<button
						className={`btn mt-5 ${loadingGetOperator ? 'btn-disabled loading' : 'btn-primary'}`}
						onClick={() => getOperatorAddress()}>{intl.get("get-operator-address-btn")}</button>
					<button
						className={`btn mt-5 ${loadingSetOperator ? 'btn-disabled loading' : 'btn-secondary'}`}
						onClick={() => setOperatorAddress()}>{intl.get("set-operator-address-btn")}</button>
				</div>
			</div>


			{/* Toast */}
			<Toaster toastOptions={{
				duration: 4000,
			}} />

		</div>)
}

export default WhitelistMain;